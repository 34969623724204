import { motion } from "framer-motion"
import { Resizable } from "re-resizable"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { Brain, TrendingUp, TrendingDown, X } from "lucide-react"
import { cn } from "@/lib/utils"
import { IntradayLevelsChart } from "@/presentation/components/Charts/IntradayLevelsChart"

export const getSentimentText = (sentiment: string) => {
  const lowerSentiment = sentiment.toLowerCase()
  if (lowerSentiment === "bearish") return "Bearish"
  if (lowerSentiment === "bullish") return "Bullish"
  if (lowerSentiment === "neutral_to_bearish") return "Slightly Bearish"
  if (lowerSentiment === "neutral_to_bullish") return "Slightly Bullish"
  return "Neutral"
}

export const getSentimentColor = (sentiment: string) => {
  const lowerSentiment = sentiment.toLowerCase()
  if (lowerSentiment === "bullish") {
    return "text-green-400"
  }
  if (lowerSentiment === "bearish") {
    return "text-red-400"
  }
  if (lowerSentiment === "neutral_to_bullish") {
    return "text-green-400/80"
  }
  if (lowerSentiment === "neutral_to_bearish") {
    return "text-red-400/80"
  }
  return "text-gray-300/70"
}

const formatTime = (dateString: string) => {
  const date = new Date(dateString)
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })
}

interface AIAnalysisPanelProps {
  visible: boolean
  onClose: () => void
  analysis?: string
  isLoading?: boolean
  symbol?: string
  oneHourSentiment?: string
  dailySentiment?: string
  weeklySentiment?: string
  oneHourReasoning?: string
  dailyReasoning?: string
  weeklyReasoning?: string
  generatedAt?: string
  intraday_levels?: number[]
  querySymbol: string
}

export const AIAnalysisPanel = ({
  visible,
  onClose,
  analysis,
  isLoading = false,
  symbol,
  oneHourSentiment = "Neutral",
  dailySentiment = "Neutral",
  weeklySentiment = "Neutral",
  oneHourReasoning,
  dailyReasoning,
  weeklyReasoning,
  generatedAt,
  intraday_levels = [],
  querySymbol,
}: AIAnalysisPanelProps) => {
  if (!visible) return null

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "spring", bounce: 0, duration: 0.4 }}
      className="fixed right-0 top-0 h-full w-full sm:w-auto bg-[#111] border-l border-[#333] backdrop-blur-xl bg-black/80 shadow-xl z-[50] flex"
    >
      <Resizable
        defaultSize={{ width: "100%", height: "100%" }}
        minWidth="100%"
        maxWidth="100%"
        enable={{
          top: false,
          right: false,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        className="sm:w-[480px] md:w-[580px] lg:w-[680px] sm:min-w-[400px] sm:max-w-[800px]"
        {...(window.innerWidth >= 640 && {
          defaultSize: { width: 580, height: "100%" },
          minWidth: 400,
          maxWidth: 800,
          enable: {
            top: false,
            right: false,
            bottom: false,
            left: true,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          },
        })}
        handleClasses={{
          left: "hidden sm:block w-1 h-full cursor-ew-resize hover:bg-purple-500/20 transition-colors absolute left-0 top-0 border-r border-purple-500/10",
        }}
      >
        <div className="h-full flex flex-col overflow-hidden">
          <div className="flex justify-between items-center p-6 shrink-0">
            <div className="flex items-center gap-2">
              <Brain
                className={cn(
                  "w-5 h-5 text-purple-400",
                  isLoading && "animate-pulse",
                )}
              />
              <span className="text-lg font-medium text-white/90">
                MRKT AI Analysis
              </span>
            </div>
            <button
              onClick={onClose}
              className="text-white/70 hover:text-white"
            >
              <X className="h-4 w-4" />
            </button>
          </div>

          <div
            className="px-4 sm:px-6 pb-6 flex flex-col overflow-hidden"
            style={{ height: "calc(100% - 88px)" }}
          >
            {!isLoading && analysis ? (
              <>
                <div className="mb-4 shrink-0">
                  <h3 className="text-lg font-medium text-white/90">
                    {oneHourSentiment.toLowerCase().includes("bullish") ||
                    oneHourSentiment
                      .toLowerCase()
                      .includes("neutral_to_bullish") ? (
                      <TrendingUp
                        className={cn(
                          "w-4 h-4 inline mr-1",
                          getSentimentColor(oneHourSentiment),
                        )}
                      />
                    ) : oneHourSentiment.toLowerCase().includes("bearish") ||
                      oneHourSentiment
                        .toLowerCase()
                        .includes("neutral_to_bearish") ? (
                      <TrendingDown
                        className={cn(
                          "w-4 h-4 inline mr-1",
                          getSentimentColor(oneHourSentiment),
                        )}
                      />
                    ) : null}
                    <span className="bg-white/5 backdrop-blur-[2px] text-white/90 font-medium">
                      {symbol}
                    </span>{" "}
                    is{" "}
                    <span
                      className={cn(
                        "font-medium",
                        getSentimentColor(oneHourSentiment),
                      )}
                    >
                      {getSentimentText(oneHourSentiment)}
                    </span>{" "}
                    on intraday
                  </h3>
                </div>
                <SimpleBar className="flex-1 min-h-0 custom-scrollbar">
                  <div className="pr-2 sm:pr-4">
                    <p className="text-md text-white/90 leading-relaxed mb-6">
                      {analysis}
                    </p>

                    {symbol && intraday_levels.length > 0 && (
                      <div className="mb-6">
                        <div className="flex items-center gap-2 mb-3">
                          <h3 className="text-md font-medium text-white/70">
                            Price Levels
                          </h3>
                          <div className="h-4 border-l border-white/40" />
                          <span className="text-xs text-white/60">
                            Use as reference alongside trend analysis
                          </span>
                        </div>
                        <IntradayLevelsChart
                          symbol={querySymbol}
                          levels={intraday_levels}
                        />
                      </div>
                    )}

                    <div className="space-y-4 sm:space-y-6">
                      {oneHourReasoning && (
                        <div>
                          <div className="mb-3 sm:mb-4">
                            <h4 className="flex items-center gap-2 text-base sm:text-lg">
                              <span className="text-white font-semibold">
                                1H Technical Analysis:
                              </span>
                              <span
                                className={cn(
                                  "text-lg",
                                  getSentimentColor(oneHourSentiment),
                                )}
                              >
                                {getSentimentText(oneHourSentiment)}
                              </span>
                            </h4>
                          </div>
                          <p className="text-md text-white/90 leading-relaxed">
                            {oneHourReasoning}
                          </p>
                        </div>
                      )}
                      {dailyReasoning && (
                        <div>
                          <div className="mb-3 sm:mb-4">
                            <h4 className="flex items-center gap-2 text-base sm:text-lg">
                              <span className="text-white font-semibold">
                                Daily Technical Analysis:
                              </span>
                              <span
                                className={cn(
                                  "text-lg",
                                  getSentimentColor(dailySentiment),
                                )}
                              >
                                {getSentimentText(dailySentiment)}
                              </span>
                            </h4>
                          </div>
                          <p className="text-md text-white/90 leading-relaxed">
                            {dailyReasoning}
                          </p>
                        </div>
                      )}
                      {weeklyReasoning && (
                        <div>
                          <div className="mb-3 sm:mb-4">
                            <h4 className="flex items-center gap-2 text-base sm:text-lg">
                              <span className="text-white font-semibold">
                                Weekly Technical Analysis:
                              </span>
                              <span
                                className={cn(
                                  "text-lg",
                                  getSentimentColor(weeklySentiment),
                                )}
                              >
                                {getSentimentText(weeklySentiment)}
                              </span>
                            </h4>
                          </div>
                          <p className="text-md  text-white/90 leading-relaxed">
                            {weeklyReasoning}
                          </p>
                        </div>
                      )}
                      {generatedAt && (
                        <div className="text-md  text-white/60 flex leading-relaxed items-center">
                          Analysis generated at {formatTime(generatedAt)}
                        </div>
                      )}
                    </div>
                  </div>
                </SimpleBar>
              </>
            ) : isLoading ? (
              <div className="flex items-center gap-2 text-white/70">
                <div className="animate-spin w-4 h-4 border-2 border-purple-400 border-t-transparent rounded-full" />
                <span>Analyzing market sentiment...</span>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center py-12">
                <h3 className="text-lg font-medium text-white mb-2">
                  Analysis Unavailable
                </h3>
                <p className="text-zinc-400 text-sm">
                  Unable to retrieve AI analysis at this time.
                </p>
              </div>
            )}
          </div>
        </div>
      </Resizable>
    </motion.div>
  )
}
