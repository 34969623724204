import { XCircle, X, FileText } from "lucide-react"
import { DNA } from "react-loader-spinner"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { motion } from "framer-motion"
import { Resizable } from "re-resizable"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import { BullBearTargetChart } from "../BullBearTargetChart/BullBearTargetChart"
import { SupportResistanceChart } from "../SupportResistanceChart/SupportResistanceChart"
import { cn } from "@/lib/utils"
import { Toggle } from "@/components/ui/Toggle"

interface StockAnalysis {
  symbol: string
  fundamentalAnalysis: {
    text: string
    key_points: string[]
    profitability: {
      revenuePerShare: number
      netIncomePerShare: number
      peRatio: number
      freeCashFlowYield: number
      roe: number
    }
    valuation: {
      priceToSalesRatio: number
      enterpriseValue: number
      debtToEquity: number
    }
    liquidity: {
      currentRatio: number
      cashPerShare: number
    }
  }
  sentimentAnalysis: {
    text: string
    key_points: string[]
    overallSentiment: string
    summary: string
    relevantNews: Array<{
      title: string
      url: string
      sentiment: string
    }>
  }
  technicalAnalysis: {
    text: string
    key_points: string[]
    recentPriceTrend: string
    supportLevels: number[]
    resistanceLevels: number[]
    averageVolume: number
    yearlyLow?: number
    yearlyHigh?: number
  }
  investmentThesis: {
    text: string
    key_points: string[]
    bullCase: string
    bearCase: string
    priceTargets: {
      bullish: number
      bearish: number
    }
  }
  analystRecommendations: {
    text: string
    key_points: string[]
  }
  earningsAnalysis: {
    text: string
    key_points: string[]
    historicalEarnings: Array<{
      date: string
      eps: number
      revenue: number
    }>
    upcomingEarnings: {
      date: string
      eps: number
      revenue: number
    }
  }
  competitiveAnalysis: {
    text: string
    key_points: string[]
    positioning: string
    marketTrends: string
    disruptionRisk: string
  }
  risksAndChallenges: {
    text: string
    key_points: string[]
    details: string
  }
}

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

const highlightText = (
  text: string,
  keyPoints: string[],
  highlightEnabled: boolean,
) => {
  if (!highlightEnabled) {
    return <p className="text-gray-300">{text}</p>
  }

  let highlightedText = text
  keyPoints.forEach((point) => {
    const escapedPoint = escapeRegExp(point)
    const regex = new RegExp(escapedPoint, "gi")
    highlightedText = highlightedText.replace(
      regex,
      `<span class="bg-yellow-600/40 text-yellow-200 font-medium px-1 rounded-sm border-b border-yellow-400/50">${point}</span>`,
    )
  })
  return (
    <p
      className="text-gray-300"
      dangerouslySetInnerHTML={{ __html: highlightedText }}
    />
  )
}

const AnalysisLoadingSkeleton = ({ messages }: { messages: string[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length)
    }, 2000)

    return () => clearInterval(interval)
  }, [messages.length])

  return (
    <div className="space-y-6">
      <div className="flex flex-col items-center justify-center py-8">
        <DNA
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{ color: "orange" }}
          wrapperClass="dna-wrapper"
        />
        <h2 className="text-xl font-medium text-white mb-4">
          {messages[currentIndex]}
        </h2>
      </div>

      <div className="space-y-6">
        {[...Array(4)].map((_, index) => (
          <div key={index}>
            <Skeleton
              height={24}
              width={160}
              className="mb-3"
              baseColor="#27272a"
              highlightColor="#3f3f46"
            />
            <div className="space-y-2">
              <Skeleton
                height={16}
                baseColor="#27272a"
                highlightColor="#3f3f46"
              />
              <Skeleton
                height={16}
                width="75%"
                baseColor="#27272a"
                highlightColor="#3f3f46"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

interface StockAnalysisPanelProps {
  symbol: string
  companyName: string
  onClose: () => void
}

export const StockAnalysisPanel = ({
  symbol,
  companyName,
  onClose,
}: StockAnalysisPanelProps) => {
  const { getAccessTokenSilently } = useAuth0()
  const [streamingAnalysis, setStreamingAnalysis] =
    useState<StockAnalysis | null>(null)
  const [streamingError, setStreamingError] = useState<string | null>(null)
  const [analysisStatus, setAnalysisStatus] = useState<
    "idle" | "fetching_data" | "analyzing_data" | "completed"
  >("idle")

  useEffect(() => {
    const fetchAnalysis = async () => {
      try {
        const token = await getAccessTokenSilently()
        const response = await fetch(
          `${import.meta.env.VITE_MRKT_SERVER}/equities/analyze/${symbol}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        if (!response.ok) {
          throw new Error("Failed to fetch analysis")
        }

        const reader = response.body?.getReader()
        if (!reader) throw new Error("No reader available")

        try {
          let completeJson = ""
          while (true) {
            const { done, value } = await reader.read()
            if (done) break

            const chunk = new TextDecoder().decode(value)
            const lines = chunk.split("\n")

            for (const line of lines) {
              if (line.startsWith("data: ")) {
                try {
                  const data = JSON.parse(line.slice(5))
                  console.log("Received data:", data)

                  if (data.status) {
                    console.log("Status update:", data.status)
                    setAnalysisStatus(data.status)
                    continue
                  }

                  if (data.error) {
                    console.log("Error received:", data.error)
                    setStreamingError(data.error)
                    continue
                  }

                  // Handle the complete message directly
                  if (data.type === "complete" && data.content) {
                    console.log("Received complete analysis")
                    setStreamingAnalysis(data.content)
                    continue
                  }

                  // Handle chunks
                  if (data.type === "chunk") {
                    console.log("Received chunk of size:", data.content.length)
                    completeJson += data.content
                  }
                } catch (e) {
                  console.error("Error parsing SSE data:", e)
                }
              }
            }
          }
        } finally {
          reader.releaseLock()
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          setStreamingError(error.message)
        } else {
          setStreamingError("An error occurred")
        }
        console.error("Error:", error)
      }
    }

    fetchAnalysis()
  }, [symbol, getAccessTokenSilently])

  const loadingMessages = [
    "Analyzing fundamentals...",
    "Processing market sentiment...",
    "Evaluating technical indicators...",
    "Generating investment thesis...",
    "Synthesizing comprehensive analysis...",
  ]

  const normalizedAnalysis = streamingAnalysis
  const isLoading = analysisStatus !== "completed"

  useEffect(() => {
    console.log("Streaming Analysis:", streamingAnalysis)
  }, [streamingAnalysis])

  const [highlightedLevel, setHighlightedLevel] = useState<number>()
  const [highlightEnabled, setHighlightEnabled] = useState(false)

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "spring", bounce: 0, duration: 0.4 }}
      className="fixed right-0 top-0 h-full bg-[#111] border-l border-[#333] backdrop-blur-xl bg-black/80 shadow-xl z-[50] flex"
    >
      <Resizable
        defaultSize={{ width: 600, height: "100%" }}
        minWidth={500}
        maxWidth={1000}
        enable={{
          top: false,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        handleClasses={{
          left: "w-1 h-full cursor-ew-resize hover:bg-purple-500/20 transition-colors absolute left-0 top-0 border-r border-purple-500/10",
        }}
      >
        <div className="h-full flex flex-col overflow-hidden">
          <div className="flex justify-between items-center p-6 border-b border-[#333] bg-black/50 backdrop-blur-sm">
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center w-12 h-12 rounded-xl overflow-hidden shadow-lg">
                <img
                  src={`https://financialmodelingprep.com/image-stock/${symbol}.png`}
                  alt={`${symbol} logo`}
                  className="w-8 h-8 object-contain"
                  onError={(e) => {
                    const target = e.target as HTMLImageElement
                    target.style.display = "none"
                    target.parentElement!.innerHTML = `<span class="text-lg font-semibold text-purple-300">${symbol}</span>`
                  }}
                />
              </div>
              <div>
                <div className="flex items-center gap-3">
                  <h2 className="text-xl font-semibold text-white">
                    {companyName}
                  </h2>
                  <span className="flex items-center gap-0.5 text-sm px-2.5 py-1 rounded-md bg-white/5 text-gray-300 font-medium border border-white/10 hover:border-white/20 transition-colors">
                    <span>$</span>
                    <span>{symbol}</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <button
                onClick={onClose}
                className="flex items-center justify-center w-9 h-9 rounded-lg text-gray-400 hover:text-white hover:bg-white/5 transition-colors border border-white/5"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>

          <SimpleBar
            className="flex-1 overflow-y-auto"
            style={{ height: "100%" }}
            autoHide={false}
          >
            <div className="p-6 pb-12">
              {isLoading ? (
                <AnalysisLoadingSkeleton messages={loadingMessages} />
              ) : streamingError ? (
                <div className="flex flex-col items-center justify-center py-12">
                  <XCircle className="w-12 h-12 text-red-500/70 mb-4" />
                  <h3 className="text-lg font-medium text-white mb-2">
                    Analysis Failed
                  </h3>
                  <p className="text-zinc-400 text-sm">{streamingError}</p>
                </div>
              ) : normalizedAnalysis ? (
                <div className="space-y-8">
                  <div>
                    <div className="flex items-center justify-between mb-4">
                      <h3 className="text-lg font-medium text-white">
                        Fundamental Analysis
                      </h3>
                      <Toggle
                        enabled={highlightEnabled}
                        onChange={setHighlightEnabled}
                        label="Highlights"
                        size="sm"
                      />
                    </div>
                    {highlightText(
                      normalizedAnalysis.fundamentalAnalysis?.text || "",
                      normalizedAnalysis.fundamentalAnalysis?.key_points || [],
                      highlightEnabled,
                    )}
                  </div>
                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Investment Thesis
                    </h3>
                    {highlightText(
                      normalizedAnalysis.investmentThesis?.text || "",
                      normalizedAnalysis.investmentThesis?.key_points || [],
                      highlightEnabled,
                    )}
                    <div className="grid grid-cols-2 gap-4 mt-4">
                      <div className="bg-gradient-to-b from-emerald-500/5 to-transparent p-4 rounded-lg border border-emerald-500/10 hover:border-emerald-500/20 transition-colors">
                        <h4 className="text-sm font-medium text-green-400 mb-2">
                          Bull Case
                        </h4>
                        {highlightText(
                          normalizedAnalysis.investmentThesis?.bullCase || "",
                          [],
                          highlightEnabled,
                        )}
                        <div className="mt-2 flex items-center gap-2">
                          <span className="text-gray-400 text-sm">Target:</span>
                          <span className="text-green-400 font-medium">
                            $
                            {
                              normalizedAnalysis.investmentThesis?.priceTargets
                                .bullish
                            }
                          </span>
                        </div>
                      </div>
                      <div className="bg-gradient-to-b from-red-500/5 to-transparent p-4 rounded-lg border border-red-500/10 hover:border-red-500/20 transition-colors">
                        <h4 className="text-sm font-medium text-red-400 mb-2">
                          Bear Case
                        </h4>
                        {highlightText(
                          normalizedAnalysis.investmentThesis?.bearCase || "",
                          [],
                          highlightEnabled,
                        )}
                        <div className="mt-2 flex items-center gap-2">
                          <span className="text-gray-400 text-sm">Target:</span>
                          <span className="text-red-400 font-medium">
                            $
                            {
                              normalizedAnalysis.investmentThesis?.priceTargets
                                .bearish
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <BullBearTargetChart
                        symbol={symbol}
                        bullishTarget={
                          normalizedAnalysis.investmentThesis?.priceTargets
                            .bullish
                        }
                        bearishTarget={
                          normalizedAnalysis.investmentThesis?.priceTargets
                            .bearish
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Market Sentiment
                    </h3>
                    <span
                      className={`inline-flex items-center gap-2 px-4 py-1.5 rounded-full text-sm font-medium bg-black text-white ring-1 ring-white/20`}
                    >
                      {normalizedAnalysis.sentimentAnalysis
                        ?.overallSentiment === "Bullish" ? (
                        <svg
                          className="w-4 h-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={1.5}
                        >
                          <path d="M22 7L13.5 15.5L8.5 10.5L2 17" />
                        </svg>
                      ) : normalizedAnalysis.sentimentAnalysis
                          ?.overallSentiment === "Bearish" ? (
                        <svg
                          className="w-4 h-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={1.5}
                        >
                          <path d="M22 17L13.5 8.5L8.5 13.5L2 7" />
                        </svg>
                      ) : (
                        <span className="w-2 h-2 rounded-full bg-current"></span>
                      )}
                      {highlightText(
                        normalizedAnalysis.sentimentAnalysis
                          ?.overallSentiment || "",
                        [],
                        highlightEnabled,
                      )}
                    </span>
                    <div className="mt-4">
                      {highlightText(
                        normalizedAnalysis.sentimentAnalysis?.text || "",
                        normalizedAnalysis.sentimentAnalysis?.key_points || [],
                        highlightEnabled,
                      )}
                    </div>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Technical Analysis
                    </h3>
                    <span
                      className={`inline-flex items-center px-4 py-1.5 rounded-full text-sm font-medium bg-black text-white ring-1 ring-white/20`}
                    >
                      {highlightText(
                        normalizedAnalysis.technicalAnalysis
                          ?.recentPriceTrend || "",
                        [],
                        highlightEnabled,
                      )}
                    </span>
                    <div className="mt-2">
                      {highlightText(
                        normalizedAnalysis.technicalAnalysis?.text || "",
                        normalizedAnalysis.technicalAnalysis?.key_points || [],
                        highlightEnabled,
                      )}
                    </div>

                    <div className="space-y-4 mt-4">
                      {/* Chart first */}
                      <div className="bg-black/20 rounded-xl p-4">
                        <SupportResistanceChart
                          symbol={symbol}
                          supportLevel={
                            normalizedAnalysis.technicalAnalysis?.supportLevels
                          }
                          resistanceLevel={
                            normalizedAnalysis.technicalAnalysis
                              ?.resistanceLevels
                          }
                          yearlyLow={
                            normalizedAnalysis.technicalAnalysis?.yearlyLow
                          }
                          yearlyHigh={
                            normalizedAnalysis.technicalAnalysis?.yearlyHigh
                          }
                          highlightedLevel={highlightedLevel}
                        />

                        {/* Levels */}
                        <div className="flex justify-between mt-6 px-1">
                          {/* Support Levels */}
                          <div className="space-y-0.5">
                            <div className="text-[11px] uppercase tracking-wider text-white/50 mb-2">
                              Support
                            </div>
                            {normalizedAnalysis.technicalAnalysis?.supportLevels?.map(
                              (level: number, index: number) => (
                                <div
                                  key={index}
                                  className={cn(
                                    "flex items-center gap-3 text-[13px] cursor-pointer transition-colors",
                                    highlightedLevel === level
                                      ? "text-green-400"
                                      : "text-white/70",
                                  )}
                                  onMouseEnter={() =>
                                    setHighlightedLevel(level)
                                  }
                                  onMouseLeave={() =>
                                    setHighlightedLevel(undefined)
                                  }
                                >
                                  <span className="text-white/40 w-4">
                                    S{index + 1}
                                  </span>
                                  <span className="font-mono">
                                    ${level.toFixed(2)}
                                  </span>
                                </div>
                              ),
                            )}
                          </div>

                          {/* Resistance Levels */}
                          <div className="space-y-0.5">
                            <div className="text-[11px] uppercase tracking-wider text-white/50 mb-2">
                              Resistance
                            </div>
                            {normalizedAnalysis.technicalAnalysis?.resistanceLevels?.map(
                              (level: number, index: number) => (
                                <div
                                  key={index}
                                  className={cn(
                                    "flex items-center gap-3 text-[13px] cursor-pointer transition-colors",
                                    highlightedLevel === level
                                      ? "text-red-400"
                                      : "text-white/70",
                                  )}
                                  onMouseEnter={() =>
                                    setHighlightedLevel(level)
                                  }
                                  onMouseLeave={() =>
                                    setHighlightedLevel(undefined)
                                  }
                                >
                                  <span className="text-white/40 w-4">
                                    R{index + 1}
                                  </span>
                                  <span className="font-mono">
                                    ${level.toFixed(2)}
                                  </span>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Analyst Recommendations
                    </h3>
                    {highlightText(
                      normalizedAnalysis.analystRecommendations?.text || "",
                      normalizedAnalysis.analystRecommendations?.key_points ||
                        [],
                      highlightEnabled,
                    )}
                  </div>

                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Earnings Analysis
                    </h3>
                    {highlightText(
                      normalizedAnalysis.earningsAnalysis?.text || "",
                      normalizedAnalysis.earningsAnalysis?.key_points || [],
                      highlightEnabled,
                    )}
                    <div className="grid grid-cols-2 gap-4 mt-4">
                      <div className="bg-gradient-to-b from-zinc-500/[0.15] to-zinc-900/[0.05] p-5 rounded-xl border border-zinc-300/20 hover:border-zinc-300/30 transition-colors">
                        <div className="flex items-center justify-between mb-6">
                          <h4 className="text-xs uppercase tracking-wider text-zinc-200 font-medium">
                            Upcoming Earnings
                          </h4>
                          <span className="text-[10px] px-2 py-0.5 rounded-full bg-zinc-300/10 text-zinc-200 uppercase tracking-wider font-medium">
                            Est
                          </span>
                        </div>
                        {normalizedAnalysis.earningsAnalysis
                          ?.upcomingEarnings ? (
                          <div className="space-y-5">
                            <div>
                              <div className="text-xs text-gray-500 mb-1.5">
                                Date
                              </div>
                              <div className="text-sm font-medium text-white">
                                {new Date(
                                  normalizedAnalysis.earningsAnalysis.upcomingEarnings.date,
                                ).toLocaleDateString()}
                              </div>
                            </div>

                            <div>
                              <div className="text-xs text-gray-500 mb-1.5">
                                EPS
                              </div>
                              <div className="text-sm font-medium flex items-baseline">
                                <span className="text-xs text-gray-500 mr-1">
                                  $
                                </span>
                                <span className="text-xl font-semibold text-zinc-100">
                                  {normalizedAnalysis.earningsAnalysis
                                    ?.upcomingEarnings?.eps != null
                                    ? normalizedAnalysis.earningsAnalysis.upcomingEarnings.eps.toFixed(
                                        2,
                                      )
                                    : "N/A"}
                                </span>
                              </div>
                            </div>

                            <div>
                              <div className="text-xs text-gray-500 mb-1.5">
                                Revenue
                              </div>
                              <div className="space-y-1">
                                <div className="text-sm font-medium flex items-baseline">
                                  <span className="text-xs text-gray-500 mr-1">
                                    $
                                  </span>
                                  <span className="text-xl font-semibold text-zinc-100">
                                    {normalizedAnalysis.earningsAnalysis
                                      ?.upcomingEarnings?.revenue != null
                                      ? (
                                          normalizedAnalysis.earningsAnalysis
                                            .upcomingEarnings.revenue / 1e9
                                        ).toFixed(2)
                                      : "N/A"}
                                  </span>
                                  <span className="text-xs text-gray-500 ml-1">
                                    B
                                  </span>
                                </div>
                                <div className="text-[11px] text-gray-500 font-medium">
                                  {normalizedAnalysis.earningsAnalysis
                                    ?.upcomingEarnings?.revenue != null
                                    ? `$${normalizedAnalysis.earningsAnalysis.upcomingEarnings.revenue.toLocaleString()}`
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="text-sm text-gray-600">
                            No upcoming earnings data available
                          </div>
                        )}
                      </div>
                      <div className="bg-gradient-to-b from-zinc-500/[0.15] to-zinc-900/[0.05] p-5 rounded-xl border border-zinc-300/20 hover:border-zinc-300/30 transition-colors">
                        <h4 className="text-xs uppercase tracking-wider font-medium mb-6 text-gray-200">
                          Latest Earnings
                        </h4>
                        {normalizedAnalysis.earningsAnalysis
                          ?.historicalEarnings?.[0] ? (
                          <div className="space-y-5">
                            <div>
                              <div className="text-xs text-gray-500 mb-1.5">
                                Date
                              </div>
                              <div className="text-sm font-medium text-white">
                                {new Date(
                                  normalizedAnalysis.earningsAnalysis.historicalEarnings[0].date,
                                ).toLocaleDateString()}
                              </div>
                            </div>

                            <div>
                              <div className="text-xs text-gray-500 mb-1.5">
                                EPS
                              </div>
                              <div
                                className={cn(
                                  "text-sm font-medium flex items-baseline",
                                  normalizedAnalysis.earningsAnalysis
                                    .historicalEarnings[0].eps > 0
                                    ? "text-emerald-400"
                                    : "text-red-400",
                                )}
                              >
                                <span className="text-xs text-gray-500 mr-1">
                                  $
                                </span>
                                <span className="text-xl font-semibold text-gray-100">
                                  {normalizedAnalysis.earningsAnalysis
                                    ?.historicalEarnings?.[0]?.eps != null
                                    ? normalizedAnalysis.earningsAnalysis.historicalEarnings[0].eps.toFixed(
                                        2,
                                      )
                                    : "N/A"}
                                </span>
                              </div>
                            </div>

                            <div>
                              <div className="text-xs text-gray-500 mb-1.5">
                                Revenue
                              </div>
                              <div className="space-y-1">
                                <div className="text-sm font-medium flex items-baseline">
                                  <span className="text-xs text-gray-500 mr-1">
                                    $
                                  </span>
                                  <span className="text-xl font-semibold text-gray-100">
                                    {normalizedAnalysis.earningsAnalysis
                                      ?.historicalEarnings?.[0]?.revenue != null
                                      ? (
                                          normalizedAnalysis.earningsAnalysis
                                            .historicalEarnings[0].revenue / 1e9
                                        ).toFixed(2)
                                      : "N/A"}
                                  </span>
                                  <span className="text-xs text-gray-500 ml-1">
                                    B
                                  </span>
                                </div>
                                <div className="text-[11px] text-gray-500 font-medium">
                                  {normalizedAnalysis.earningsAnalysis
                                    ?.historicalEarnings?.[0]?.revenue != null
                                    ? `$${normalizedAnalysis.earningsAnalysis.historicalEarnings[0].revenue.toLocaleString()}`
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="text-sm text-gray-600">
                            No historical earnings data available
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Competitive Analysis
                    </h3>
                    {highlightText(
                      normalizedAnalysis.competitiveAnalysis?.text || "",
                      normalizedAnalysis.competitiveAnalysis?.key_points || [],
                      highlightEnabled,
                    )}
                  </div>

                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Risks & Challenges
                    </h3>
                    {highlightText(
                      normalizedAnalysis.risksAndChallenges?.text || "",
                      normalizedAnalysis.risksAndChallenges?.key_points || [],
                      highlightEnabled,
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </SimpleBar>
        </div>
      </Resizable>
    </motion.div>
  )
}
