import moment from "moment-timezone"

export function parseHeadlineTime(headline: string): {
  relativeTime: string
  localTime: string
} {
  if (headline === undefined) return { relativeTime: "", localTime: "" }

  const splitIndex = headline.indexOf("]: ")

  // Check if the headline doesn't have a timestamp
  if (splitIndex === -1) {
    return { relativeTime: "No time", localTime: new Date().toISOString() }
  }

  const dateTimeString = headline.substring(0, splitIndex + 1)
  const dateTimeStringWithoutBrackets = dateTimeString.slice(1, -1)

  // Parse the date using moment to handle the EST timezone correctly
  const reutersTime = moment.tz(
    dateTimeStringWithoutBrackets,
    "MMM DD YYYY, HH:mm:ss z",
    "America/New_York",
  )

  // Check if the parsed date is valid
  if (!reutersTime.isValid()) {
    return { relativeTime: "Invalid time", localTime: new Date().toISOString() }
  }

  // Convert to user's local timezone
  const localMoment = reutersTime.local()

  // Get current time
  const now = moment()

  // Calculate the difference in minutes
  const diffInMinutes = now.diff(reutersTime, "minutes")

  let relativeTime: string
  if (diffInMinutes < 1) {
    relativeTime = "Just now"
  } else if (diffInMinutes === 1) {
    relativeTime = "1 minute ago"
  } else if (diffInMinutes < 60) {
    relativeTime = `${diffInMinutes} minutes ago`
  } else if (diffInMinutes < 1440) {
    const hours = Math.floor(diffInMinutes / 60)
    relativeTime = `${hours} hour${hours > 1 ? "s" : ""} ago`
  } else {
    const days = Math.floor(diffInMinutes / 1440)
    relativeTime = `${days} day${days > 1 ? "s" : ""} ago`
  }

  // Return ISO string for consistent date handling, in local timezone
  return {
    relativeTime,
    localTime: localMoment.toISOString(),
  }
}

export function parseHeadlineTitle(headline: string) {
  if (headline === undefined) return
  const splitIndex = headline.indexOf("]: ")
  return splitIndex === -1 ? headline : headline.substring(splitIndex + 3)
}
