import { useState } from "react"
import { Link, useRouterState, useNavigate } from "@tanstack/react-router"
import { clsx } from "clsx"
import {
  Bars3Icon,
  HomeIcon,
  XMarkIcon,
  BookOpenIcon,
  PlusIcon,
} from "@heroicons/react/24/outline"
import AccountDropdown from "./AccountDropdown"
import { useUser } from "@/providers/UserProvider"
import { useAuth0 } from "@auth0/auth0-react"
import posthog from "posthog-js"

const BetaModal = ({
  isOpen,
  onClose,
  isSubscribed,
}: {
  isOpen: boolean
  onClose: () => void
  isSubscribed: boolean
}) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-black border border-[#333] rounded-lg p-6 max-w-2xl w-full mx-4 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>

        <div className="flex items-center gap-3 mb-6">
          <h2 className="text-2xl font-semibold text-white">
            <span role="img" aria-label="rocket" className="text-2xl mr-2">
              🚀
            </span>
            Welcome to MRKT{" "}
            <span className="bg-violet-600/20 text-purple-400 text-xs px-2 py-0.5 rounded-md font-medium tracking-wide border border-purple-500/30">
              BETA
            </span>
          </h2>
        </div>

        <div className="space-y-4 text-gray-300">
          <p className="text-lg">
            MRKT is currently in beta, and we're working hard to create the
            ultimate platform for traders and investors. Our mission is to
            provide sophisticated market analysis tools that are both powerful
            and accessible.
          </p>

          <p className="text-lg font-medium text-white">
            During this beta phase, we're actively:
          </p>

          <ul className="list-disc list-inside space-y-2 ml-4">
            <li>Developing new features and improvements</li>
            <li>Optimizing performance and user experience</li>
            <li>Gathering valuable feedback from our users</li>
            <li>Rolling out regular updates and enhancements</li>
          </ul>

          <p className="text-lg">
            Your feedback is crucial in shaping MRKT's future. We'd love to hear
            your thoughts, suggestions, and feature requests.
          </p>

          {isSubscribed && (
            <div className="mt-8">
              <a
                href="https://mrkt.featurebase.app/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-6 py-3 bg-violet-700 hover:bg-violet-600 text-white rounded-md transition-colors font-medium"
              >
                Submit Feedback
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const NAVIGATION = [
  {
    name: "Currencies",
    href: "/dashboard",
    icon: HomeIcon,
    requiresAuth: true,
  },
  {
    name: "Equities",
    href: "/equities",
    icon: BookOpenIcon,
    requiresAuth: true,
  },
  // { name: "Research", href: "", icon: BookOpenIcon, requiresAuth: false },
]

export const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [betaModalOpen, setBetaModalOpen] = useState(false)
  const router = useRouterState()
  const navigate = useNavigate()
  const { userData } = useUser()
  const { isAuthenticated } = useAuth0()
  const { isSubscribed } = useUser()
  const selectedDashboard = userData?.tradingInfo?.selectedDashboard || ""

  const getOrderedNavigation = () => {
    const baseNavigation = [...NAVIGATION]
    let orderedNavigation = [...baseNavigation]

    if (selectedDashboard === "equities") {
      const equitiesIndex = orderedNavigation.findIndex(
        (item) => item.name === "Equities",
      )
      if (equitiesIndex !== -1) {
        const [equitiesItem] = orderedNavigation.splice(equitiesIndex, 1)
        orderedNavigation.unshift(equitiesItem)
      }
    } else if (["forex", "crypto", "futures"].includes(selectedDashboard)) {
      const currenciesIndex = orderedNavigation.findIndex(
        (item) => item.name === "Currencies",
      )
      if (currenciesIndex !== -1) {
        const [currenciesItem] = orderedNavigation.splice(currenciesIndex, 1)
        orderedNavigation.unshift(currenciesItem)
      }
    }

    return orderedNavigation
  }

  const orderedNavigation = getOrderedNavigation()

  const handleNavClick = (itemName: string) => {
    posthog.capture("navigation_click", {
      nav_item: itemName,
      user: userData?.email,
    })
  }

  return (
    <>
      {!isAuthenticated && (
        <div className="fixed top-0 w-full z-[60] bg-gradient-to-r from-violet-600 via-indigo-600 to-violet-600">
          <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
            <div className="text-center sm:px-16">
              <p className="font-medium text-white">
                <span className="inline-flex items-center">
                  <span className="animate-pulse mr-2">🚀</span>
                  MRKT Beta is now live!
                  <button
                    onClick={() => window.open("https://mrktedge.ai", "_blank")}
                    className="ml-4 bg-white/20 hover:bg-white/30 transition-colors rounded-full px-4 py-1 text-sm font-semibold backdrop-blur-sm"
                  >
                    Try it now →
                  </button>
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
      <nav className={clsx("bg-black pt-4", !isAuthenticated && "mt-12")}>
        <div className="mx-auto px-12 sm:px-6 lg:px-12">
          <div className="flex h-16 items-center justify-between">
            <div className="flex-shrink-0 flex items-center gap-2">
              <h1 className="text-white text-4xl font-normal">MRKT</h1>
            </div>
            <div className="flex-grow flex items-center justify-center">
              <div className="hidden sm:flex space-x-4 items-center">
                {orderedNavigation.map((item) => {
                  const isActive =
                    item.name === "Research"
                      ? router.location.pathname.startsWith("/research")
                      : router.location.pathname === item.href

                  return (
                    <Link
                      key={item.name}
                      to={item.href}
                      onClick={() => handleNavClick(item.name)}
                      className={clsx(
                        isActive
                          ? "text-white bg-[#111] border border-[#222]"
                          : "text-gray-400 hover:text-white",
                        "inline-flex items-center px-4 py-2 text-sm font-medium transition-colors rounded-lg text-[17px]",
                      )}
                    >
                      {item.name}
                    </Link>
                  )
                })}
              </div>
            </div>
            <div className="flex items-center gap-4">
              {isSubscribed && (
                <div className="relative p-2">
                  <AccountDropdown />
                </div>
              )}
              <div className="sm:hidden">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {mobileMenuOpen && (
          <div className="sm:hidden" id="mobile-menu">
            <div className="space-y-1 pb-3 pt-2 px-12 sm:px-6 lg:px-12">
              {orderedNavigation.map((item) => {
                const isActive =
                  item.name === "Research"
                    ? router.location.pathname.startsWith("/research")
                    : router.location.pathname === item.href

                return (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => handleNavClick(item.name)}
                    className={clsx(
                      isActive
                        ? "bg-gray/50 border-indigo-500 text-white"
                        : "border-transparent text-gray-300 hover:bg-gray-100 hover:border-gray-300 hover:text-white",
                      "block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
                    )}
                  >
                    {item.name}
                  </Link>
                )
              })}
            </div>
          </div>
        )}
      </nav>

      <BetaModal
        isOpen={betaModalOpen}
        onClose={() => setBetaModalOpen(false)}
        isSubscribed={isSubscribed}
      />
    </>
  )
}
