import { EconomicCalendar } from "./components/EconomicCalendar"
import { MarketHeadlines } from "./components/MarketHeadlines"
import "simplebar-react/dist/simplebar.min.css"
import { CentralBankEvents } from "./components/CentralBankEvents/CentralBankEvents"
import { useUser } from "@/providers"
import { useState, useEffect } from "react"
import { MultiSymbolChart } from "@/presentation/components/Charts/MultiSymbolChart"
import { MarketNews } from "./components/MarketNews/MarketNews"
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline"
import { GlobalEconomy } from "./components/GlobalEconomy/GlobalEconomy"
import { AIGenerateButton } from "@/components/ui/AIGenerateButton"
import { format } from "date-fns"

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Button } from "@/components/ui/button"
import {
  BetweenVerticalEnd,
  Brain,
  Expand,
  Grid3x3,
  Loader2,
  Minimize,
  Plus,
  SquarePen,
} from "lucide-react"
import { Calendar } from "@/components/ui/calendar"

// Component to display headlines
import { toast } from "@/hooks/use-toast"
import { useAuth0 } from "@auth0/auth0-react"
import { AnimatePresence } from "framer-motion"
import { motion } from "framer-motion"
import { DailySummaryPanel } from "./components/MarketHeadlines/DailySummaryPanel"
import posthog from "posthog-js"
import { AIAnalysisPanel } from "./components/MarketHeadlines/AIAnalysisPanel"
import { getFormattedDate } from "./utilities"
import { UserReport } from "@/presentation/components/UserReport/UserReport"
import { useUpdateUser } from "@/hooks/useUpdateUser"
import { AssetSelector } from "@/presentation/components/AssetSelector/AssetSelector"
import { Edit2 } from "lucide-react"
import { AssetManager } from "@/presentation/components/AssetManager/AssetManager"
import { Settings } from "lucide-react"
import { BarChart3 } from "lucide-react"

export const HomescreenHeader = ({ name }: { name: string }) => {
  return (
    <div className="flex justify-between items-center">
      <div>
        <h1 className="text-2xl font-light text-white">Welcome, {"Trader"}</h1>
        <h3 className="text-lg font-light text-gray-500">
          {getFormattedDate()}
        </h3>
      </div>
    </div>
  )
}

type NewsTab = "headlines" | "articles"

const SYMBOLS = ["ESUSD", "NQUSD", "YMUSD"]
const SYMBOL_LABELS = {
  ESUSD: "S&P 500",
  NQUSD: "NASDAQ",
  YMUSD: "DOW JONES",
}

const DEFAULT_VISIBLE_SYMBOLS = ["ESUSD", "NQUSD", "YMUSD", "RTYUSD"]

const GLOBAL_SYMBOLS = ["USDCNY", "USDJPY", "EURUSD", "AUDUSD", "GBPUSD"]
const GLOBAL_SYMBOL_LABELS = {
  USDCNY: "USD/CNY",
  USDJPY: "USD/JPY",
  EURUSD: "EUR/USD",
  AUDUSD: "AUD/USD",
  GBPUSD: "GBP/USD",
}

const CRYPTO_SYMBOLS = ["GCUSD", "SIUSD", "CLUSD"]
const CRYPTO_SYMBOL_LABELS = {
  GCUSD: "Gold",
  SIUSD: "Silver",
  CLUSD: "Crude Oil",
}

const VOLATILITY_SYMBOLS = ["^VIX"]
const VOLATILITY_LABELS = {
  "^VIX": "VIX",
}

const SAFE_HAVENS_SYMBOLS = ["XAUUSD", "CHFUSD", "JPYUSD"]
const SAFE_HAVENS_SYMBOL_LABELS = {
  XAUUSD: "Gold",
  CHFUSD: "CHF",
  JPYUSD: "JPY",
}

const GLOBAL_INDICES_SYMBOLS = ["ESUSD", "YMUSD", "NQUSD", "EWU", "EWJ", "EZU"]
const GLOBAL_INDICES_LABELS: { [key: string]: string } = {
  ESUSD: "S&P 500",
  YMUSD: "Dow Jones",
  NQUSD: "Nasdaq",
  EWU: "FTSE 100",
  EWJ: "Nikkei 225",
  EZU: "Euro Stoxx",
}

const CURRENCY_PAIRS_SYMBOLS = [
  "XAUUSD",
  "USDJPY",
  "EURUSD",
  "GBPUSD",
  "GBPJPY",
  "USDCHF",
]
const CURRENCY_PAIRS_LABELS: { [key: string]: string } = {
  XAUUSD: "XAU/USD",
  USDJPY: "USD/JPY",
  EURUSD: "EUR/USD",
  GBPUSD: "GBP/USD",
  GBPJPY: "GBP/JPY",
  USDCHF: "USD/CHF",
}

const CURRENCY_SENTIMENT_MAPPING = CURRENCY_PAIRS_SYMBOLS.map((symbol) => ({
  [symbol]: symbol,
}))

const COMMODITIES_SYMBOLS = [
  "GCUSD",
  "SIUSD",
  "CLUSD",
  "NGUSD",
  "KCUSX",
  "ZSUSX",
]
const COMMODITIES_LABELS: { [key: string]: string } = {
  GCUSD: "Gold",
  SIUSD: "Silver",
  CLUSD: "Crude Oil",
  NGUSD: "Natural Gas",
  KCUSX: "Coffee",
  ZSUSX: "Soybean",
}

const US_MARKET_COLORS = {
  ESUSD: "rgba(255, 107, 107, 0.7)", // #FF6B6B Muted Red
  NQUSD: "rgba(165, 201, 255, 0.7)", // #A5C9FF Soft Blue
  YMUSD: "rgba(255, 209, 102, 0.7)", // #FFD166 Warm Amber
  RTYUSD: "rgba(136, 132, 216, 0.7)", // #8884D8 Muted Purple
}

export const GLOBAL_CURRENCY_COLORS = {
  USDCNY: "rgba(127, 179, 213, 0.7)", // #7FB3D5 Desaturated Blue
  USDJPY: "rgba(229, 152, 102, 0.7)", // #E59866 Muted Orange
  EURUSD: "rgba(195, 155, 211, 0.7)", // #C39BD3 Soft Violet
  AUDUSD: "rgba(118, 215, 196, 0.7)", // #76D7C4 Subtle Teal
  GBPUSD: "rgba(171, 178, 185, 0.7)", // #ABB2B9 Muted Gray
}

const SAFE_HAVENS_COLORS = {
  XAUUSD: "rgba(212, 175, 55, 0.7)", // #D4AF37 Deep Gold
  CHFUSD: "rgba(96, 125, 176, 0.7)", // #607D8B - Muted Steel Blue
  JPYUSD: "rgba(175, 122, 197, 0.7)", // #AF7AC5 Dusty Purple
}

const COMMODITIES_COLORS = {
  GCUSD: "rgba(212, 175, 55, 0.7)", // #D4AF37 - Deep Gold
  SIUSD: "rgba(191, 201, 202, 0.7)", // #BFC9CA - Soft Silver
  CLUSD: "rgba(160, 120, 75, 0.7)", // #A0784B - Muted Bronze
}

const VOLATILITY_COLORS = {
  "^VIX": "rgba(255, 255, 255, 0.7)", // #FFFFFF Soft White
}

type SubTab =
  | "personal"
  | "sentiment"
  | "currencies"
  | "global"
  | "commodities"
  | "economy"

export const HomeScreen = () => {
  const [activeTab, setActiveTab] = useState<SubTab>("personal")
  const [selectedNewsTab, setSelectedNewsTab] = useState<NewsTab>("headlines")
  const [isPrioritized, setIsPrioritized] = useState(false)
  const [open, setOpen] = useState(false)
  const [isDailySummaryLoading, setIsDailySummaryLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date>()
  const [showDailySummary, setShowDailySummary] = useState(false)
  const [dailySummaryAnalysis, setDailySummaryAnalysis] = useState<any>(null)
  const { userData } = useUser()
  const { updateUser } = useUpdateUser()
  const { getAccessTokenSilently, user } = useAuth0()
  const userSymbols = userData?.tradingInfo?.specificAssets?.forex ?? []
  const mappedUserSymbols = userSymbols.map((symbol: string) => {
    return {
      [symbol]: symbol,
    }
  })

  const [showAIAnalysis, setShowAIAnalysis] = useState(false)
  const [aiAnalysisData, setAIAnalysisData] = useState<any>(null)
  const [isUserReportOpen, setIsUserReportOpen] = useState(false)
  const [isAssetSelectorOpen, setIsAssetSelectorOpen] = useState(false)
  const [editingAssetIndex, setEditingAssetIndex] = useState<
    number | undefined
  >(undefined)
  const [isAssetManagerOpen, setIsAssetManagerOpen] = useState(false)

  // Add this effect to check if we should show the report on first visit
  useEffect(() => {
    const handleFirstTimeReport = async () => {
      if (!userData?.showFirstTimeReport || !user?.email) return

      setIsUserReportOpen(true)

      try {
        await updateUser({
          email: user.email,
          updates: {
            showFirstTimeReport: false,
          },
        })
      } catch (error) {
        console.error("Failed to update first time report status:", error)
      }
    }

    handleFirstTimeReport()
  }, [userData?.showFirstTimeReport, user?.email, updateUser])

  const handleGetDailySummary = async () => {
    setIsDailySummaryLoading(true)
    setShowDailySummary(true)
    try {
      const dateParam = selectedDate
        ? format(selectedDate, "yyyy-MM-dd")
        : undefined
      const queryString = dateParam ? `?date=${dateParam}` : ""

      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/headlines/daily${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error("Failed to fetch daily summary")
      }

      const data = await response.json()
      setDailySummaryAnalysis(data)
      posthog.capture("daily_summary_generated", {
        user: userData?.email,
      })
    } catch (error) {
      console.error("Error fetching daily summary:", error)
      toast({
        title: "Error",
        description: "Failed to fetch daily summary",
        variant: "destructive",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    } finally {
      setIsDailySummaryLoading(false)
    }
  }

  const handleCloseDailySummary = () => {
    setShowDailySummary(false)
    setDailySummaryAnalysis(null)
  }

  const handleTabChange = (tab: SubTab) => {
    posthog.capture("currency_sub_tab_clicked", {
      tab_name: tab,
      previous_tab: activeTab,
      user: user?.email,
    })
    setActiveTab(tab)
  }

  const handleShowAnalysis = (data: any) => {
    setAIAnalysisData(data)
    setShowAIAnalysis(true)
  }

  const handleShowUserReport = () => {
    setIsUserReportOpen(true)
    posthog.capture("user_report_opened", {
      user: user?.email,
      source: "header_button",
      dashboard: "currencies",
    })
  }

  return (
    <div className="flex flex-col min-h-screen bg-black">
      <div className="flex flex-col gap-4 -mt-4">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4">
          <div className="flex flex-col md:flex-row items-start md:items-end gap-4">
            <HomescreenHeader name={(userData?.name as string) ?? "Trader"} />

            {/* Tab Navigation */}
            {!isPrioritized && (
              <div className="flex flex-wrap md:flex-nowrap gap-2 text-left">
                <button
                  onClick={() => handleTabChange("personal")}
                  className={`px-3 py-2 text-xs sm:text-sm font-medium rounded-lg transition-colors duration-200 text-left
                    ${activeTab === "personal" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
                >
                  Personal
                </button>
                <button
                  onClick={() => handleTabChange("sentiment")}
                  className={`px-3 py-2 text-xs sm:text-sm font-medium rounded-lg transition-colors duration-200 text-left
                    ${activeTab === "sentiment" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
                >
                  Overview
                </button>
                <button
                  onClick={() => handleTabChange("currencies")}
                  className={`px-3 py-2 text-xs sm:text-sm font-medium rounded-lg transition-colors duration-200 text-left
                    ${activeTab === "currencies" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
                >
                  Currencies
                </button>
                <button
                  onClick={() => handleTabChange("global")}
                  className={`px-3 py-2 text-xs sm:text-sm font-medium rounded-lg transition-colors duration-200 text-left
                    ${activeTab === "global" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
                >
                  Global Markets
                </button>
                <button
                  onClick={() => handleTabChange("commodities")}
                  className={`px-3 py-2 text-xs sm:text-sm font-medium rounded-lg transition-colors duration-200 text-left
                    ${activeTab === "commodities" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
                >
                  Commodities
                </button>
                <button
                  onClick={() => handleTabChange("economy")}
                  className={`px-3 py-2 text-xs sm:text-sm font-medium rounded-lg transition-colors duration-200 text-left
                    ${activeTab === "economy" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
                >
                  Global Economy
                </button>
              </div>
            )}
          </div>

          {/* Action Buttons */}
          <div className="flex flex-wrap lg:flex-nowrap items-center gap-2">
            <div className="flex items-center gap-2">
              <button
                onClick={handleShowUserReport}
                className="inline-flex items-center gap-2 px-3 py-2 text-white bg-gradient-to-r from-purple-500/10 to-blue-500/10 border border-purple-500/30 text-xs sm:text-sm rounded-md hover:from-purple-500/20 hover:to-blue-500/20 transition-colors whitespace-nowrap"
              >
                <Brain className="w-3 h-3 sm:w-4 sm:h-4 text-purple-400" />
                <span className="text-xs sm:text-sm font-medium text-purple-300">
                  Your Report
                </span>
              </button>
              <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                  <AIGenerateButton
                    text="Currencies Recap"
                    isGenerating={isDailySummaryLoading}
                    className="text-xs sm:text-sm px-3 py-2 whitespace-nowrap"
                  />
                </PopoverTrigger>
                <PopoverContent
                  className="w-[300px] p-0 bg-black/95 border border-[#333] shadow-xl backdrop-blur-sm"
                  align="end"
                  side="bottom"
                  sideOffset={5}
                  alignOffset={0}
                >
                  <div className="flex flex-col">
                    <div className="p-4 border-b border-zinc-800/50">
                      <span className="text-zinc-400 text-sm">
                        {selectedDate
                          ? format(selectedDate, "MMMM d, yyyy")
                          : "Select a date"}
                      </span>
                    </div>

                    <Calendar
                      mode="single"
                      selected={selectedDate}
                      onSelect={setSelectedDate}
                      className="p-0 mx-auto"
                      disabled={{ after: new Date() }}
                      classNames={{
                        months: "flex flex-col space-y-4",
                        month: "space-y-4",
                        caption:
                          "flex justify-center pt-1 relative items-center text-zinc-400",
                        caption_label: "text-sm font-medium",
                        nav: "space-x-1 flex items-center",
                        nav_button:
                          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 hover:bg-zinc-800/50 rounded-md text-zinc-400",
                        nav_button_previous: "absolute left-1",
                        nav_button_next: "absolute right-1",
                        table: "w-full border-collapse space-y-1",
                        head_row: "flex",
                        head_cell:
                          "text-zinc-400 rounded-md w-9 font-normal text-[0.8rem]",
                        row: "flex w-full mt-2 justify-center",
                        cell: "text-center text-sm p-0 relative [&:has([aria-selected])]:bg-zinc-800/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                        day: "h-9 w-9 p-0 font-normal aria-selected:opacity-100 text-zinc-400 hover:bg-zinc-800/50 rounded-md transition-colors",
                        day_selected:
                          "bg-purple-400/30 text-white hover:bg-purple-400 hover:text-white focus:bg-purple-400 focus:text-white",
                        day_today: "bg-zinc-800/50 text-white",
                        day_outside: "opacity-50",
                        day_disabled: "opacity-30 cursor-not-allowed",
                        day_range_middle:
                          "aria-selected:bg-zinc-800/50 aria-selected:text-zinc-400",
                        day_hidden: "invisible",
                      }}
                    />
                    <div className="p-4 border-t border-zinc-800/50 space-y-2">
                      <Button
                        onClick={() => {
                          handleGetDailySummary()
                          setOpen(false)
                        }}
                        disabled={!selectedDate || isDailySummaryLoading}
                        className="w-full hover:from-purple-500/20 hover:to-blue-500/20 bg-gradient-to-r from-purple-500/10 to-blue-500/10 text-purple-300 border border-purple-500/30 transition-colors duration-200"
                      >
                        {isDailySummaryLoading ? (
                          <Loader2 className="w-4 h-4 animate-spin mr-2" />
                        ) : (
                          <Brain className="w-4 h-4 mr-2" />
                        )}
                        View Market Summary
                      </Button>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
            <div className="flex items-center gap-2">
              {activeTab === "personal" && (
                <button
                  onClick={() => setIsAssetManagerOpen(true)}
                  className="inline-flex items-center justify-center w-9 h-9 text-white bg-[#111] border 
                  border-[#333] text-xs sm:text-sm rounded-md hover:bg-[#333] transition-colors"
                >
                  <SquarePen className="h-3 w-3 sm:h-4 sm:w-4" />
                </button>
              )}
              <button
                onClick={() => {
                  setIsPrioritized(!isPrioritized)
                  posthog.capture("prioritized_view_clicked", {
                    user: user?.email,
                    view: isPrioritized ? "dashboard" : "prioritized",
                  })
                }}
                className="inline-flex items-center justify-center w-9 h-9 text-white bg-[#111] border 
                border-[#333] text-xs sm:text-sm rounded-md hover:bg-[#333] transition-colors"
              >
                {!isPrioritized ? (
                  <BetweenVerticalEnd className="h-3 w-3 sm:h-4 sm:w-4" />
                ) : (
                  <Grid3x3 className="h-3 w-3 sm:h-4 sm:w-4" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {isPrioritized ? (
        <div className="grid grid-cols-1 md:grid-cols-1 2xl:grid-cols-2 gap-4 mt-6">
          <div className="rounded-lg lg:col-span-1 border border-[#333] p-4 max-h-[calc(100vh-200px)] bg-gradient-to-b from-white/[0.03] to-black/30 overflow-y-auto">
            <EconomicCalendar />
          </div>
          <div className="rounded-lg lg:col-span-1 border border-[#333] p-4 max-h-[calc(100vh-200px)] bg-gradient-to-b from-white/[0.03] to-black/30 overflow-y-auto">
            <MarketHeadlines />
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-6 gap-4 mt-6">
          {activeTab === "personal" ? (
            <>
              {userSymbols
                .filter(Boolean)
                .map((symbol: string, index: number) => (
                  <div
                    key={symbol}
                    className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 h-[400px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
                  >
                    <MultiSymbolChart
                      symbols={[symbol]}
                      symbolLabels={{ [symbol]: symbol }}
                      height={400}
                      title={symbol}
                      showSentiment={true}
                      sentimentSymbols={mappedUserSymbols}
                      showMrktAI={true}
                      onShowAnalysis={handleShowAnalysis}
                    />
                  </div>
                ))}

              {userSymbols.filter(Boolean).length === 0 && (
                <div className="col-span-full flex flex-col items-center justify-center py-16 text-center">
                  <div className="p-6 rounded-full bg-white/5 mb-4">
                    <Plus className="w-8 h-8 text-white/50" />
                  </div>
                  <h3 className="text-xl font-medium text-white/80 mb-2">
                    No Assets Added
                  </h3>
                  <p className="text-white/50 max-w-md mb-6">
                    Add assets to your personal dashboard and market report
                  </p>
                  <Button
                    onClick={() => setIsAssetManagerOpen(true)}
                    className="bg-gradient-to-r from-purple-500/20 to-blue-500/20 hover:from-purple-500/30 hover:to-blue-500/30 text-white border border-purple-500/30"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Assets
                  </Button>
                </div>
              )}
            </>
          ) : activeTab === "sentiment" ? (
            <>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 h-[400px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/20
                  hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={SYMBOLS}
                  symbolLabels={SYMBOL_LABELS}
                  defaultVisibleSymbols={DEFAULT_VISIBLE_SYMBOLS}
                  height={400}
                  title="US Market Indices"
                  symbolColors={US_MARKET_COLORS}
                  tickCount={4}
                  showSentiment={false}
                  sentimentSymbols={[{ ESUSD: "US500" }]}
                  onShowAnalysis={handleShowAnalysis}
                />
              </div>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 h-[400px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={GLOBAL_SYMBOLS}
                  symbolLabels={GLOBAL_SYMBOL_LABELS}
                  height={450}
                  title="Global Currencies"
                  symbolColors={GLOBAL_CURRENCY_COLORS}
                  tickCount={3}
                  onShowAnalysis={handleShowAnalysis}
                />
              </div>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 h-[400px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={SAFE_HAVENS_SYMBOLS}
                  symbolLabels={SAFE_HAVENS_SYMBOL_LABELS}
                  height={400}
                  title="Safe Havens"
                  symbolColors={SAFE_HAVENS_COLORS}
                  tickCount={4}
                  onShowAnalysis={handleShowAnalysis}
                />
              </div>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 h-[400px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={CRYPTO_SYMBOLS}
                  symbolLabels={CRYPTO_SYMBOL_LABELS}
                  height={400}
                  title="Commodities"
                  symbolColors={COMMODITIES_COLORS}
                  tickCount={4}
                  onShowAnalysis={handleShowAnalysis}
                />
              </div>
              <div
                className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 h-[400px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
              >
                <MultiSymbolChart
                  symbols={VOLATILITY_SYMBOLS}
                  symbolLabels={VOLATILITY_LABELS}
                  height={400}
                  title="Market Volatility"
                  defaultTimeRange="3M"
                  symbolColors={VOLATILITY_COLORS}
                  tickCount={5}
                  onShowAnalysis={handleShowAnalysis}
                />
              </div>
              <div className="rounded-lg border border-[#333] p-4 h-[400px] bg-gradient-to-b from-white/[0.03] to-black/30">
                <CentralBankEvents />
              </div>
            </>
          ) : activeTab === "currencies" ? (
            <>
              {CURRENCY_PAIRS_SYMBOLS.map((symbol) => (
                <div
                  key={symbol}
                  className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 h-[400px] 
                    shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                    before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
                    after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                    after:pointer-events-none 
                    relative 
                    bg-gradient-to-b from-black/50 to-black/30
                    hover:border-white/20 transition-colors duration-200"
                >
                  <MultiSymbolChart
                    symbols={[symbol]}
                    symbolLabels={{ [symbol]: CURRENCY_PAIRS_LABELS[symbol] }}
                    height={400}
                    title={CURRENCY_PAIRS_LABELS[symbol]}
                    showSentiment={true}
                    sentimentSymbols={CURRENCY_SENTIMENT_MAPPING}
                    showMrktAI={true}
                    onShowAnalysis={handleShowAnalysis}
                  />
                </div>
              ))}
            </>
          ) : activeTab === "global" ? (
            <>
              {GLOBAL_INDICES_SYMBOLS.map((symbol, index) => (
                <div
                  key={symbol}
                  className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 h-[400px] 
                    shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                    before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
                    after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                    after:pointer-events-none 
                    relative 
                    bg-gradient-to-b from-black/50 to-black/30
                    hover:border-white/20 transition-colors duration-200"
                >
                  <MultiSymbolChart
                    symbols={[symbol]}
                    symbolLabels={{ [symbol]: GLOBAL_INDICES_LABELS[symbol] }}
                    height={400}
                    title={GLOBAL_INDICES_LABELS[symbol]}
                    colors={["#FFA726"]}
                    showSentiment={true}
                    sentimentSymbols={[
                      { ESUSD: "ESUSD" },
                      { YMUSD: "YMUSD" },
                      { NQUSD: "NQUSD" },
                      { EWU: "EWU" },
                      { EWJ: "EWJ" },
                      { EZU: "EZU" },
                    ]}
                    showMrktAI={true}
                    onShowAnalysis={handleShowAnalysis}
                  />
                </div>
              ))}
            </>
          ) : activeTab === "economy" ? (
            <div className="col-span-full">
              <GlobalEconomy />
            </div>
          ) : (
            <>
              {COMMODITIES_SYMBOLS.map((symbol) => (
                <div
                  key={symbol}
                  className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 h-[400px] 
                    shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                    before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
                    after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                    after:pointer-events-none 
                    relative 
                    bg-gradient-to-b from-black/50 to-black/30
                    hover:border-white/20 transition-colors duration-200"
                >
                  <MultiSymbolChart
                    symbols={[symbol]}
                    symbolLabels={{ [symbol]: COMMODITIES_LABELS[symbol] }}
                    height={400}
                    title={COMMODITIES_LABELS[symbol]}
                    colors={["#4CAF50"]}
                    showSentiment={true}
                    sentimentSymbols={[
                      { GCUSD: "GCUSD" },
                      { SIUSD: "SIUSD" },
                      { CLUSD: "CLUSD" },
                      { NGUSD: "NGUSD" },
                      { KCUSX: "KCUSX" },
                      { ZSUSX: "ZSUSX" },
                    ]}
                    showMrktAI={true}
                    onShowAnalysis={handleShowAnalysis}
                  />
                </div>
              ))}
            </>
          )}

          {activeTab !== "economy" && (
            <>
              <div className="rounded-lg lg:col-span-3 border border-[#333] p-4 h-[700px] bg-gradient-to-b from-white/[0.03] to-black/30">
                <EconomicCalendar />
              </div>

              <div className="rounded-lg lg:col-span-3 border border-[#333] p-4 h-[700px] bg-gradient-to-b from-white/[0.03] to-black/30">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-base font-medium text-white/80">
                    Market Updates
                  </h2>
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        setSelectedNewsTab("headlines")
                        posthog.capture("market_news_tab_clicked", {
                          tab: "headlines",
                          user: user?.email,
                        })
                      }}
                      className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                          ${
                            selectedNewsTab === "headlines"
                              ? "bg-white/10 text-white"
                              : "text-gray-400 hover:text-white"
                          }`}
                    >
                      Headlines
                    </button>
                    <button
                      onClick={() => {
                        setSelectedNewsTab("articles")
                        posthog.capture("market_news_tab_clicked", {
                          tab: "articles",
                          user: user?.email,
                        })
                      }}
                      className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                          ${
                            selectedNewsTab === "articles"
                              ? "bg-white/10 text-white"
                              : "text-gray-400 hover:text-white"
                          }`}
                    >
                      Articles
                    </button>
                  </div>
                </div>
                <div className="h-[calc(100%-60px)] overflow-auto">
                  {selectedNewsTab === "headlines" ? (
                    <MarketHeadlines />
                  ) : (
                    <MarketNews />
                  )}
                </div>
              </div>
            </>
          )}
          <AnimatePresence>
            {showDailySummary && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                className="fixed inset-0 bg-black/20 z-50"
              >
                <DailySummaryPanel
                  analysis={dailySummaryAnalysis?.analysis}
                  isLoading={isDailySummaryLoading}
                  onClose={handleCloseDailySummary}
                  selectedDate={selectedDate}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {showAIAnalysis && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                className="fixed inset-0 bg-black/10 z-50"
              >
                <AIAnalysisPanel
                  visible={showAIAnalysis}
                  onClose={() => setShowAIAnalysis(false)}
                  symbol={aiAnalysisData?.symbol}
                  analysis={aiAnalysisData?.analysis}
                  oneHourSentiment={aiAnalysisData?.oneHourSentiment}
                  dailySentiment={aiAnalysisData?.dailySentiment}
                  weeklySentiment={aiAnalysisData?.weeklySentiment}
                  oneHourReasoning={aiAnalysisData?.oneHourReasoning}
                  dailyReasoning={aiAnalysisData?.dailyReasoning}
                  weeklyReasoning={aiAnalysisData?.weeklyReasoning}
                  generatedAt={aiAnalysisData?.generatedAt}
                  intraday_levels={aiAnalysisData?.intraday_levels}
                  querySymbol={aiAnalysisData.querySymbol}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
      <UserReport
        isOpen={isUserReportOpen}
        onClose={() => setIsUserReportOpen(false)}
        symbols={userData?.tradingInfo?.specificAssets?.forex}
      />
      <AssetSelector
        isOpen={isAssetSelectorOpen}
        onClose={() => {
          setIsAssetSelectorOpen(false)
          setEditingAssetIndex(undefined)
        }}
        assetType="forex"
        currentIndex={editingAssetIndex}
      />
      <AssetManager
        isOpen={isAssetManagerOpen}
        onClose={() => setIsAssetManagerOpen(false)}
        assetType="forex"
      />
    </div>
  )
}
