import { HeadlineAnalysisChipEnums } from "../enums/HeadlineAnalysisChipEnums"
import { HeadlineAnalysisDialogChip } from "./HeadlineAnalysisDialogChip"
import { DNA } from "react-loader-spinner"
import { HeadlineAnalysis } from "../Data/HeadlineAnalysisData"
import { X, Brain, TrendingUp, TrendingDown, Info } from "lucide-react"
import { useState } from "react"
import posthog from "posthog-js"
import { useAuth0 } from "@auth0/auth0-react"

interface AnalysisDialogContentProps {
  headlineAnalysisData?: HeadlineAnalysis
  headlineTitle?: string
  headlineTime: string
  isLoading: boolean
  onClose: () => void
}

export type Asset = {
  asset: string
  impact: string
  explanation: string
}

export const AnalysisDialogContent = ({
  headlineAnalysisData,
  headlineTime,
  headlineTitle,
  isLoading,
  onClose,
}: AnalysisDialogContentProps) => {
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null)
  const { user } = useAuth0()

  const getHeadlineAnalysisChipType = (impact: string) => {
    const formattedImpact =
      impact.charAt(0).toUpperCase() + impact.slice(1).toLowerCase()
    if (formattedImpact === "Bullish") {
      return HeadlineAnalysisChipEnums.Bullish
    } else if (formattedImpact === "Bearish") {
      return HeadlineAnalysisChipEnums.Bearish
    } else {
      return HeadlineAnalysisChipEnums.General
    }
  }

  return (
    <div className="bg-gray-800/40 rounded-lg border border-gray-800 p-4">
      {!isLoading ? (
        <>
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center gap-2">
              <Brain className="w-5 h-5 text-purple-400/80" />
              <h3 className="font-medium text-lg text-white">
                MRKT AI Analysis
              </h3>
            </div>
            <button
              onClick={onClose}
              className="p-1.5 rounded-md hover:bg-gray-800 transition-colors duration-200"
            >
              <X className="w-4 h-4 text-gray-400" />
            </button>
          </div>
          <p className="text-md leading-relaxed font-normal text-gray-200/80 mb-4">
            {headlineAnalysisData?.summary}
          </p>

          <div className="space-y-4">
            <div className="flex flex-wrap gap-2">
              {headlineAnalysisData?.assets.map((asset: Asset, index) => {
                const formattedImpact = asset.impact.toLowerCase()
                const isBullish = formattedImpact === "bullish"
                const isBearish = formattedImpact === "bearish"
                const isSelected = selectedAsset?.asset === asset.asset

                return (
                  <button
                    key={index}
                    onClick={() => {
                      setSelectedAsset(isSelected ? null : asset)
                      posthog.capture(
                        "headline_analysis_asset_analysis_clicked",
                        {
                          asset: asset.asset,
                          user: user?.email,
                          analysis: asset.explanation,
                          impact: asset.impact,
                        },
                      )
                    }}
                    className={`
                      flex items-center gap-2 px-3 py-1.5 rounded-full
                      ${
                        isBullish
                          ? "bg-green-400/25 border-green-400/40 text-green-400 shadow-[0_0_12px_-3px_rgba(74,222,128,0.4)]"
                          : isBearish
                            ? "bg-red-400/25 border-red-400/40 text-red-400 shadow-[0_0_12px_-3px_rgba(248,113,113,0.4)]"
                            : "bg-gray-400/25 border-gray-400/40 text-gray-200 shadow-[0_0_12px_-3px_rgba(156,163,175,0.4)]"
                      }
                      ${
                        isSelected
                          ? "scale-102 shadow-lg border-opacity-100"
                          : "hover:scale-105 border-opacity-40"
                      }
                      ${selectedAsset && !isSelected ? "opacity-40" : "opacity-100"}
                      border font-semibold text-sm transition-all duration-200
                      [text-shadow:_0_1px_1px_rgb(0_0_0_/_40%)]
                      group
                    `}
                  >
                    {isBullish && <TrendingUp className="w-4 h-4" />}
                    {isBearish && <TrendingDown className="w-4 h-4" />}
                    {asset.asset}
                    <Info
                      className={`
                        w-3.5 h-3.5 
                        ${
                          isSelected
                            ? "text-gray-400"
                            : "opacity-50 group-hover:opacity-100"
                        }
                        transition-all duration-200
                      `}
                    />
                  </button>
                )
              })}
            </div>

            {selectedAsset && (
              <div className="mt-3 animate-in fade-in slide-in-from-top-2 duration-200">
                <div
                  className={`
                    flex flex-col gap-2 p-3 rounded-lg
                    ${
                      selectedAsset.impact.toLowerCase() === "bullish"
                        ? "bg-green-400/10"
                        : selectedAsset.impact.toLowerCase() === "bearish"
                          ? "bg-red-400/10"
                          : "bg-gray-800/40"
                    }
                  `}
                >
                  <div className="flex items-center gap-2">
                    {selectedAsset.impact.toLowerCase() === "bullish" ? (
                      <TrendingUp className="w-4 h-4 text-green-400" />
                    ) : selectedAsset.impact.toLowerCase() === "bearish" ? (
                      <TrendingDown className="w-4 h-4 text-red-400" />
                    ) : null}
                    <span className="text-gray-400 text-[14px]">
                      Potential Impact on
                    </span>
                    <span
                      className={`
                        font-medium text-[14px]
                        ${
                          selectedAsset.impact.toLowerCase() === "bullish"
                            ? "text-green-400"
                            : selectedAsset.impact.toLowerCase() === "bearish"
                              ? "text-red-400"
                              : "text-gray-200"
                        }
                      `}
                    >
                      {selectedAsset.asset}
                    </span>
                  </div>
                  <p className="text-[14px] leading-relaxed text-gray-300 font-normal">
                    {selectedAsset.explanation}
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center py-4">
          <DNA
            visible={true}
            height="60"
            width="60"
            ariaLabel="dna-loading"
            wrapperStyle={{ color: "orange" }}
            wrapperClass="dna-wrapper"
          />
        </div>
      )}
    </div>
  )
}
