import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import Flag from "react-flagkit"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"

interface MarketIndex {
  symbol: string
  name: string
  price: number | null
  changesPercentage: number | null
  change: number | null
  previousClose: number | null
}

const MARKET_INDEXES = [
  { symbol: "^GSPC", name: "S&P 500", region: "USA" },
  { symbol: "^DJI", name: "Dow Jones", region: "USA" },
  { symbol: "^NDX", name: "Nasdaq", region: "USA" },
  { symbol: "^FTSE", name: "FTSE 100", region: "UK" },
  { symbol: "^GDAXI", name: "DAX", region: "DE" },
  { symbol: "^N225", name: "Nikkei 225", region: "JP" },
  { symbol: "^HSI", name: "Hang Seng", region: "HK" },
  { symbol: "^STOXX50E", name: "EURO STOXX 50", region: "EU" },
  { symbol: "EWL", name: "SMI", region: "CH" },
]

export const getCountryFlag = (countryCode: string) => {
  let isoCode = countryCode
  if (countryCode === "USA") {
    isoCode = "US"
  } else if (countryCode === "UK") {
    isoCode = "GB"
  }
  return <Flag country={isoCode} size={20} className="rounded-sm" />
}

const getFormattedName = (name: string) => {
  if (name === "Dow Jones Industrial Average") {
    return "Dow Jones"
  } else if (name === "DAX Performance Index") {
    return "DAX"
  } else if (name === "Nikkei 225") {
    return "Nikkei"
  } else if (name === "Hang Seng Index") {
    return "Hang Seng"
  } else if (name === "EURO STOXX 50") {
    return "EURO STOXX"
  } else if (name === "iShares MSCI Switzerland ETF") {
    return "SMI"
  }
  return name
}

const getPriceArrow = (price: number | null, previousClose: number | null) => {
  if (!price || !previousClose) return null
  return price > previousClose ? (
    <span className="text-emerald-500">↑</span>
  ) : price < previousClose ? (
    <span className="text-red-500">↓</span>
  ) : (
    <span className="text-white/40">→</span>
  )
}

export const GlobalMarketIndexes = () => {
  const { getAccessTokenSilently } = useAuth0()

  const fetchMarketIndex = async (symbol: string): Promise<MarketIndex> => {
    try {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/equities/commodity/${symbol}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      const data = await response.json()

      if (!data || !data.symbol) {
        console.error(`Invalid data format for ${symbol}:`, data)
        throw new Error(`Invalid data format for ${symbol}`)
      }

      return data
    } catch (error) {
      console.error(`Error fetching data for ${symbol}:`, error)
      throw error
    }
  }

  const { data: marketData, isLoading } = useQuery({
    queryKey: ["marketIndexes"],
    queryFn: async () => {
      const promises = MARKET_INDEXES.map((index) =>
        fetchMarketIndex(index.symbol),
      )
      const results = await Promise.all(promises)
      const mappedResults = results.map((result, index) => {
        return {
          symbol: MARKET_INDEXES[index].symbol,
          name: result.name,
          region: MARKET_INDEXES[index].region,
          price: result.price ?? null,
          change: result.change ?? null,
          changesPercentage: result.changesPercentage ?? null,
          previousClose: result.previousClose ?? null,
        }
      })
      return mappedResults
    },
  })

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-48">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white/20"></div>
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-xs font-medium text-white/60 mb-3">Global Markets</h2>
      <SimpleBar className="flex-1 min-h-0 -mx-2 px-2">
        <div>
          <div className="flex items-center justify-between px-2.5 mb-2">
            <span className="text-[10px] font-medium text-white/40">Index</span>
            <div className="flex items-center">
              <span className="text-[10px] font-medium text-white/40 w-[100px] text-right">
                Price
              </span>
              <span className="text-[10px] font-medium text-white/40 w-[100px] text-right">
                Prev Close
              </span>
              <span className="text-[10px] font-medium text-white/40 w-[100px] text-right">
                Change
              </span>
            </div>
          </div>
          <div className="space-y-2">
            {marketData?.map((index) => (
              <div
                key={index.symbol}
                className="flex items-center justify-between py-2 px-2.5 rounded-lg border border-white/5 bg-black/20 hover:bg-black/30 hover:border-white/10 transition-all duration-200"
              >
                <div className="flex items-center gap-2">
                  <span className="text-[10px] text-white/40">
                    {getCountryFlag(index.region)}
                  </span>
                  <span className="text-xs font-medium text-white/80 group-hover:text-white/90">
                    {getFormattedName(index.name)}
                  </span>
                </div>

                <div className="flex items-center">
                  <div className="flex items-center gap-1 w-[100px] justify-end">
                    <span className="text-xs font-medium text-white/80">
                      ${index.price ? index.price.toFixed(2) : "N/A"}
                    </span>
                    {getPriceArrow(index.price, index.previousClose)}
                  </div>
                  <div className="flex items-center w-[100px] justify-end">
                    <span className="text-[10px] text-white/30">
                      ${index.previousClose?.toFixed(2) ?? "N/A"}
                    </span>
                  </div>

                  <div className="flex items-center gap-4 w-[100px] justify-end">
                    {/* <span className="text-xs text-white/40">
                      {index.change
                        ? `${index.change >= 0 ? "+" : ""}${index.change.toFixed(2)}`
                        : "N/A"}
                    </span> */}
                    <span
                      className={`text-xs font-medium ${
                        index.changesPercentage && index.changesPercentage >= 0
                          ? "text-emerald-500/80"
                          : "text-red-500/80"
                      }`}
                    >
                      {index.changesPercentage
                        ? `${
                            index.changesPercentage >= 0 ? "+" : ""
                          }${index.changesPercentage.toFixed(2)}%`
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </SimpleBar>
    </div>
  )
}
