import { Brain, Check, Copy } from "lucide-react"
import { useState } from "react"
import { HeadlineAnalysis } from "./AnalysisDialog/Data/HeadlineAnalysisData"
import { AnalysisDialogContent } from "./AnalysisDialog/components/HeadlineAnalysisDialogContent"
import { motion } from "framer-motion"
import { useAuth0 } from "@auth0/auth0-react"
import { useUser } from "@/providers"
import { usePostHog } from "posthog-js/react"
import { toast } from "@/hooks/use-toast"
import { toPng } from "html-to-image"
import { format } from "date-fns"
import { headlinesKeywords } from "./AnalysisDialog/enums/HeadlineAnalysisChipEnums"

interface HeadlineItemProps {
  headlineTitle: string
  headlineTime: string
  relativeTime: string
  className?: string
  highlightEnabled?: boolean
}

interface HeadlineAnalysisState {
  isLoading: boolean
  data?: HeadlineAnalysis
  error?: string
}

export const HeadlineItem = ({
  headlineTitle,
  headlineTime,
  relativeTime,
  className = "",
  highlightEnabled = false,
}: HeadlineItemProps) => {
  const [expanded, setExpanded] = useState(false)
  const [analysisState, setAnalysisState] = useState<HeadlineAnalysisState>()
  const [copiedIndex, setCopiedIndex] = useState<string | null>(null)
  const { getAccessTokenSilently } = useAuth0()
  const { userData } = useUser()
  const posthog = usePostHog()

  const getHeadlineInformation = async () => {
    if (!headlineTitle) return

    posthog.capture("market_headline_ai_analysis", {
      headline_time: headlineTime,
      headline_title: headlineTitle,
      user: userData?.name,
    })

    setAnalysisState({ isLoading: true })
    setExpanded(true)

    try {
      const encodedHeadline = encodeURIComponent(headlineTitle)
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/mrkt-ai/headline?headline=${encodedHeadline}`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        },
      )

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}))
        const errorMessage = "Unable to generate insights at the moment"

        // If we got a parsing error, we can show the raw content to help with debugging
        if (errorData.rawContent) {
          console.error(
            "AI Response parsing error. Raw content:",
            errorData.rawContent,
          )
        }

        setAnalysisState({
          isLoading: false,
          error: errorMessage,
        })

        toast({
          title: "Try Again Later",
          description:
            "MRKT AI is taking a quick break. Please try again in a few moments.",
          variant: "destructive",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })
        return
      }

      const headlineResponse = await response.json()
      setAnalysisState({ isLoading: false, data: headlineResponse.analysis })
    } catch (error) {
      console.error("Failed to analyze headline:", error)

      const errorMessage =
        error instanceof Error
          ? "We're having trouble connecting right now"
          : "Having trouble connecting to MRKT AI"

      setAnalysisState({
        isLoading: false,
        error: errorMessage,
      })

      toast({
        title: "Connection Issue",
        description: "Please check your connection and try again",
        variant: "destructive",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    }
  }

  const escapeHtml = (str: string) => {
    if (!str) return ""

    // First decode any existing HTML entities
    const decoded = str
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&")
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")

    // Then do a fresh encode of everything
    return decoded
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
  }

  const copyHeadlineAsImage = async () => {
    setCopiedIndex("copy")

    const wrapper = document.createElement("div")

    try {
      wrapper.style.cssText = `
        position: fixed;
        left: -9999px;
        top: -9999px;
        background: black;
        width: 0;
        height: 0;
        overflow: hidden;
      `

      const container = document.createElement("div")
      container.style.cssText = `
        padding: 20px;
        background-color: black;
        border-radius: 12px;
        width: 600px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        position: absolute;
        left: 0;
        top: 0;
      `

      // Sanitize content before setting innerHTML
      const sanitizedRelativeTime = escapeHtml(relativeTime)
      const sanitizedHeadline = escapeHtml(headlineTitle)

      container.innerHTML = `
        <div style="padding: 20px; background-color: black;">
          <div style="
            color: rgb(156, 163, 175);
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: 500;
            letter-spacing: 0.3px;
          ">
            ${sanitizedRelativeTime}
          </div>
          <div style="
            color: white;
            font-size: 22px;
            line-height: 1.3;
            font-weight: 500;
            letter-spacing: -0.01em;
            margin-bottom: 16px;
          ">
            ${sanitizedHeadline}
          </div>
          <div style="
            color: rgb(156, 163, 175);
            font-size: 20px;
            display: flex;
            align-items: center;
            gap: 6px;
            padding-top: 12px;
            border-top: 1px solid #333;
          ">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 5v4"/><rect width="4" height="6" x="7" y="9" rx="1"/><path d="M9 15v2"/><path d="M17 3v2"/><rect width="4" height="8" x="15" y="5" rx="1"/><path d="M17 13v3"/><path d="M3 3v16a2 2 0 0 0 2 2h16"/></svg>
            Powered by <span style="color: white; font-weight: 600; margin-left: 4px;">MRKT</span>
          </div>
        </div>
      `

      wrapper.appendChild(container)
      document.body.appendChild(wrapper)

      // Increase delay to ensure proper rendering
      await new Promise((resolve) => setTimeout(resolve, 200))

      const dataUrl = await toPng(container, {
        quality: 0.95,
        backgroundColor: "black",
        skipAutoScale: true,
        cacheBust: true,
      })

      try {
        const response = await fetch(dataUrl)
        const blob = await response.blob()

        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob,
          }),
        ])

        toast({
          title: "Copied!",
          description: "Headline copied to clipboard as image",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })

        posthog.capture("headline_copied", {
          user: userData?.email,
          type: "text",
        })
      } catch (clipboardError) {
        const link = document.createElement("a")
        link.href = dataUrl
        link.download = "headline.png"
        link.click()

        toast({
          title: "Downloaded",
          description: "Headline saved as image (clipboard access denied)",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })
      }
    } catch (error) {
      console.error("Failed to process headline image:", error)
      toast({
        title: "Error",
        description: "Failed to copy headline as image",
        variant: "destructive",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    } finally {
      document.body.removeChild(wrapper)
      setTimeout(() => setCopiedIndex(null), 2000)
    }
  }

  const copyHeadlineWithAnalysis = async (
    headline: string,
    time: string,
    analysis: HeadlineAnalysis,
  ) => {
    setCopiedIndex("copy-analysis")

    if (!analysis || !headline || !time) {
      toast({
        title: "Error",
        description: "Missing required data for copying",
        variant: "destructive",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
      return
    }

    const wrapper = document.createElement("div")

    try {
      wrapper.style.cssText = `
        position: fixed;
        left: -9999px;
        top: -9999px;
        background: black;
        width: 0;
        height: 0;
        overflow: hidden;
      `

      const container = document.createElement("div")
      container.style.cssText = `
        padding: 20px;
        background-color: black;
        border-radius: 12px;
        width: 1000px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        position: absolute;
        left: 0;
        top: 0;
      `

      // Sanitize and prepare the content before setting innerHTML
      const sanitizedTime = escapeHtml(time)
      const sanitizedHeadline = escapeHtml(headline)
      const sanitizedSummary = escapeHtml(analysis.summary || "")

      const assetsHtml = (analysis.assets || [])
        .filter((asset) => asset && asset.asset && asset.impact)
        .map(
          (asset) => `
          <div style="
            display: inline-flex;
            align-items: center;
            gap: 6px;
            padding: 8px 14px;
            background-color: ${
              asset.impact === "bullish"
                ? "rgba(22, 163, 74, 0.15)"
                : asset.impact === "bearish"
                  ? "rgba(220, 38, 38, 0.15)"
                  : "rgba(75, 85, 99, 0.15)"
            };
            border: 1px solid ${
              asset.impact === "bullish"
                ? "rgba(22, 163, 74, 0.3)"
                : asset.impact === "bearish"
                  ? "rgba(220, 38, 38, 0.3)"
                  : "rgba(75, 85, 99, 0.3)"
            };
            border-radius: 9999px;
            color: ${
              asset.impact === "bullish"
                ? "rgb(34, 197, 94)"
                : asset.impact === "bearish"
                  ? "rgb(239, 68, 68)"
                  : "rgb(156, 163, 175)"
            };
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.3px;
          ">
            ${
              asset.impact === "bullish"
                ? '<svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m18 15-6-6-6 6"/></svg>'
                : asset.impact === "bearish"
                  ? '<svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>'
                  : ""
            }
            ${escapeHtml(asset.asset)}
          </div>
        `,
        )
        .join("")

      container.innerHTML = `
        <div style="padding: 20px; background-color: black;">
          <div style="color: rgb(156, 163, 175); font-size: 20px; margin-bottom: 10px; font-weight: 500; letter-spacing: 0.3px;">
            ${sanitizedTime}
          </div>
          <div style="color: white; font-size: 22px; line-height: 1.3; font-weight: 500; letter-spacing: -0.01em; margin-bottom: 16px;">
            ${sanitizedHeadline}
          </div>
          <div style="color: white; font-size: 18px; line-height: 1.6; margin-top: 16px; padding: 20px; background: rgb(17, 24, 39); border-radius: 12px; border: 1px solid rgb(31, 41, 55);">
            <div style="font-size: 20px; font-weight: 600; margin-bottom: 12px; color: white;">
              MRKT AI Analysis
            </div>
            <div style="color: rgb(209, 213, 219);">
              ${sanitizedSummary}
            </div>
            <div style="display: flex; gap: 8px; margin-top: 16px; flex-wrap: wrap;">
              ${assetsHtml}
            </div>
          </div>
          <div style="color: rgb(156, 163, 175); font-size: 20px; display: flex; align-items: center; gap: 6px; padding-top: 12px; border-top: 1px solid #333; margin-top: 16px;">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 5v4"/><rect width="4" height="6" x="7" y="9" rx="1"/><path d="M9 15v2"/><path d="M17 3v2"/><rect width="4" height="8" x="15" y="5" rx="1"/><path d="M17 13v3"/><path d="M3 3v16a2 2 0 0 0 2 2h16"/></svg>
            Powered by <span style="color: white; font-weight: 600; margin-left: 4px;">MRKT</span>
          </div>
        </div>
      `

      wrapper.appendChild(container)
      document.body.appendChild(wrapper)

      // Increase delay to ensure proper rendering
      await new Promise((resolve) => setTimeout(resolve, 200))

      const dataUrl = await toPng(container, {
        quality: 0.95,
        backgroundColor: "black",
        skipAutoScale: true,
        cacheBust: true, // Add cache busting
      })

      try {
        const response = await fetch(dataUrl)
        const blob = await response.blob()

        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob,
          }),
        ])

        toast({
          title: "Copied!",
          description: "Headline with AI analysis copied to clipboard as image",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })
      } catch (clipboardError) {
        const link = document.createElement("a")
        link.href = dataUrl
        link.download = "headline-with-analysis.png"
        link.click()

        toast({
          title: "Downloaded",
          description:
            "Headline with AI analysis saved as image (clipboard access denied)",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })

        posthog.capture("headline_copied", {
          user: userData?.email,
          type: "mrkt_analysis",
        })
      }
    } catch (error) {
      console.error("Failed to process headline image:", error)
      toast({
        title: "Error",
        description: "Failed to copy headline as image. Please try later.",
        variant: "destructive",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    } finally {
      if (wrapper.parentNode) {
        document.body.removeChild(wrapper)
      }
      setTimeout(() => setCopiedIndex(null), 2000)
    }
  }

  const highlightKeywords = (
    text: string,
    keywords: string[],
    highlightEnabled: boolean,
  ) => {
    if (!highlightEnabled) return text

    const words = text.split(/\s+/)
    return words.map((word, index) => {
      const cleanWord = word
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
        .toUpperCase()
      const matchedKeyword = keywords.find(
        (keyword) =>
          keyword === cleanWord ||
          (keyword.length > 2 && cleanWord.includes(keyword)),
      )
      if (matchedKeyword) {
        return (
          <span key={index} className="font-extrabold text-purple-400">
            {word}{" "}
          </span>
        )
      }
      return word + " "
    })
  }

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1.5">
          <span className="text-md font-medium text-gray-400">
            {(() => {
              try {
                const date = new Date(headlineTime)
                if (!(date instanceof Date) || isNaN(date.getTime())) {
                  return headlineTime // Return the raw string if date is invalid
                }
                return format(date, "MMM d, yyyy h:mm a")
              } catch (error) {
                console.error("Error formatting headline time:", error)
                return headlineTime // Fallback to raw string
              }
            })()}
            <span className="text-gray-600 mx-1">-</span>
            <span className="text-gray-500">{relativeTime}</span>
          </span>
        </div>
        <div className="flex items-center gap-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          <button
            onClick={getHeadlineInformation}
            className="p-1.5 rounded-md hover:bg-gray-700 transition-colors duration-200"
          >
            <Brain className="w-5 h-5 text-purple-400" />
          </button>

          <button
            onClick={copyHeadlineAsImage}
            className="p-1.5 rounded-md hover:bg-gray-700 transition-colors duration-200"
          >
            {copiedIndex === "copy" ? (
              <Check className="w-5 h-5 text-green-400" />
            ) : (
              <Copy className="w-5 h-5 text-purple-400" />
            )}
          </button>

          {analysisState?.data && (
            <button
              onClick={() =>
                copyHeadlineWithAnalysis(
                  headlineTitle,
                  relativeTime,
                  analysisState.data!,
                )
              }
              className="p-1.5 rounded-md hover:bg-gray-700 transition-colors duration-200 flex items-center gap-1"
            >
              {copiedIndex === "copy-analysis" ? (
                <Check className="w-5 h-5 text-green-400" />
              ) : (
                <>
                  <Copy className="w-5 h-5 text-purple-400" />
                  <span className="text-xs text-gray-400">With AI</span>
                </>
              )}
            </button>
          )}
        </div>
      </div>
      <p className="text-[15px] leading-relaxed text-gray-100 font-normal">
        {highlightKeywords(headlineTitle, headlinesKeywords, highlightEnabled)}
      </p>

      {expanded && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="mt-2 overflow-hidden"
        >
          <AnalysisDialogContent
            headlineTitle={headlineTitle}
            headlineTime={headlineTime}
            headlineAnalysisData={analysisState?.data}
            isLoading={analysisState?.isLoading ?? false}
            onClose={() => setExpanded(false)}
          />
        </motion.div>
      )}
    </div>
  )
}
