import {
  createFileRoute,
  Outlet,
  useRouteContext,
  useNavigate,
} from "@tanstack/react-router"
import { Navbar } from "@/components"
import { useUser } from "@/providers/UserProvider"
import { LoadingScreen } from "@/providers"
import { ActiveStocks } from "@/presentation/screens/equities/components/ActiveStocks/ActiveStocks"
import { EquitiesNews } from "@/presentation/screens/equities/components/EquitiesNews/EquitiesNews"
import { HomescreenHeader } from "@/presentation/screens/homescreen/homescreen"
import { useState, useEffect, createContext, useContext } from "react"
import { MultiSymbolChart } from "@/presentation/components/Charts/MultiSymbolChart"
import { SearchTicker } from "@/presentation/screens/equities/components/SearchTicker/SearchTicker"
import { EarningsCalendar } from "@/presentation/screens/equities/components/EarningsCalendar/EarningsCalendar"
import { Watchlist } from "@/presentation/screens/equities/components/Watchlist/Watchlist"
import { Brain, Plus, SquarePen } from "lucide-react"
import { AnimatePresence, motion } from "framer-motion"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import { format } from "date-fns"
import { EquitiesDailyRecap } from "@/presentation/screens/equities/components/EquitiesDailyRecap/EquitiesDailyRecap"
import { FearAndGreedIndex } from "@/presentation/screens/homescreen/components/FearAndGreedIndex/FearAndGreedIndex"
import { SafeHavenDemandChart } from "@/presentation/components/Charts/SafeHavenDemandChart"
import { PutCallRatioChart } from "@/presentation/components/Charts/PutCallRatioChart"
import { YieldCurveChart } from "@/presentation/components/Charts/YieldCurveChart"
import posthog from "posthog-js"
import { UserReport } from "@/presentation/components/UserReport/UserReport"
import { useAuth0 } from "@auth0/auth0-react"
import { useUpdateUser } from "@/hooks/useUpdateUser"
import { AssetSelector } from "@/presentation/components/AssetSelector/AssetSelector"
import { AssetManager } from "@/presentation/components/AssetManager/AssetManager"
import { AIAnalysisPanel } from "@/presentation/screens/homescreen/components/MarketHeadlines/AIAnalysisPanel"

export const Route = createFileRoute("/equities")({
  validateSearch: (search: Record<string, unknown>) => {
    const plan = search.plan as string | undefined
    if (plan && !["monthly", "annual"].includes(plan)) {
      return { plan: undefined }
    }
    return { plan: plan as "monthly" | "annual" | undefined }
  },
  component: () => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
    const { plan } = Route.useSearch()
    const { isSubscribed, isLoading: isUserLoading, userData } = useUser()
    const navigate = useNavigate()

    useEffect(() => {
      console.log("[Equities Route] State:", {
        isLoading,
        isAuthenticated,
        isUserLoading,
        isSubscribed,
        plan,
        hasOnboarded: userData?.hasOnboarded,
      })

      if (!isLoading && !isUserLoading) {
        if (!isAuthenticated) {
          console.log(
            "[Equities Route] User not authenticated, redirecting to login",
          )
          loginWithRedirect({
            appState: {
              returnTo: "/auth",
              plan,
            },
          })
          return
        }

        if (!isSubscribed) {
          console.log(
            "[Equities Route] User not subscribed, redirecting to checkout",
          )
          navigate({ to: "/checkout" })
          return
        }

        if (!userData?.hasOnboarded) {
          console.log(
            "[Equities Route] User not onboarded, redirecting to onboarding",
          )
          navigate({ to: "/onboarding" })
          return
        }
      }
    }, [
      isLoading,
      isAuthenticated,
      isUserLoading,
      isSubscribed,
      userData?.hasOnboarded,
      plan,
      navigate,
      loginWithRedirect,
    ])

    // Show LoadingScreen while checking auth status
    if (isLoading || isUserLoading) {
      return <LoadingScreen />
    }

    return <EquitiesLayout />
  },
})

// Create context
const AssetManagerContext = createContext<{
  setIsAssetManagerOpen: (open: boolean) => void
}>({ setIsAssetManagerOpen: () => {} })

function EquitiesLayout() {
  const { isLoading, userData } = useUser()
  const [open, setOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date>()
  const [showDailyRecap, setShowDailyRecap] = useState(false)
  const [isUserReportOpen, setIsUserReportOpen] = useState(false)
  const [isAssetManagerOpen, setIsAssetManagerOpen] = useState(false)
  const { user } = useAuth0()
  const { updateUser } = useUpdateUser()
  const navigate = useNavigate()

  // Add this effect to check if we should show the report on first visit
  useEffect(() => {
    const handleFirstTimeReport = async () => {
      if (!userData?.showFirstTimeReport || !user?.email) return

      setIsUserReportOpen(true)

      try {
        await updateUser({
          email: user.email,
          updates: {
            showFirstTimeReport: false,
          },
        })
      } catch (error) {
        console.error("Failed to update first time report status:", error)
      }
    }

    handleFirstTimeReport()
  }, [userData?.showFirstTimeReport, user?.email, updateUser])

  const handleShowUserReport = () => {
    setIsUserReportOpen(true)
    posthog.capture("user_report_opened", {
      user: user?.email,
      source: "header_button",
      dashboard: "equities",
    })
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <AssetManagerContext.Provider value={{ setIsAssetManagerOpen }}>
      <div className="bg-black min-h-screen">
        <Navbar />
        <div className="px-12 sm:px-6 lg:px-12 mx-auto mt-2 flex flex-col gap-4">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <HomescreenHeader name={userData?.name as string} />
            <div className="flex flex-wrap items-center gap-2 w-full sm:w-auto">
              <UserReport
                isOpen={isUserReportOpen}
                onClose={() => setIsUserReportOpen(false)}
                symbols={userData?.tradingInfo?.specificAssets?.equities}
              />
              <button
                onClick={handleShowUserReport}
                className="inline-flex items-center gap-2 px-3 py-2 text-white bg-gradient-to-r from-purple-500/10 to-blue-500/10 border border-purple-500/30 text-xs sm:text-sm rounded-md hover:from-purple-500/20 hover:to-blue-500/20 transition-colors whitespace-nowrap"
              >
                <Brain className="w-3 h-3 sm:w-4 sm:h-4 text-purple-400" />
                <span className="text-xs sm:text-sm font-medium text-purple-300">
                  Your Report
                </span>
              </button>

              <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                  <button className="inline-flex items-center gap-2 px-3 py-2 text-white bg-gradient-to-r from-purple-500/10 to-blue-500/10 border border-purple-500/30 text-xs sm:text-sm rounded-md hover:from-purple-500/20 hover:to-blue-500/20 transition-colors whitespace-nowrap">
                    <Brain className="w-3 h-3 sm:w-4 sm:h-4 text-purple-400" />
                    <span className="text-xs sm:text-sm font-medium text-purple-300">
                      Equities Recap
                    </span>
                  </button>
                </PopoverTrigger>
                <PopoverContent
                  className="w-[300px] p-0 bg-black/95 border border-[#333] shadow-xl backdrop-blur-sm"
                  align="end"
                  side="bottom"
                  sideOffset={5}
                  alignOffset={0}
                >
                  <div className="flex flex-col">
                    <div className="p-4 border-b border-zinc-800/50">
                      <span className="text-zinc-400 text-sm">
                        {selectedDate
                          ? format(selectedDate, "MMMM d, yyyy")
                          : "Select a date"}
                      </span>
                    </div>

                    <Calendar
                      mode="single"
                      selected={selectedDate}
                      onSelect={setSelectedDate}
                      className="p-0 mx-auto"
                      disabled={(date) => {
                        // Disable weekends (Saturday = 6, Sunday = 0)
                        const day = date.getDay()
                        return day === 0 || day === 6 || date > new Date()
                      }}
                      classNames={{
                        months: "flex flex-col space-y-4",
                        month: "space-y-4",
                        caption:
                          "flex justify-center pt-1 relative items-center text-zinc-400",
                        caption_label: "text-sm font-medium",
                        nav: "space-x-1 flex items-center",
                        nav_button:
                          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 hover:bg-zinc-800/50 rounded-md text-zinc-400",
                        nav_button_previous: "absolute left-1",
                        nav_button_next: "absolute right-1",
                        table: "w-full border-collapse space-y-1",
                        head_row: "flex",
                        head_cell:
                          "text-zinc-400 rounded-md w-9 font-normal text-[0.8rem]",
                        row: "flex w-full mt-2 justify-center",
                        cell: "text-center text-sm p-0 relative [&:has([aria-selected])]:bg-zinc-800/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                        day: "h-9 w-9 p-0 font-normal aria-selected:opacity-100 text-zinc-400 hover:bg-zinc-800/50 rounded-md transition-colors",
                        day_selected:
                          "bg-purple-400/30 text-white hover:bg-purple-400 hover:text-white focus:bg-purple-400 focus:text-white",
                        day_today: "bg-zinc-800/50 text-white",
                        day_outside: "opacity-50",
                        day_disabled: "opacity-30 cursor-not-allowed",
                        day_range_middle:
                          "aria-selected:bg-zinc-800/50 aria-selected:text-zinc-400",
                        day_hidden: "invisible",
                      }}
                    />
                    <div className="p-4 border-t border-zinc-800/50 space-y-2">
                      <Button
                        onClick={() => {
                          setShowDailyRecap(true)
                          setOpen(false)
                          posthog.capture("equities_market_recap_clicked", {
                            user: userData?.email,
                          })
                        }}
                        disabled={!selectedDate}
                        className="w-full hover:from-purple-500/20 hover:to-blue-500/20 bg-gradient-to-r from-purple-500/10 to-blue-500/10 text-purple-300 border border-purple-500/30 transition-colors duration-200"
                      >
                        <Brain className="w-4 h-4 mr-2" />
                        View Market Recap
                      </Button>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
              <SearchTicker />
              <button
                onClick={() => setIsAssetManagerOpen(true)}
                className="inline-flex items-center justify-center w-9 h-9 text-white bg-[#111] border 
                border-[#333] text-xs sm:text-sm rounded-md hover:bg-[#333] transition-colors"
              >
                <SquarePen className="h-3 w-3 sm:h-4 sm:w-4" />
              </button>
            </div>
          </div>
        </div>
        <Outlet />
        <AnimatePresence>
          {showDailyRecap && (
            <EquitiesDailyRecap
              selectedDate={selectedDate}
              onClose={() => setShowDailyRecap(false)}
            />
          )}
        </AnimatePresence>
        <AssetManager
          isOpen={isAssetManagerOpen}
          onClose={() => setIsAssetManagerOpen(false)}
          assetType="equities"
        />
      </div>
    </AssetManagerContext.Provider>
  )
}

// Move the dashboard component to a separate file
export function EquitiesDashboard() {
  const { isLoading, userData } = useUser()
  const [activeTab, setActiveTab] = useState<
    "overview" | "earnings" | "personal"
  >("personal")
  const [isAssetSelectorOpen, setIsAssetSelectorOpen] = useState(false)
  const [editingAssetIndex, setEditingAssetIndex] = useState<
    number | undefined
  >(undefined)
  const [showAIAnalysis, setShowAIAnalysis] = useState(false)
  const [aiAnalysisData, setAIAnalysisData] = useState<any>(null)
  const userSymbols = userData?.tradingInfo?.specificAssets?.equities ?? []
  const mappedUserSymbols = userSymbols.map((symbol: string) => {
    return {
      [symbol]: symbol,
    }
  })
  const { setIsAssetManagerOpen } = useContext(AssetManagerContext)

  const handleShowAnalysis = (data: any) => {
    setAIAnalysisData(data)
    setShowAIAnalysis(true)
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <div className="bg-black min-h-screen">
      <div className="px-12 sm:px-6 lg:px-12 mx-auto mt-2">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-4">
          <div className="flex flex-nowrap overflow-x-auto w-full sm:w-auto space-x-2 sm:space-x-4 pb-2 sm:pb-0">
            <button
              onClick={() => setActiveTab("personal")}
              className={`px-3 py-2 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium rounded-lg transition-colors duration-200 whitespace-nowrap
                ${activeTab === "personal" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
            >
              Personal
            </button>
            <button
              onClick={() => setActiveTab("overview")}
              className={`px-3 py-2 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium rounded-lg transition-colors duration-200 whitespace-nowrap
                ${activeTab === "overview" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab("earnings")}
              className={`px-3 py-2 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium rounded-lg transition-colors duration-200 whitespace-nowrap
                ${activeTab === "earnings" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
            >
              Earnings Calendar
            </button>
          </div>
        </div>

        {activeTab === "overview" && (
          <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-6 gap-4 mt-2">
            <div
              className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 h-[400px] 
              shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
              before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
              after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
              after:pointer-events-none 
              relative 
              bg-gradient-to-b from-black/50 to-black/30
              hover:border-white/20 transition-colors duration-200"
            >
              <MultiSymbolChart
                symbols={["ESUSD", "NQUSD", "YMUSD", "RTYUSD"]}
                symbolLabels={{
                  ESUSD: "S&P 500",
                  NQUSD: "Nasdaq",
                  YMUSD: "Dow Jones",
                  RTYUSD: "Russell 2000",
                }}
                title="US Indices"
              />
            </div>

            <div
              className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 h-[400px] 
              shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
              before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
              after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
              after:pointer-events-none 
              relative 
              bg-gradient-to-b from-black/50 to-black/30
              hover:border-white/20 transition-colors duration-200"
            >
              <MultiSymbolChart
                symbols={["SPY", "EWU", "EWG", "EWJ", "EWH", "EZU"]}
                symbolLabels={{
                  SPY: "S&P 500",
                  EWU: "FTSE 100",
                  EWG: "DAX",
                  EWJ: "Nikkei 225",
                  EWH: "Hang Seng",
                  EZU: "Euro Stoxx",
                }}
                title="Global Indices"
              />
            </div>

            <div
              className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 h-[400px] 
              shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
              before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
              after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
              after:pointer-events-none 
              relative 
              bg-gradient-to-b from-black/50 to-black/30
              hover:border-white/20 transition-colors duration-200"
            >
              <MultiSymbolChart
                symbols={["^VIX"]}
                symbolLabels={{
                  "^VIX": "VIX",
                }}
                title="Volatility Index"
                defaultTimeRange="1Y"
              />
            </div>

            <div
              className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
              shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
              before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
              after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
              after:pointer-events-none 
              relative 
              bg-gradient-to-b from-black/50 to-black/30
              hover:border-white/20 transition-colors duration-200"
            >
              <YieldCurveChart />
            </div>

            <div
              className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
              shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
              before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
              after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
              after:pointer-events-none 
              relative 
              bg-gradient-to-b from-black/50 to-black/30
              hover:border-white/20 transition-colors duration-200"
            >
              <SafeHavenDemandChart />
            </div>

            <div
              className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[400px] 
              shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
              before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
              after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
              after:pointer-events-none 
              relative 
              bg-gradient-to-b from-black/50 to-black/30
              hover:border-white/20 transition-colors duration-200"
            >
              <PutCallRatioChart />
            </div>
            <div
              className="backdrop-blur-md bg-black/40 rounded-xl lg:col-span-2 border border-white/10 p-4 h-[600px] 
              shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
              before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
              after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
              after:pointer-events-none 
              relative 
              bg-gradient-to-b from-black/50 to-black/30
              hover:border-white/20 transition-colors duration-200"
            >
              <FearAndGreedIndex />
            </div>
            <div
              className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[600px] 
              shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
              before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
              after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
              after:pointer-events-none 
              relative 
              bg-gradient-to-b from-black/50 to-black/30
              hover:border-white/20 transition-colors duration-200"
            >
              <div className="w-full h-full flex flex-col">
                <Watchlist />
              </div>
            </div>
            <div
              className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[600px] 
              shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
              before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
              after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
              after:pointer-events-none 
              relative 
              bg-gradient-to-b from-black/50 to-black/30
              hover:border-white/20 transition-colors duration-200"
            >
              <div className="w-full h-full flex flex-col">
                <ActiveStocks />
              </div>
            </div>
            <div
              className="backdrop-blur-md bg-black/40 rounded-xl lg:col-span-2 border border-white/10 p-4 h-[600px] 
              shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
              before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
              after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
              after:pointer-events-none 
              relative 
              bg-gradient-to-b from-black/50 to-black/30
              hover:border-white/20 transition-colors duration-200"
            >
              <EquitiesNews />
            </div>
          </div>
        )}

        {activeTab === "earnings" && (
          <div
            className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[calc(100vh-200px)] 
            shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
            before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
            after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
            after:pointer-events-none 
            relative 
            bg-gradient-to-b from-black/50 to-black/30
            hover:border-white/20 transition-colors duration-200"
          >
            <EarningsCalendar />
          </div>
        )}

        {activeTab === "personal" && (
          <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-6 gap-4 mt-2">
            {userSymbols
              .filter(Boolean)
              .map((symbol: string, index: number) => (
                <div
                  key={symbol}
                  className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 h-[400px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
                >
                  <MultiSymbolChart
                    symbols={[symbol]}
                    symbolLabels={{ [symbol]: symbol }}
                    height={400}
                    title={symbol}
                    showSentiment={true}
                    sentimentSymbols={mappedUserSymbols}
                    showMrktAI={true}
                    onShowAnalysis={handleShowAnalysis}
                  />
                </div>
              ))}

            {(!userData?.tradingInfo?.specificAssets?.equities ||
              userData.tradingInfo.specificAssets.equities.filter(Boolean)
                .length === 0) && (
              <div className="col-span-full flex flex-col items-center justify-center py-16 text-center">
                <div className="p-6 rounded-full bg-white/5 mb-4">
                  <Plus className="w-8 h-8 text-white/50" />
                </div>
                <h3 className="text-xl font-medium text-white/80 mb-2">
                  No Assets Added
                </h3>
                <p className="text-white/50 max-w-md mb-6">
                  Add assets to your personal dashboard and market report
                </p>
                <Button
                  onClick={() => setIsAssetManagerOpen(true)}
                  className="bg-gradient-to-r from-purple-500/20 to-blue-500/20 hover:from-purple-500/30 hover:to-blue-500/30 text-white border border-purple-500/30"
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Assets
                </Button>
              </div>
            )}

            {userData?.tradingInfo?.specificAssets?.equities?.filter(Boolean)
              .length > 0 && (
              <>
                <div
                  className="backdrop-blur-md bg-black/40 rounded-xl lg:col-span-2 border border-white/10 p-4 h-[600px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
                >
                  <FearAndGreedIndex />
                </div>
                <div
                  className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[600px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
                >
                  <div className="w-full h-full flex flex-col">
                    <Watchlist />
                  </div>
                </div>
                <div
                  className="backdrop-blur-md bg-black/40 rounded-xl border border-white/10 p-4 h-[600px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
                >
                  <div className="w-full h-full flex flex-col">
                    <ActiveStocks />
                  </div>
                </div>
                <div
                  className="backdrop-blur-md bg-black/40 rounded-xl lg:col-span-2 border border-white/10 p-4 h-[600px] 
                  shadow-[0_8px_32px_rgba(0,0,0,0.5)] 
                  before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-white/[0.03] before:to-transparent before:pointer-events-none
                  after:absolute after:inset-0 after:rounded-xl after:shadow-[inset_0_1px_1px_rgba(255,255,255,0.08)] 
                  after:pointer-events-none 
                  relative 
                  bg-gradient-to-b from-black/50 to-black/30
                  hover:border-white/20 transition-colors duration-200"
                >
                  <EquitiesNews />
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/* Add the AssetSelector component */}
      <AssetSelector
        isOpen={isAssetSelectorOpen}
        onClose={() => {
          setIsAssetSelectorOpen(false)
          setEditingAssetIndex(undefined)
        }}
        assetType="equities"
        currentIndex={editingAssetIndex}
      />

      {/* Add AI Analysis Panel */}
      <AnimatePresence>
        {showAIAnalysis && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="fixed inset-0 bg-black/10 z-50"
          >
            <AIAnalysisPanel
              visible={showAIAnalysis}
              onClose={() => setShowAIAnalysis(false)}
              symbol={aiAnalysisData?.symbol}
              analysis={aiAnalysisData?.analysis}
              oneHourSentiment={aiAnalysisData?.oneHourSentiment}
              dailySentiment={aiAnalysisData?.dailySentiment}
              weeklySentiment={aiAnalysisData?.weeklySentiment}
              oneHourReasoning={aiAnalysisData?.oneHourReasoning}
              dailyReasoning={aiAnalysisData?.dailyReasoning}
              weeklyReasoning={aiAnalysisData?.weeklyReasoning}
              generatedAt={aiAnalysisData?.generatedAt}
              intraday_levels={aiAnalysisData?.intraday_levels}
              querySymbol={aiAnalysisData?.querySymbol}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
