import { InformationCircleIcon } from "@heroicons/react/24/outline"

interface SentimentBadgeProps {
  rating: string
  score: number
  description?: string
  onInfoClick?: () => void
}

const getRatingColor = (rating: string) => {
  const colors: { [key: string]: string } = {
    "extreme fear": "text-red-500 bg-red-500/10 border-red-500/20",
    fear: "text-orange-500 bg-orange-500/10 border-orange-500/20",
    neutral: "text-yellow-500 bg-yellow-500/10 border-yellow-500/20",
    greed: "text-green-500 bg-green-500/10 border-green-500/20",
    "extreme greed": "text-emerald-500 bg-emerald-500/10 border-emerald-500/20",
  }
  return (
    colors[rating.toLowerCase()] || "text-white bg-white/10 border-white/20"
  )
}

export const SentimentBadge = ({
  rating,
  score,
  description,
  onInfoClick,
}: SentimentBadgeProps) => {
  return (
    <div className="flex items-center gap-2">
      <div
        className={`
          px-2.5 py-0.5 
          border rounded-md
          font-medium text-xs tracking-widest
          transition-all duration-150 ease-in-out
          cursor-pointer hover:bg-opacity-20
          ${getRatingColor(rating)}
        `}
        title={`Current Value: ${score.toFixed(2)}`}
      >
        {rating.toUpperCase()}
      </div>
      {description && (
        <button
          onClick={onInfoClick}
          className="group relative focus:outline-none"
        >
          <InformationCircleIcon className="w-4 h-4 text-white/40 hover:text-white/60 transition-colors cursor-pointer" />
        </button>
      )}
    </div>
  )
}
