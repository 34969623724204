import { CardContent, CardTitle } from "@/components/ui/card"
import { TooltipIcon } from "@/components/ui/tooltip_icon"
import { Card, CardHeader } from "@nextui-org/card"
import {
  addDays,
  startOfWeek,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  endOfWeek,
} from "date-fns"
import { Fragment, useEffect, useMemo, useState, useRef } from "react"
import { DateRange } from "react-day-picker"
import Flag from "react-flagkit"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import {
  CountriesMultiSelectFilter,
  DatePickerFilterPill,
} from "../../../../../components/Filters"
import {
  useEconomicCalendar,
  getLocalMidnight,
  getLocalEndOfDay,
  adjustDateForTimezone,
} from "../../../../../providers/EconomicCalendarProvider"
import { getDayDateFormat } from "../../utilities"
import { HeadlineBadge } from "../MarketHeadlines"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { AnimatePresence, motion } from "framer-motion"
import { EventAnalysisPanel } from "./EventAnalysisPanel"
import { Button } from "@/components/ui/button"
import { useToast, useUpdateUser } from "@/hooks"
import { useAuth0 } from "@auth0/auth0-react"
import clsx from "clsx"
import { usePostHog } from "posthog-js/react"
import { useUser } from "@/providers"
import { Resizable } from "re-resizable"
import { Brain, Zap } from "lucide-react"
import { EventSearch } from "./EventSearch"
import { cn } from "@/lib/utils"
import posthog from "posthog-js"

export const countryOptions = [
  { label: "United States", value: "US" },
  { label: "United Kingdom", value: "UK" },
  { label: "Euro Zone", value: "EMU" },
  { label: "France", value: "FR" },
  { label: "Japan", value: "JP" },
  { label: "India", value: "IN" },
  { label: "Canada", value: "CA" },
  { label: "Germany", value: "DE" },
  { label: "Italy", value: "IT" },
  { label: "Spain", value: "ES" },
  { label: "China", value: "CN" },
  { label: "Thailand", value: "TH" },
  { label: "Switzerland", value: "CH" },
  { label: "Australia", value: "AU" },
  { label: "Brazil", value: "BR" },
  { label: "Argentina", value: "AR" },
  { label: "Mexico", value: "MX" },
  { label: "Russia", value: "RU" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "South Africa", value: "ZA" },
  { label: "South Korea", value: "KR" },
  { label: "Turkey", value: "TR" },
  { label: "Indonesia", value: "ID" },
  { label: "Singapore", value: "SG" },
  { label: "Netherlands", value: "NL" },
  { label: "Belgium", value: "BE" },
  { label: "Austria", value: "AT" },
  { label: "Portugal", value: "PT" },
  { label: "Greece", value: "GR" },
  { label: "New Zealand", value: "NZ" },
]

export const impactOptions = [
  { label: "Low", value: "LOW" },
  { label: "Medium", value: "MEDIUM" },
  { label: "High", value: "HIGH" },
  { label: "None", value: "NONE" },
]

export const categoryOptions = [
  { label: "Central Bank", value: "c94405b5-5f85-4397-ab11-002a481c4b92" },
  { label: "Economic Activity", value: "24127f3b-edce-4dc4-afdf-0b3bd8a964be" },
  { label: "Inflation", value: "33303f5e-1e3c-4016-ab2d-ac87e98f57ca" },
  { label: "Labor Market", value: "91da97bd-d94a-4ce8-a02b-b96ee2944e4c" },
  { label: "Interest Rates", value: "9c4a731a-d993-4d55-89f3-dc707cc1d596" },
  { label: "Capital Flows", value: "fa6570f6-e494-4563-a363-00d0f2abec37" },
  { label: "Holidays", value: "7dfaef86-c3fe-4e76-9421-8958cc2f9a0d" },
  { label: "Consumption", value: "e229c890-80fc-40f3-b6f4-b658f3a02635" },
  { label: "Housing Market", value: "1e06a304-fac6-440c-9ced-9225a6277a55" },
  { label: "Bond Auctions", value: "8896aa26-a50c-4f8b-aa11-8b3fccda1dfd" },
  { label: "Energy", value: "dd332fd3-6996-41be-8c41-33f277074fa7" },
  { label: "Politics", value: "e9e957ec-2927-4a77-ae0c-f5e4b5807c16" },
]

const formatTime = (dateString: string) => {
  const date = new Date(dateString)
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
}

export const getCountryFlag = (countryCode: string) => {
  let isoCode = countryCode
  if (countryCode === "EMU") {
    isoCode = "EU"
  } else if (countryCode === "UK") {
    isoCode = "GB"
  }
  return <Flag country={isoCode} size={20} />
}

const Countdown = ({ releaseTime }: { releaseTime: string }) => {
  const [timeLeft, setTimeLeft] = useState("")

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date()
      const release = new Date(releaseTime)
      const diff = release.getTime() - now.getTime()

      if (diff <= 0) {
        clearInterval(timer)
        setTimeLeft("Releasing...")
      } else {
        const minutes = Math.floor(diff / 60000)
        const seconds = Math.floor((diff % 60000) / 1000)
        setTimeLeft(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`)
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [releaseTime])

  return <span className="text-red-500">{timeLeft}</span>
}

// Add this helper function at the top of your file
export const formatValue = (
  value: number | null,
  unit: string | null,
  potency: string | null,
): string => {
  // Early return if value is null or undefined
  if (value == null) return "-"

  let suffix = ""
  let formattedValue = value

  // Handle unit and potency
  if (unit) {
    suffix = unit
  } else if (potency) {
    switch (potency) {
      case "K":
        formattedValue = value >= 1000 ? value / 1000 : value
        suffix = "K"
        break
      case "M":
        formattedValue = value >= 1000000 ? value / 1000000 : value
        suffix = "M"
        break
      case "B":
        formattedValue = value >= 1000000000 ? value / 1000000000 : value
        suffix = "B"
        break
      case "T":
        formattedValue = value >= 1000000000000 ? value / 1000000000000 : value
        suffix = "T"
        break
      case "ZERO":
        // Remove the Math.round to preserve decimals for ZERO potency
        formattedValue = value
        break
      default:
        formattedValue = value
    }
  }

  try {
    // Always format to 1 decimal place if the number has decimals
    const numberStr = Number.isInteger(formattedValue)
      ? formattedValue.toString()
      : formattedValue.toFixed(2)

    return `${numberStr}${suffix}`
  } catch (error) {
    console.warn("Error formatting value:", { value, unit, potency, error })
    return "-"
  }
}

// Add this helper function near the other helper functions at the top
const formatDeviation = (deviation: number | null): string => {
  if (deviation === null || deviation === undefined) return "-"

  // Format to 2 decimal places
  const formattedValue = Math.abs(deviation).toFixed(2)

  // Add + or - prefix
  return deviation >= 0 ? `+${formattedValue}` : `-${formattedValue}`
}

// Add this helper function to determine the color class based on actual vs forecast
export const getValueWithIndicator = (
  actual: number | null,
  forecast: number | null,
  previous: number | null,
  formattedValue: string,
) => {
  if (actual === null) return { value: formattedValue, indicator: null }

  const compareValue = forecast !== null ? forecast : previous
  if (compareValue === null) return { value: formattedValue, indicator: null }

  const epsilon = 0.0001
  if (Math.abs(actual - compareValue) < epsilon) {
    return {
      value: formattedValue,
      indicator: null,
      color: "text-white",
    }
  }

  return {
    value: formattedValue,
    indicator: actual > compareValue ? "↑" : "↓",
    color: actual > compareValue ? "text-green-400" : "text-red-400",
  }
}

// Update the getActualValueTooltip function
const getActualValueTooltip = (
  actual: number | null,
  forecast: number | null,
  previous: number | null,
  unit: string | null,
  potency: string | null,
) => {
  if (actual === null) return null

  const compareValue = forecast !== null ? forecast : previous
  const comparisonType = forecast !== null ? "forecast" : "previous"

  if (compareValue === null) return null

  const epsilon = 0.0001
  if (Math.abs(actual - compareValue) < epsilon) {
    return `Actual reading is on ${comparisonType}`
  }

  const difference = Math.abs(actual - compareValue)
  const formattedDifference = formatValue(difference, unit, potency)

  return actual > compareValue
    ? `Actual reading is higher than ${comparisonType} by ${formattedDifference}`
    : `Actual reading is lower than ${comparisonType} by ${formattedDifference}`
}

export const EconomicCalendar = () => {
  // Context hooks first
  const {
    economicCalendarData,
    error,
    countryFilter,
    impactFilter,
    categoryFilter,
    setCountryFilter,
    setEndDateFilter,
    setStartDateFilter,
    setImpactFilter,
    setCategoryFilter,
    isLoading,
  } = useEconomicCalendar()
  const { updateUser } = useUpdateUser()
  const { user } = useAuth0()
  const { toast } = useToast()
  const posthog = usePostHog()
  const { userData } = useUser()

  // State hooks next
  const [selectedStartDate, setSelectedStartDate] = useState<Date | undefined>(
    () => startOfWeek(new Date()),
  )
  const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>(() =>
    endOfWeek(new Date()),
  )
  const [isAnalysisPanelOpen, setIsAnalysisPanelOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const [selectedPeriod, setSelectedPeriod] = useState<
    "today" | "tomorrow" | "week" | "month"
  >("today")

  // Memoized values next
  const groupedEvents = useMemo(() => {
    if (!Array.isArray(economicCalendarData)) {
      console.warn(
        "economicCalendarData is not an array:",
        economicCalendarData,
      )
      return {}
    }

    return economicCalendarData.reduce(
      (acc: Record<string, any[]>, event: any) => {
        if (!event?.dateUtc) {
          console.warn("Event missing dateUtc:", event)
          return acc
        }

        // Convert UTC to local date
        const localDate = new Date(event.dateUtc).toLocaleDateString("en-CA") // YYYY-MM-DD format

        if (!acc[localDate]) {
          acc[localDate] = []
        }
        acc[localDate].push(event)
        return acc
      },
      {},
    )
  }, [economicCalendarData])

  // Effects last
  useEffect(() => {
    // Get start and end of week in local time
    const localStartOfWeek = getLocalMidnight(startOfWeek(new Date()))
    const localEndOfWeek = getLocalEndOfDay(endOfWeek(new Date()))

    setStartDateFilter(localStartOfWeek)
    setEndDateFilter(localEndOfWeek)
  }, [])

  const todayRef = useRef<HTMLTableRowElement>(null)
  const simpleBarRef = useRef<any>(null)

  const resultArray = useMemo(
    () =>
      Object.keys(groupedEvents).map((date) => ({
        date,
        events: groupedEvents[date] || [],
      })),
    [groupedEvents],
  ) // Only recalculate when groupedEvents changes

  const countryFilterOnChange = (selectedOptions: string[]) => {
    setCountryFilter(selectedOptions)
  }

  const impactFilterOnChange = (selectedOptions: string[]) => {
    setImpactFilter(selectedOptions)
  }

  const categoryFilterOnChange = (selectedOptions: string[]) => {
    setCategoryFilter(selectedOptions)
  }

  const onDateFilterChange = (dateRange?: DateRange) => {
    if (dateRange?.to && dateRange?.from) {
      setSelectedStartDate(dateRange?.from)
      setSelectedEndDate(dateRange?.to)
      setStartDateFilter(dateRange?.from)
      setEndDateFilter(dateRange?.to)
    }
  }

  const onClearDateRange = () => {
    const startOfCurrentWeek = startOfWeek(new Date())
    setSelectedStartDate(startOfCurrentWeek)
    setSelectedEndDate(addDays(startOfCurrentWeek, 7))
    setStartDateFilter(startOfCurrentWeek)
    setEndDateFilter(addDays(startOfCurrentWeek, 7))
  }

  const isWithinOneHour = (dateString: string, actual: number | null) => {
    const now = new Date()
    const eventTime = new Date(dateString)
    const diffInMinutes = (eventTime.getTime() - now.getTime()) / 60000
    const hasPassedReleaseTime = diffInMinutes <= 0

    // If we have an actual value, it's no longer releasing
    if (actual !== null) {
      return false
    }

    // Show "Releasing..." for events that have just passed their release time (up to 5 minutes after)
    if (hasPassedReleaseTime && diffInMinutes > -5) {
      return true
    }

    // Show countdown for upcoming releases within the next hour
    return diffInMinutes > 0 && diffInMinutes <= 60
  }

  const handleCalendarEventAnalysis = (event: any) => {
    posthog.capture("economic_calendar_event_ai_analysis", {
      event_id: event.id,
      event_title: event.title,
      user: userData?.name,
    })
    setSelectedEvent(event)
    setIsAnalysisPanelOpen(true)
  }

  const saveUserFilters = async () => {
    if (!user?.email) {
      toast({
        title: "Error",
        description: "Failed to save filters.",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    } else {
      await updateUser({
        email: user?.email,
        updates: {
          economicCalendarFilters: {
            countries: countryFilter,
            impact: impactFilter,
            categories: categoryFilter,
          },
        },
      })
      toast({
        description: "Your economic calendar filters have been saved!",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
      posthog.capture("economic_calendar_save_filters", {
        country_filters: countryFilter,
        impact_filters: impactFilter,
        category_filters: categoryFilter,
        user: userData?.email,
      })
    }
  }

  const handlePeriodChange = (
    period: "today" | "tomorrow" | "week" | "month",
  ) => {
    posthog.capture("economic_calendar_period_change", {
      period,
      user: userData?.email,
    })

    const today = new Date()
    let start: Date
    let end: Date

    switch (period) {
      case "today":
        start = getLocalMidnight(today)
        end = getLocalEndOfDay(today)
        break
      case "tomorrow":
        start = getLocalMidnight(addDays(today, 1))
        end = getLocalEndOfDay(addDays(today, 1))
        break
      case "week":
        start = getLocalMidnight(startOfWeek(today))
        end = getLocalEndOfDay(endOfWeek(today))
        break
      case "month":
        start = getLocalMidnight(startOfMonth(today))
        end = getLocalEndOfDay(endOfMonth(today))
        break
      default:
        start = getLocalMidnight(today)
        end = getLocalEndOfDay(today)
    }

    setSelectedPeriod(period)
    setSelectedStartDate(start)
    setSelectedEndDate(end)
    setStartDateFilter(start)
    setEndDateFilter(end)
  }

  const handleCountryFilterChange = (selectedOptions: string[]) => {
    posthog.capture("economic_calendar_filter_change", {
      filter_type: "country",
      selected_options: selectedOptions,
      user: userData?.email,
    })
    countryFilterOnChange(selectedOptions)
  }

  const handleImpactFilterChange = (selectedOptions: string[]) => {
    posthog.capture("economic_calendar_filter_change", {
      filter_type: "impact",
      selected_options: selectedOptions,
      user: userData?.email,
    })
    impactFilterOnChange(selectedOptions)
  }

  const handleCategoryFilterChange = (selectedOptions: string[]) => {
    posthog.capture("economic_calendar_filter_change", {
      filter_type: "category",
      selected_options: selectedOptions,
      user: userData?.email,
    })
    categoryFilterOnChange(selectedOptions)
  }

  const handleDateFilterChange = (dateRange?: DateRange) => {
    if (!dateRange) return

    posthog.capture("economic_calendar_filter_change", {
      filter_type: "date",
      date_range: dateRange,
      user: userData?.email,
    })
    onDateFilterChange(dateRange)
  }

  if (isLoading) {
    return (
      <Card className="h-full w-full border-none">
        <CardHeader className="flex flex-row items-center justify-between">
          <div className="flex gap-1 relative z-[1]">
            <Button
              onClick={() => handlePeriodChange("today")}
              variant={selectedPeriod === "today" ? "default" : "ghost"}
              size="sm"
              className={cn(
                "text-xs px-3 py-1.5 h-auto transition-all duration-200",
                selectedPeriod === "today"
                  ? "bg-white/5 text-white hover:bg-white/10 shadow-lg shadow-white/5"
                  : "text-gray-500 hover:bg-white/5 hover:text-white",
              )}
            >
              Today
            </Button>
            <Button
              onClick={() => handlePeriodChange("tomorrow")}
              variant={selectedPeriod === "tomorrow" ? "default" : "ghost"}
              size="sm"
              className={cn(
                "text-xs px-3 py-1.5 h-auto transition-all duration-200",
                selectedPeriod === "tomorrow"
                  ? "bg-white/5 text-white hover:bg-white/10 shadow-lg shadow-white/5"
                  : "text-gray-500 hover:bg-white/5 hover:text-white",
              )}
            >
              Tomorrow
            </Button>
            <Button
              onClick={() => handlePeriodChange("week")}
              variant={selectedPeriod === "week" ? "default" : "ghost"}
              size="sm"
              className={cn(
                "text-xs px-3 py-1.5 h-auto transition-all duration-200",
                selectedPeriod === "week"
                  ? "bg-white/5 text-white hover:bg-white/10 shadow-lg shadow-white/5"
                  : "text-gray-500 hover:bg-white/5 hover:text-white",
              )}
            >
              Week
            </Button>
            <Button
              onClick={() => handlePeriodChange("month")}
              variant={selectedPeriod === "month" ? "default" : "ghost"}
              size="sm"
              className={cn(
                "text-xs px-3 py-1.5 h-auto transition-all duration-200",
                selectedPeriod === "month"
                  ? "bg-white/5 text-white hover:bg-white/10 shadow-lg shadow-white/5"
                  : "text-gray-500 hover:bg-white/5 hover:text-white",
              )}
            >
              Month
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <div className="flex gap-2 mt-2 mb-4">
            <EventSearch />
            <CountriesMultiSelectFilter
              title="Countries"
              selectedStatuses={countryFilter}
              onChange={handleCountryFilterChange}
              filterOptions={countryOptions}
            />
            <CountriesMultiSelectFilter
              title="Impact"
              selectedStatuses={impactFilter}
              onChange={handleImpactFilterChange}
              filterOptions={impactOptions}
            />
            <CountriesMultiSelectFilter
              title="Categories"
              selectedStatuses={categoryFilter}
              onChange={handleCategoryFilterChange}
              filterOptions={categoryOptions}
            />
            <DatePickerFilterPill
              title="Date"
              selectedStartDate=""
              onChange={handleDateFilterChange}
              onClear={onClearDateRange}
            />
            <Button className="bg-[#201f21] items-center justify-center hover:bg-[#333] cursor-pointer rounded-md border-[#333] border h-[42px]">
              <span className="overflow-hidden flex items-center gap-2 whitespace-nowrap text-white font-medium text-[16px]">
                Save Filters
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-4 h-4"
                >
                  <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
                  <polyline points="17 21 17 13 7 13 7 21" />
                  <polyline points="7 3 7 8 15 8" />
                </svg>
              </span>
            </Button>
          </div>
          <SimpleBar style={{ maxHeight: "100%" }}>
            <div>
              <table className="min-w-full">
                <thead className="bg-[#111] sticky top-0 z-20">
                  <tr>
                    <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider w-[100px]">
                      <Skeleton baseColor="#333" highlightColor="#444" />
                    </th>
                    <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider w-[100px]">
                      <Skeleton baseColor="#333" highlightColor="#444" />
                    </th>
                    <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider w-[100px]">
                      <Skeleton baseColor="#333" highlightColor="#444" />
                    </th>
                    <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider w-[100px]">
                      <Skeleton baseColor="#333" highlightColor="#444" />
                    </th>
                    <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider">
                      <Skeleton baseColor="#333" highlightColor="#444" />
                    </th>
                    <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider w-[100px]">
                      <Skeleton baseColor="#333" highlightColor="#444" />
                    </th>
                    <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider w-[100px]">
                      <Skeleton baseColor="#333" highlightColor="#444" />
                    </th>
                    <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider w-[100px]">
                      <Skeleton baseColor="#333" highlightColor="#444" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(5)].map((_, dayIndex) => (
                    <Fragment key={dayIndex}>
                      <tr className="bg-[#0a0a0a]">
                        <td colSpan={8} className="px-3 py-2">
                          <Skeleton baseColor="#333" highlightColor="#444" />
                        </td>
                      </tr>
                      {[...Array(3)].map((_, eventIndex) => (
                        <tr
                          key={eventIndex}
                          className="border-b border-gray-700"
                        >
                          <td className="px-3 py-2">
                            <Skeleton
                              baseColor="#333"
                              highlightColor="#444"
                              width={60}
                            />
                          </td>
                          <td className="px-3 py-2">
                            <Skeleton
                              baseColor="#333"
                              highlightColor="#444"
                              width={30}
                              height={20}
                              circle
                            />
                          </td>
                          <td className="px-3 py-2">
                            <Skeleton
                              baseColor="#333"
                              highlightColor="#444"
                              width={40}
                            />
                          </td>
                          <td className="px-3 py-2">
                            <Skeleton
                              baseColor="#333"
                              highlightColor="#444"
                              width={60}
                            />
                          </td>
                          <td className="px-3 py-2">
                            <Skeleton
                              baseColor="#333"
                              highlightColor="#444"
                              width={200}
                            />
                          </td>
                          <td className="px-3 py-2">
                            <Skeleton
                              baseColor="#333"
                              highlightColor="#444"
                              width={40}
                            />
                          </td>
                          <td className="px-3 py-2">
                            <Skeleton
                              baseColor="#333"
                              highlightColor="#444"
                              width={40}
                            />
                          </td>
                          <td className="px-3 py-2">
                            <Skeleton
                              baseColor="#333"
                              highlightColor="#444"
                              width={40}
                            />
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </SimpleBar>
        </CardContent>
      </Card>
    )
  }

  //TODO - fallback to error boundary
  if (error) {
    return <div>Error</div>
  }

  if (!economicCalendarData) {
    return (
      <Card className="h-full w-full border-none">
        <CardHeader className="flex flex-row items-center justify-between"></CardHeader>
        <CardContent>
          <div className="flex items-center justify-center h-full">
            <p className="text-white">No data available</p>
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card className="h-full w-full border-none flex flex-col text-white">
      <CardHeader className="flex flex-row items-center justify-between border-gray-700 p-2">
        <CardTitle className="text-base font-medium text-white/80">
          Economic Calendar
        </CardTitle>
        <div className="flex gap-2 relative z-[1]">
          <Button
            onClick={() => handlePeriodChange("today")}
            variant={selectedPeriod === "today" ? "default" : "ghost"}
            size="sm"
            className={cn(
              "text-xs px-3 py-1.5 h-auto transition-all duration-200",
              selectedPeriod === "today"
                ? "bg-white/5 text-white hover:bg-white/10 shadow-lg shadow-white/5"
                : "text-gray-500 hover:bg-white/5 hover:text-white",
            )}
          >
            Today
          </Button>
          <Button
            onClick={() => handlePeriodChange("tomorrow")}
            variant={selectedPeriod === "tomorrow" ? "default" : "ghost"}
            size="sm"
            className={cn(
              "text-xs px-3 py-1.5 h-auto transition-all duration-200",
              selectedPeriod === "tomorrow"
                ? "bg-white/5 text-white hover:bg-white/10 shadow-lg shadow-white/5"
                : "text-gray-500 hover:bg-white/5 hover:text-white",
            )}
          >
            Tomorrow
          </Button>
          <Button
            onClick={() => handlePeriodChange("week")}
            variant={selectedPeriod === "week" ? "default" : "ghost"}
            size="sm"
            className={cn(
              "text-xs px-3 py-1.5 h-auto transition-all duration-200",
              selectedPeriod === "week"
                ? "bg-white/5 text-white hover:bg-white/10 shadow-lg shadow-white/5"
                : "text-gray-500 hover:bg-white/5 hover:text-white",
            )}
          >
            Week
          </Button>
          <Button
            onClick={() => handlePeriodChange("month")}
            variant={selectedPeriod === "month" ? "default" : "ghost"}
            size="sm"
            className={cn(
              "text-xs px-3 py-1.5 h-auto transition-all duration-200",
              selectedPeriod === "month"
                ? "bg-white/5 text-white hover:bg-white/10 shadow-lg shadow-white/5"
                : "text-gray-500 hover:bg-white/5 hover:text-white",
            )}
          >
            Month
          </Button>
        </div>
      </CardHeader>
      <CardContent className="flex-grow overflow-hidden relative">
        <div className="flex gap-2 mt-2 mb-4">
          <EventSearch />
          <CountriesMultiSelectFilter
            title="Countries"
            selectedStatuses={countryFilter}
            onChange={handleCountryFilterChange}
            filterOptions={countryOptions}
          />
          <CountriesMultiSelectFilter
            title="Impact"
            selectedStatuses={impactFilter}
            onChange={handleImpactFilterChange}
            filterOptions={impactOptions}
          />
          <CountriesMultiSelectFilter
            title="Categories"
            selectedStatuses={categoryFilter}
            onChange={handleCategoryFilterChange}
            filterOptions={categoryOptions}
          />
          <DatePickerFilterPill
            title="Date"
            selectedStartDate=""
            onChange={handleDateFilterChange}
            onClear={onClearDateRange}
          />
          <Button
            onClick={saveUserFilters}
            className="bg-[#201f21] items-center justify-center hover:bg-[#333] cursor-pointer rounded-md border-[#333] border h-[42px]"
          >
            <span className="overflow-hidden flex items-center gap-2 whitespace-nowrap text-white font-medium text-[16px]">
              Save Filters
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-4 h-4"
              >
                <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
                <polyline points="17 21 17 13 7 13 7 21" />
                <polyline points="7 3 7 8 15 8" />
              </svg>
            </span>
          </Button>
        </div>
        <SimpleBar ref={simpleBarRef} style={{ height: "calc(100% - 60px)" }}>
          {resultArray.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-[#111] sticky top-0 z-20">
                <tr>
                  <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider">
                    Time
                  </th>
                  <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider">
                    Country
                  </th>
                  <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider">
                    Impact
                  </th>
                  <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider">
                    Event Name
                  </th>
                  <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider">
                    Actual
                  </th>
                  <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider">
                    Forecast
                  </th>
                  <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider">
                    Previous
                  </th>
                  <th className="px-3 py-2 text-left text-sm font-medium text-gray-400 uppercase tracking-wider">
                    <div className="flex items-center gap-1">
                      Deviation
                      <TooltipIcon
                        tooltipTitle="Deviation"
                        tooltipText="Measures how much the actual data deviates from expectations. Larger readings often signal higher market volatility."
                        className="h-4 w-4 text-gray-400"
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-inherit divide-y divide-gray-700">
                {resultArray.map((day: any) => {
                  const isToday =
                    getDayDateFormat(
                      adjustDateForTimezone(day.date).toISOString(),
                    ) === getDayDateFormat(new Date().toISOString())

                  return (
                    <Fragment key={day.date}>
                      <tr
                        ref={isToday ? todayRef : null}
                        className={`sticky top-[35px] z-10 bg-black ${
                          isToday
                            ? "border-y border-purple-500/50" // Special border for today
                            : "border-y border-[#333]"
                        }`}
                      >
                        <td
                          colSpan={9}
                          className="px-3 py-2 text-sm font-medium"
                        >
                          <div className="flex items-center gap-2">
                            {getDayDateFormat(
                              adjustDateForTimezone(day.date).toISOString(),
                            )}
                            {isToday && (
                              <span className="px-2 py-1 text-xs font-medium bg-purple-500/10 text-purple-400 border border-purple-500/20 rounded-full">
                                Today
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                      {day.events.map((event: any) => {
                        const unit = event.unit !== null ? event.unit : ""
                        return (
                          <tr key={event.id} className="hover:bg-gray-800/70">
                            <td className="px-3 py-2 text-sm">
                              {formatTime(event.dateUtc)}
                            </td>
                            <td className="px-3 py-2 text-sm">
                              <div className="rounded-full flex items-center gap-2">
                                {getCountryFlag(event.countryCode)}
                                {event.countryCode}
                              </div>
                            </td>
                            <td className="px-3 py-2 text-sm">
                              {HeadlineBadge(
                                event.name === "Retail Sales (YoY)"
                                  ? "HIGH"
                                  : event.volatility,
                              )}
                            </td>
                            <td className="px-3 py-2 text-sm">
                              <div className="flex items-center gap-2 group">
                                <button
                                  onClick={() =>
                                    handleCalendarEventAnalysis(event)
                                  }
                                  className="p-1.5 rounded-md flex items-center gap-2 hover:bg-gray-700 transition-colors duration-200"
                                >
                                  <div className="w-5 h-5 flex items-center justify-center flex-shrink-0">
                                    <Brain className="w-4 h-4 text-purple-400" />
                                  </div>
                                  <p className="hover:text-gray-300 transition-colors duration-200 text-left">
                                    {event.name}
                                  </p>
                                </button>

                                {event.isSpeech && (
                                  <div className="flex items-center">
                                    <span className="inline-flex items-center gap-1 bg-[#201f21] text-gray-400 px-1.5 py-0.5 rounded text-xs font-medium border border-[#333] group-hover:border-purple-500/20 group-hover:text-purple-400 transition-all duration-200">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="translate-y-[0.5px]"
                                      >
                                        <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z" />
                                        <path d="M19 10v2a7 7 0 0 1-14 0v-2" />
                                        <line x1="12" y1="19" x2="12" y2="22" />
                                      </svg>
                                      Speech
                                    </span>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className="px-3 py-2 text-sm">
                              {isWithinOneHour(event.dateUtc, event.actual) ? (
                                <Countdown releaseTime={event.dateUtc} />
                              ) : (
                                (() => {
                                  const formattedValue = formatValue(
                                    event.actual,
                                    event.unit,
                                    event.potency,
                                  )
                                  const { value, indicator, color } =
                                    getValueWithIndicator(
                                      event.actual,
                                      event.consensus,
                                      event.revised ?? event.previous,
                                      formattedValue,
                                    )
                                  const tooltipText = getActualValueTooltip(
                                    event.actual,
                                    event.consensus,
                                    event.revised ?? event.previous,
                                    event.unit,
                                    event.potency,
                                  )
                                  return (
                                    <div className="flex items-center gap-1 group relative cursor-pointer">
                                      {indicator && (
                                        <span
                                          className={clsx("text-xs", color)}
                                        >
                                          {indicator}
                                        </span>
                                      )}
                                      <span
                                        className={clsx("font-medium", color)}
                                        data-tip={tooltipText}
                                      >
                                        {value}
                                      </span>
                                      {tooltipText && (
                                        <div className="absolute bottom-full left-0 mb-2 hidden group-hover:block z-50">
                                          <div className="bg-black text-white text-sm rounded-md px-3 py-2 whitespace-nowrap border border-gray-800">
                                            {tooltipText}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )
                                })()
                              )}
                            </td>
                            <td className="px-3 py-2 text-sm">
                              {formatValue(
                                event.consensus,
                                event.unit,
                                event.potency,
                              )}
                            </td>
                            <td className="px-3 py-2 text-sm">
                              {formatValue(
                                event.revised ?? event.previous,
                                event.unit,
                                event.potency,
                              )}
                            </td>
                            <td className="px-3 py-2 text-sm">
                              {event.actual !== null &&
                              event.ratioDeviation !== null ? (
                                <span
                                  className={clsx(
                                    "px-2 py-1 rounded-md font-medium inline-flex items-center gap-1",
                                    event.ratioDeviation > 0
                                      ? "bg-green-500/10 text-green-400"
                                      : event.ratioDeviation < 0
                                        ? "bg-red-500/10 text-red-400"
                                        : "text-gray-400",
                                  )}
                                >
                                  {event.ratioDeviation !== 0 && (
                                    <Zap className="w-3 h-3" />
                                  )}
                                  {formatDeviation(event.ratioDeviation)}%
                                </span>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </Fragment>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <div className="flex items-center justify-center h-[400px] text-gray-400">
              <div className="text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-12 mx-auto mb-4 text-gray-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
                <p className="text-lg font-medium">No events found</p>
                <p className="text-sm mt-1">
                  Try adjusting your filters or selecting a different date range
                </p>
              </div>
            </div>
          )}
        </SimpleBar>
      </CardContent>
      <AnimatePresence>
        {isAnalysisPanelOpen && (
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ type: "spring", bounce: 0, duration: 0.4 }}
            className="fixed right-0 top-0 h-full bg-[#111] border-l border-[#333] backdrop-blur-xl bg-black/80 shadow-xl z-[50] flex"
          >
            <Resizable
              defaultSize={{ width: 500, height: "100%" }}
              minWidth={500}
              maxWidth={1000}
              enable={{
                top: false,
                right: false,
                bottom: false,
                left: true,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              handleClasses={{
                left: "w-1 h-full cursor-ew-resize hover:bg-purple-500/20 transition-colors absolute left-0 top-0 border-r border-purple-500/10",
              }}
            >
              <EventAnalysisPanel
                event={selectedEvent}
                onClose={() => setIsAnalysisPanelOpen(false)}
              />
            </Resizable>
          </motion.div>
        )}
      </AnimatePresence>
    </Card>
  )
}
