import { createFileRoute } from "@tanstack/react-router"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { StockChart } from "@/presentation/screens/equities/components/StockChart/StockChart"
import { useQuery, useMutation } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { Sun, Moon, Brain, XCircle } from "lucide-react"
import { motion, AnimatePresence } from "framer-motion"
import { AnalystRecommendations } from "@/presentation/screens/equities/components/AnalystRecommendations/AnalystRecommendations"
import { StockNews } from "@/presentation/screens/equities/components/StockNews/StockNews"
import { PriceTargets } from "@/presentation/screens/equities/components/PriceTargets/PriceTargets"
import { FinancialHealth } from "@/presentation/screens/equities/components/FinancialHealth/FinancialHealth"
import { useState } from "react"
import { ExpandablePanel } from "@/components/ui/ExpandablePanel"
import { DNA } from "react-loader-spinner"
import { StockSummaryPanel } from "@/presentation/screens/equities/components/StockSummaryPanel/StockSummaryPanel"
import { StockAnalysisPanel } from "@/presentation/screens/equities/components/StockAnalysisPanel/StockAnalysisPanel"
import { AIGenerateButton } from "@/components/ui/AIGenerateButton"
import posthog from "posthog-js"

// Add interface for the stock profile data
interface StockProfile {
  symbol: string
  price: number
  changes: number
  companyName: string
  currency: string
  exchange: string
  industry: string
  sector: string
  description: string
  website: string
  mktCap: number
  volAvg: number
}

// Add interface for after-market data
interface PrePostMarketData {
  symbol: string
  ask: number
  bid: number
  asize: number
  bsize: number
  timestamp: number
}

// Add function to format market cap
function formatMarketCap(marketCap: number): string {
  if (marketCap >= 1e12) return `$${(marketCap / 1e12).toFixed(2)}T`
  if (marketCap >= 1e9) return `$${(marketCap / 1e9).toFixed(2)}B`
  if (marketCap >= 1e6) return `$${(marketCap / 1e6).toFixed(2)}M`
  return `$${marketCap.toFixed(2)}`
}

export const Route = createFileRoute("/equities/symbol/$symbol")({
  component: SymbolDetails,
})

function SymbolDetails() {
  const { symbol } = Route.useParams()
  const { getAccessTokenSilently, user } = useAuth0()
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false)
  const [showAnalysisPanel, setShowAnalysisPanel] = useState(false)

  const { data: profile, isLoading } = useQuery({
    queryKey: ["stockProfile", symbol],
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/equities/profile/${symbol}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (!response.ok) {
        throw new Error("Failed to fetch stock profile")
      }
      return response.json()
    },
  })

  // Add new query for after-market data
  const { data: afterMarketData } = useQuery({
    queryKey: ["afterMarketPrice", symbol],
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/equities/pre-post-market/${symbol}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (!response.ok) {
        throw new Error("Failed to fetch after-market data")
      }
      return response.json() as Promise<PrePostMarketData>
    },
    // Refresh every minute
    refetchInterval: 60000,
  })

  const handleGenerateAnalysis = () => {
    setShowAnalysisPanel(true)
    posthog.capture("equities_stock_analysis_clicked", {
      user: user?.email,
      symbol,
    })
  }

  return (
    <main className="py-6">
      <div className="px-12 sm:px-6 lg:px-12 mx-auto max-w-[1400px] flex flex-col items-center gap-6">
        {/* Symbol Only */}
        <div className="w-full max-w-[1400px] flex flex-row gap-4">
          <div className="flex items-center gap-3">
            <div className="w-6 h-6 relative flex items-center justify-center bg-white/5 rounded-lg overflow-hidden">
              <img
                src={`https://financialmodelingprep.com/image-stock/${symbol}.png`}
                alt={`${symbol} logo`}
                className="w-full h-full object-contain"
                onError={(e) => {
                  const target = e.target as HTMLImageElement
                  target.style.display = "none"
                  target.parentElement!.innerHTML = symbol
                }}
              />
            </div>
            <span className="text-3xl font-bold text-white">{symbol}</span>
            <span className="text-base font-normal text-gray-400">
              {profile?.companyName}
            </span>
          </div>
        </div>

        {/* Minimalistic Price Display */}
        {profile && (
          <div className="w-full max-w-[1400px]">
            <div className="flex items-center gap-8">
              {/* Market Hours Price */}
              <div className="flex items-baseline gap-2">
                <Sun className="w-3.5 h-3.5 text-yellow-500/80" />
                <span className="text-2xl font-bold text-white">
                  ${profile.price}
                </span>
                <span
                  className={`text-sm font-medium ${
                    profile.changes >= 0 ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {profile.changes >= 0 ? "+" : ""}
                  {profile.changes.toFixed(2)} (
                  {((profile.changes / profile.price) * 100).toFixed(2)}%)
                </span>
              </div>

              {/* After Hours Price */}
              {afterMarketData && (
                <div className="flex items-baseline gap-2">
                  <Moon className="w-3.5 h-3.5 text-blue-400/60" />
                  <span className="text-2xl font-bold text-gray-400">
                    ${afterMarketData.bid.toFixed(2)}
                  </span>
                  <span
                    className={`text-sm font-medium ${
                      afterMarketData.bid - profile.price >= 0
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {afterMarketData.bid - profile.price >= 0 ? "+" : ""}
                    {(afterMarketData.bid - profile.price).toFixed(2)} (
                    {(
                      ((afterMarketData.bid - profile.price) / profile.price) *
                      100
                    ).toFixed(2)}
                    %)
                  </span>
                  <span className="text-xs text-gray-500 ml-1">
                    {new Date(afterMarketData.timestamp).toLocaleTimeString()}
                  </span>
                </div>
              )}

              {/* AI Summary Button */}
              <div className="flex items-center gap-2 ml-auto">
                <AIGenerateButton
                  onClick={handleGenerateAnalysis}
                  isGenerating={isGeneratingSummary}
                  text="Analyze Stock"
                />
              </div>
            </div>
          </div>
        )}

        {/* Main Content Grid */}
        <div className="w-full max-w-[1400px] grid grid-cols-12 gap-6">
          {/* Left Column */}
          <div className="col-span-12 lg:col-span-8 space-y-6">
            {/* Chart */}
            <div className="w-full">
              <StockChart symbol={symbol} profile={profile} />
            </div>

            {/* Financial Health */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="backdrop-blur-xl bg-black/[0.2] border border-white/10 shadow-lg rounded-xl shadow-black/5"
            >
              <FinancialHealth symbol={symbol} />
            </motion.div>

            {/* Price Targets */}
            <Card className="backdrop-blur-xl bg-black/[0.2] border-white/10 shadow-lg shadow-black/5">
              <CardContent>
                <PriceTargets symbol={symbol} />
              </CardContent>
            </Card>

            {/* Analyst Recommendations */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="relative w-full"
            >
              <div className="relative backdrop-blur-xl bg-black/[0.2] border border-white/10 shadow-lg shadow-black/5 rounded-xl overflow-hidden p-6 h-full">
                <h3 className="text-lg font-semibold text-white mb-4">
                  Analyst Recommendations
                </h3>
                <AnalystRecommendations symbol={symbol} />
              </div>
            </motion.div>
          </div>

          {/* Right Column - News Feed */}
          <div className="col-span-12 lg:col-span-4">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="relative w-full h-[calc(100vh-12rem)] sticky top-6"
            >
              <div className="relative backdrop-blur-xl bg-black/[0.2] border border-white/10 shadow-lg shadow-black/5 rounded-xl overflow-hidden p-6 h-full">
                <h3 className="text-lg font-semibold text-white mb-4">
                  Latest News
                </h3>
                <StockNews symbol={symbol} />
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {showAnalysisPanel && (
          <StockAnalysisPanel
            symbol={symbol}
            companyName={profile?.companyName}
            onClose={() => setShowAnalysisPanel(false)}
          />
        )}
      </AnimatePresence>
    </main>
  )
}
