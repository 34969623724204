import { createFileRoute, useNavigate } from "@tanstack/react-router"
import { useQuery } from "@tanstack/react-query"
import { Loader2 } from "lucide-react"
import { useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"

import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { LoadingScreen, useUser } from "@/providers"

export const Route = createFileRoute("/capital-hungry")({
  validateSearch: (search: Record<string, unknown>) => {
    const plan = search.plan as string | undefined
    if (!plan || !["monthly", "annual"].includes(plan)) {
      return { plan: undefined }
    }
    return { plan: plan as "monthly" | "annual" }
  },
  component: () => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
    const { plan } = Route.useSearch()
    const { isSubscribed, isLoading: isUserLoading } = useUser()
    const navigate = useNavigate()

    useEffect(() => {
      if (!isLoading && !isAuthenticated) {
        loginWithRedirect({
          appState: {
            returnTo: "/capital-hungry",
            plan,
          },
        })
        return
      }

      if (!isLoading && !isUserLoading) {
        if (isAuthenticated && isSubscribed) {
          navigate({ to: "/dashboard" })
          return
        }

        if (
          (!plan || !["monthly", "annual"].includes(plan)) &&
          isAuthenticated &&
          !isSubscribed
        ) {
          navigate({ to: "/checkout" })
          return
        }
      }
    }, [
      isLoading,
      isAuthenticated,
      isUserLoading,
      plan,
      isSubscribed,
      navigate,
      loginWithRedirect,
    ])

    // Show LoadingScreen while checking auth status or redirecting
    if (isLoading || !isAuthenticated || isUserLoading) {
      return <LoadingScreen />
    }

    return <CapitalHungryRoute />
  },
})

function CapitalHungryRoute() {
  const [email, setEmail] = useState("")
  const [validateEmailError, setValidateEmailError] = useState("")
  const { plan } = Route.useSearch()
  const navigate = useNavigate()
  const { getAccessTokenSilently, user } = useAuth0()

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["validateEmail", email],
    queryFn: async () => {
      if (!plan || !user?.email) return null
      const token = await getAccessTokenSilently()
      const params = new URLSearchParams({
        email: email,
        plan,
      })
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/capital-hungry?${params}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (!response.ok) {
        throw new Error("Email could not be validated")
      }
      const data = await response.json()
      return data
    },
    enabled: false,
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (email === user?.email) {
      setValidateEmailError("")
      refetch()
    } else {
      setValidateEmailError("Email does not match the one you used to login")
    }
  }

  const handleCheckout = () => {
    if (data?.sessionUrl) {
      window.location.href = data.sessionUrl
    }
  }

  const handleRegularCheckout = () => {
    navigate({ to: "/checkout", search: { plan } })
  }

  return (
    <div className="min-h-screen bg-black flex items-center justify-center">
      <Card className="w-full max-w-md border border-[#333] bg-obsidian text-zinc-100">
        <CardHeader className="p-4">
          <CardTitle className="text-xl font-semibold">
            Email Validation
          </CardTitle>
          <CardDescription className="text-zinc-400 text-md">
            Please enter your email address to check whether you qualify for
            Capital Hungry pricing.
          </CardDescription>
        </CardHeader>
        <CardContent className="p-4">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="email" className="text-zinc-200 text-md">
                Email Address
              </Label>
              <Input
                id="email"
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border-zinc-800 bg-zinc-900 text-zinc-100 text-md placeholder:text-zinc-500 placeholder:text-md"
                required
              />
            </div>
            <div className="space-y-4">
              <Button
                type="submit"
                disabled={isLoading}
                className="w-full bg-red-500 hover:bg-red-600 text-white text-md"
              >
                {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                {isLoading ? "Validating..." : "Validate Email"}
              </Button>

              {data?.verified && (
                <Button
                  onClick={handleCheckout}
                  className="w-full bg-green-600 hover:bg-green-700 text-white text-md"
                >
                  Continue to Checkout
                </Button>
              )}

              {validateEmailError && (
                <p className="text-red-400 text-md">{validateEmailError}</p>
              )}

              {error && (
                <>
                  <p className="text-red-400 text-md">
                    Email could not be validated
                  </p>
                  <Button
                    onClick={handleRegularCheckout}
                    className="w-full bg-zinc-700 hover:bg-zinc-600 text-white text-md"
                  >
                    Checkout with Regular Pricing
                  </Button>
                </>
              )}

              <div className="pt-4 text-center flex justify-center gap-6">
                <a
                  href="/checkout"
                  className="text-zinc-400 hover:text-zinc-200 text-md underline"
                >
                  Back to Pricing
                </a>
                <a
                  href="https://mrktedge.ai"
                  className="text-zinc-400 hover:text-zinc-200 text-md underline"
                >
                  Return to Website
                </a>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  )
}
