import { useState, useEffect, useRef } from "react"
import { motion } from "framer-motion"
import {
  ArrowLeft,
  Check,
  ChevronRight,
  Newspaper,
  Target,
  Globe2,
  LineChart,
  Calendar,
  Search,
  Loader2,
  X,
} from "lucide-react"
import { cn } from "@/lib/utils"
import { usePostHog } from "posthog-js/react"

import { TradingInfo, useUpdateUser } from "@/hooks/useUpdateUser"
import { useAuth0 } from "@auth0/auth0-react"
import { toast } from "@/hooks/use-toast"
import { useNavigate } from "@tanstack/react-router"
import { useQuery } from "@tanstack/react-query"

import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { useUser } from "@/providers/UserProvider"
import { Input } from "@/components/ui/input"

import { useDebounce } from "@/hooks/useDebounce"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"

interface UserProfile {
  name: string
  tradingExperience: "beginner" | "intermediate" | "advanced"
  tradingInstruments: ("equities" | "forex" | "crypto" | "futures")[]
  specificAssets: {
    forex: string[]
    equities: string[]
  }
  tradingYears: string
  referralSource: string
  userName: string
  goals: string[]
  selectedDashboard: string
}

interface SearchSymbolResult {
  symbol: string
  name: string
  type: string
}

interface SearchSymbolsResponse {
  success: boolean
  data: {
    stock: SearchSymbolResult[]
    crypto: SearchSymbolResult[]
    forex: SearchSymbolResult[]
    commodity: SearchSymbolResult[]
    index: SearchSymbolResult[]
  }
}

// Sample data
const marketTypes = [
  {
    id: "equities",
    name: "Equities",
    icon: "📈",
    description: "Stocks and shares of public companies",
  },
  {
    id: "forex",
    name: "Forex",
    icon: "💱",
    description: "Foreign exchange currency pairs",
  },
  {
    id: "crypto",
    name: "Crypto",
    icon: "💰",
    description: "Cryptocurrencies and digital assets",
  },
  {
    id: "futures",
    name: "Futures",
    icon: "🔮",
    description: "Contracts for future delivery",
  },
]

const equitiesOptions = [
  { value: "AAPL", label: "AAPL" },
  { value: "MSFT", label: "MSFT" },
  { value: "AMZN", label: "AMZN" },
  { value: "GOOGL", label: "GOOGL" },
  { value: "META", label: "META" },
  { value: "TSLA", label: "TSLA" },
  { value: "NVDA", label: "NVDA" },
  { value: "ESUSD", label: "S&P 500" },
  { value: "NQUSD", label: "Nasdaq" },
  { value: "YMUSD", label: "Dow Jones" },
]

const forexOptions = [
  { value: "XAUUSD", label: "XAU/USD" },
  { value: "EURUSD", label: "EUR/USD" },
  { value: "GBPUSD", label: "GBP/USD" },
  { value: "USDJPY", label: "USD/JPY" },
  { value: "GBPJPY", label: "GBP/JPY" },
  { value: "USDCAD", label: "USD/CAD" },
  { value: "AUDUSD", label: "AUD/USD" },
  { value: "NZDUSD", label: "NZD/USD" },
  { value: "EURGBP", label: "EUR/GBP" },
  { value: "EURJPY", label: "EUR/JPY" },
  { value: "GBPAUD", label: "GBP/AUD" },
]

const cryptoOptions = [
  { value: "BTCUSD", label: "BTC" },
  { value: "ETHUSD", label: "ETH" },
  { value: "SOLUSD", label: "SOL" },
  { value: "BNBUSD", label: "BNB" },
  { value: "XRPUSD", label: "XRP" },
  { value: "ADAUSD", label: "ADA" },
  { value: "AVAXUSD", label: "AVAX" },
  { value: "DOTUSD", label: "DOT" },
  { value: "DOGEUSD", label: "DOGE" },
]

const futuresOptions = [
  { value: "ESUSD", label: "E-Mini S&P 500" },
  { value: "NQUSD", label: "Nasdaq 100" },
  { value: "YMUSD", label: "Mini Dow" },
  { value: "CLUSD", label: "Crude Oil" },
  { value: "GCUSD", label: "Gold" },
  { value: "SIUSD", label: "Silver" },
  { value: "ZBUSD", label: "30Y T-Bond" },
  { value: "ZNUSD", label: "10Y T-Note" },
  { value: "NGUSD", label: "Natural Gas" },
  { value: "BZUSD", label: "Brent Crude" },
  { value: "HGUSD", label: "Copper" },
  { value: "ZCUSX", label: "Corn" },
]

const experienceYears = [
  {
    value: "less_than_1",
    label: "🆕 New Trader",
    description: "Getting started (0-1 year)",
    icon: "🌱",
  },
  {
    value: "1_to_5",
    label: "⚡ Active Trader",
    description: "Building experience (1-5 years)",
    icon: "📈",
  },
  {
    value: "more_than_5",
    label: "🎯 Pro Trader",
    description: "Seasoned professional (5+ years)",
    icon: "💫",
  },
]

const referralOptions = [
  { id: "social_media", label: "Social Media", icon: "📱" },
  { id: "capital_hungry", label: "Capital Hungry", icon: "📈" },
  { id: "search_engine", label: "Search Engine", icon: "🔍" },
  { id: "email_newsletter", label: "Email Newsletter", icon: "✉️" },
  { id: "online_ads", label: "Online Ads", icon: "📢" },
  { id: "blogs_articles", label: "Blogs or Articles", icon: "📰" },
  { id: "affiliate_links", label: "Affiliate Links", icon: "🔗" },
  { id: "review_sites", label: "Review Sites", icon: "⭐" },
]

const mrktGoals = [
  {
    id: "news",
    label: "Live market news",
    icon: Newspaper,
    description: "Real-time news updates with AI-powered relevance filtering",
  },
  {
    id: "trade_setups",
    label: "Find trade setups",
    icon: Target,
    description: "Get AI-powered trade ideas and market opportunities",
  },
  {
    id: "macro_understanding",
    label: "Improve macro knowledge",
    icon: Globe2,
    description: "Deep insights into market dynamics and economic trends",
  },
  {
    id: "sentiment",
    label: "Get market sentiment",
    icon: LineChart,
    description: "Real-time analysis of market mood and trends",
  },
  {
    id: "calendar",
    label: "Access economic calendar",
    icon: Calendar,
    description: "Smart economic events tracking and impact analysis",
  },
  {
    id: "research",
    label: "Stock research",
    icon: Search,
    description: "AI-powered company and market research insights",
  },
]

const ProgressBar = ({ currentStep }: { currentStep: number }) => {
  const totalSteps = 8
  const progress = (currentStep / totalSteps) * 100

  return (
    <div className="absolute top-4 sm:top-8 left-1/2 transform -translate-x-1/2 w-[90%] max-w-3xl px-4">
      <div className="flex items-center justify-between mb-2">
        <span className="text-sm font-medium text-gray-400">
          Onboarding Progress
        </span>
        <span className="text-sm font-medium text-white">
          {Math.round(progress)}%
        </span>
      </div>
      <div className="h-2 bg-[#1C1C28] rounded-full overflow-hidden">
        <motion.div
          initial={false} // Prevent initial animation
          animate={{ width: `${progress}%` }}
          className="h-full bg-gradient-to-r from-purple-600 to-indigo-600 rounded-full"
          transition={{ duration: 0.5, ease: "easeInOut" }}
        />
      </div>
    </div>
  )
}

const BackButton = ({ onClick }: { onClick: () => void }) => (
  <motion.button
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    onClick={onClick}
    className="text-gray-400 hover:text-white flex items-center group"
  >
    <ArrowLeft className="w-5 h-5 mr-2 transform group-hover:-translate-x-1 transition-transform duration-300" />
    Back
  </motion.button>
)

const ContinueButton = ({
  onClick,
  disabled,
  children = "Continue",
  className,
}: {
  onClick: () => void
  disabled?: boolean
  children?: React.ReactNode
  className?: string
}) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    className={cn(
      "w-[300px] h-12 text-white transition-all duration-200",
      "bg-gradient-to-r from-purple-600 to-indigo-600",
      "hover:from-purple-700 hover:to-indigo-700",
      "disabled:opacity-50 disabled:cursor-not-allowed",
      className,
    )}
  >
    {children}
  </Button>
)

const StepContainer = ({
  children,
  title,
  subtitle,
  onBack,
}: {
  children: React.ReactNode
  title: string
  subtitle?: string
  onBack?: () => void
}) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    className="w-[calc(100%-2rem)] max-w-[896px] min-h-[640px] rounded-xl shadow-2xl flex flex-col relative"
  >
    <div className="absolute top-0 left-0 right-0 h-[176px] px-4 sm:px-8 pt-8">
      {onBack && (
        <div className="absolute top-8 left-4 sm:left-8">
          <BackButton onClick={onBack} />
        </div>
      )}
      <div className="h-[120px] flex flex-col justify-center mt-6">
        <h2 className="text-2xl sm:text-3xl font-medium text-white mb-2 text-center">
          {title}
        </h2>
        {subtitle && (
          <p className="text-sm sm:text-base text-gray-400 text-center">
            {subtitle}
          </p>
        )}
      </div>
    </div>

    <div className="w-full pt-[176px] px-4 sm:px-8 pb-8">{children}</div>
  </motion.div>
)

export const OnboardingScreen = () => {
  const [step, setStep] = useState(1)
  const posthog = usePostHog()
  const { user, getAccessTokenSilently } = useAuth0()
  const [userProfile, setUserProfile] = useState<UserProfile>({
    name: "",
    tradingExperience: "beginner",
    tradingInstruments: [],
    specificAssets: {
      forex: [],
      equities: [],
    },
    tradingYears: "",
    referralSource: "",
    userName: "",
    goals: [],
    selectedDashboard: "dashboard",
  })

  const [selectedMarket, setSelectedMarket] = useState<string | null>(null)
  const [selectedAssets, setSelectedAssets] = useState<string[]>([])
  const [selectedDashboard, setSelectedDashboard] = useState<
    "equities" | "dashboard"
  >()
  // Update the selectedMarketTab state to default to "stocks"
  const [selectedMarketTab, setSelectedMarketTab] = useState<string>("stocks")

  const { fetchTechnicalSentiment } = useUser()

  // Auth hooks
  const { updateUser } = useUpdateUser()
  const navigate = useNavigate()
  const { userData, isLoading: isUserLoading } = useUser()

  // Replace the manual search state with useQuery
  const [searchQuery, setSearchQuery] = useState("")
  const debouncedSearchQuery = useDebounce(searchQuery, 500)

  // Use Tanstack Query for fetching search results
  const {
    data: searchResults,
    isLoading: isSearching,
    error,
  } = useQuery({
    queryKey: ["symbolSearch", debouncedSearchQuery],
    queryFn: async () => {
      try {
        const token = await getAccessTokenSilently()
        if (!debouncedSearchQuery || debouncedSearchQuery.length < 2) {
          return null
        }

        const url = `${import.meta.env.VITE_MRKT_SERVER}/mrkt-ai/search-symbols?query=${encodeURIComponent(debouncedSearchQuery)}`

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })

        if (!response.ok) {
          const errorText = await response.text()
          throw new Error(
            `Failed to fetch search results: ${response.status} ${errorText}`,
          )
        }

        const data = await response.json()
        return data as SearchSymbolsResponse
      } catch (err) {
        console.error("Symbol search error:", err)
        throw err
      }
    },
    enabled: debouncedSearchQuery.length >= 2,
    staleTime: 1000 * 60 * 5, // Cache results for 5 minutes
    refetchOnWindowFocus: false,
    retry: 1,
  })

  // Add this useEffect to log errors
  useEffect(() => {
    if (error) {
      console.error("Search query error:", error)
      toast({
        title: "Search Error",
        description: "Failed to fetch symbols. Please try again.",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    }
  }, [error])

  // Track when onboarding starts
  useEffect(() => {
    posthog.capture("onboarding_started", {
      user: user?.email,
      timestamp: new Date().toISOString(),
    })
  }, [])

  // Track step changes
  useEffect(() => {
    posthog.capture("onboarding_step_changed", {
      step,
      user: user?.email,
      timestamp: new Date().toISOString(),
    })
  }, [step])

  const handleMarketSelect = (marketId: string) => {
    const isSelected = userProfile.tradingInstruments.includes(marketId as any)
    const newInstruments = isSelected
      ? userProfile.tradingInstruments.filter((m) => m !== marketId)
      : [
          ...userProfile.tradingInstruments,
          marketId as "equities" | "forex" | "crypto" | "futures",
        ]

    setUserProfile({
      ...userProfile,
      tradingInstruments: newInstruments,
    })

    // Track market selection
    posthog.capture("onboarding_market_selected", {
      market: marketId,
      action: isSelected ? "deselected" : "selected",
      user: user?.email,
      timestamp: new Date().toISOString(),
    })
  }

  const handleAssetSelect = (asset: string) => {
    setSelectedAssets((prev) => {
      // If already selected, remove it
      if (prev.includes(asset)) {
        // Track asset deselection
        posthog.capture("onboarding_asset_selected", {
          asset,
          action: "deselected",
          user: user?.email,
          timestamp: new Date().toISOString(),
        })
        return prev.filter((a) => a !== asset)
      }

      // If not selected and we're at the limit, show a toast and don't add
      if (prev.length >= 6) {
        toast({
          title: "Maximum assets reached",
          description: "You can select up to 6 assets for your dashboard",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })
        return prev
      }

      // Track asset selection
      posthog.capture("onboarding_asset_selected", {
        asset,
        action: "selected",
        user: user?.email,
        timestamp: new Date().toISOString(),
      })

      // Otherwise, add it
      return [...prev, asset]
    })
  }

  const handleExperienceSelect = (value: string) => {
    let experienceLevel: "beginner" | "intermediate" | "advanced"

    switch (value) {
      case "less_than_1":
        experienceLevel = "beginner"
        break
      case "1_to_5":
        experienceLevel = "intermediate"
        break
      case "more_than_5":
        experienceLevel = "advanced"
        break
      default:
        experienceLevel = "beginner"
    }

    setUserProfile({
      ...userProfile,
      tradingExperience: experienceLevel,
      tradingYears: value,
    })

    // Track experience selection
    posthog.capture("onboarding_experience_selected", {
      experience_level: experienceLevel,
      years: value,
      user: user?.email,
      timestamp: new Date().toISOString(),
    })
  }

  const handleDashboardSelect = (dashboard: "equities" | "dashboard") => {
    setSelectedDashboard(dashboard)

    // Track dashboard selection
    posthog.capture("onboarding_dashboard_selected", {
      dashboard_type: dashboard,
      user: user?.email,
      timestamp: new Date().toISOString(),
    })
  }

  const completeUserOnboarding = async () => {
    try {
      if (!user?.email) {
        toast({
          title: "Error",
          description: "Failed to save onboarding data",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })
        return
      }

      // Fill remaining slots if user selected fewer than 6 assets
      let finalAssets = [...selectedAssets]
      if (finalAssets.length < 6) {
        const remainingSlots = 6 - finalAssets.length
        const defaultAssets = getDefaultAssetsToFill(
          finalAssets,
          selectedMarketTab,
          remainingSlots,
        )
        finalAssets = [...finalAssets, ...defaultAssets]
      }

      // Update the user data
      await updateUser({
        email: user.email,
        updates: {
          name: userProfile.name || user.name || "",
          hasOnboarded: true,
          tradingInfo: {
            tradingExperience: userProfile.tradingExperience,
            tradingInstruments:
              userProfile.tradingInstruments as TradingInfo["tradingInstruments"],
            specificAssets: {
              equities: finalAssets,
              forex: finalAssets,
            },
            tradingYears: userProfile.tradingYears,
            fundamentalsExperience: userProfile.tradingExperience,
            goals: userProfile.goals,
            selectedDashboard: selectedDashboard,
          },
          referralSource: userProfile.referralSource,
          userName: userProfile.userName || user.nickname || "",
          showFirstTimeReport: true,
        },
      })

      // Track onboarding completion
      posthog.capture("onboarding_completed", {
        user: user?.email,
        trading_experience: userProfile.tradingExperience,
        trading_instruments: userProfile.tradingInstruments,
        selected_assets: finalAssets,
        dashboard_type: selectedDashboard,
        referral_source: userProfile.referralSource,
        goals: userProfile.goals,
        timestamp: new Date().toISOString(),
      })

      // Show a simple toast without mentioning the added default assets
      toast({
        description: "Your preferences have been saved!",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })

      await new Promise((resolve) => setTimeout(resolve, 100))

      // Check if user is subscribed
      if (!userData?.isSubscribed) {
        // If not subscribed, redirect to checkout
        navigate({ to: "/checkout" })
      } else {
        // If subscribed, navigate based on selected dashboard
        navigate({
          to: selectedDashboard === "equities" ? "/equities" : "/dashboard",
        })
      }
    } catch (error) {
      console.error("Error updating user profile:", error)
      toast({
        title: "Error",
        description: "Failed to save your preferences",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    }
  }

  // Add this function to get default assets that aren't already selected
  const getDefaultAssetsToFill = (
    currentAssets: string[],
    marketTab: string,
    count: number,
  ) => {
    // Get the appropriate options based on the selected market tab
    let options: { value: string; label: string }[] = []

    switch (marketTab) {
      case "stocks":
        options = equitiesOptions
        break
      case "forex":
        options = forexOptions
        break
      case "crypto":
        options = cryptoOptions
        break
      case "futures":
        options = futuresOptions
        break
      default:
        options = [...equitiesOptions, ...forexOptions, ...cryptoOptions]
    }

    // Filter out options that are already selected
    const availableOptions = options.filter(
      (option) => !currentAssets.includes(option.value),
    )

    // Return the required number of default options (or all available if fewer)
    return availableOptions.slice(0, count).map((option) => option.value)
  }

  // Add this function to get assets based on the selected tab
  const getAssetOptionsForTab = (tab: string) => {
    switch (tab) {
      case "stocks":
        return equitiesOptions.map((option) => ({
          ...option,
        }))
      case "forex":
        return forexOptions.map((option) => ({
          ...option,
        }))
      case "crypto":
        return cryptoOptions.map((option) => ({
          ...option,
        }))
      case "futures":
        return futuresOptions.map((option) => ({
          ...option,
        }))
      default:
        return []
    }
  }

  // Step 1: Market Selection
  const renderMarketSelection = () => (
    <StepContainer
      title="What markets do you trade?"
      subtitle="Select all the markets you're interested in"
    >
      <div className="grid grid-cols-2 gap-3">
        {marketTypes.map((market) => (
          <motion.button
            key={market.id}
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleMarketSelect(market.id)}
            className={cn(
              "p-4 rounded-lg flex flex-col items-center justify-center space-y-2 border transition-all duration-200",
              "bg-[#0A0A0A]/80 backdrop-blur-sm",
              userProfile.tradingInstruments.includes(market.id as any)
                ? "border-purple-600 bg-[#1A1A1A] shadow-[0_0_15px_rgba(147,51,234,0.1)]"
                : "border-[#2A2A2A] hover:border-purple-600/50 hover:bg-[#1A1A1A]",
            )}
          >
            <span className="text-3xl">{market.icon}</span>
            <span className="text-white font-medium">{market.name}</span>
          </motion.button>
        ))}
      </div>

      <div className="mt-8 flex justify-center">
        <ContinueButton
          onClick={() => setStep(2)}
          disabled={userProfile.tradingInstruments.length === 0}
        />
      </div>
    </StepContainer>
  )

  // Step 2: Asset Selection
  const renderAssetSelection = () => (
    <StepContainer
      title="What assets do you trade?"
      subtitle="Select up to 6 assets for your dashboard"
      onBack={() => setStep(1)}
    >
      <div className="w-full max-w-3xl mx-auto px-2 sm:px-4">
        {/* Market type tabs with integrated search - improved for mobile */}
        <div className="flex flex-col mb-4 sm:mb-6">
          {/* Market tabs - scrollable on mobile */}

          {/* Search input - full width and more visible */}
          <div className="relative w-full mb-4">
            <Popover
              open={debouncedSearchQuery.length >= 2}
              onOpenChange={(open) => {
                if (!open && debouncedSearchQuery.length >= 2) {
                  setSearchQuery("")
                }
              }}
            >
              <PopoverTrigger asChild>
                <div className="relative">
                  <Input
                    ref={searchInputRef}
                    type="text"
                    placeholder="Search all markets..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="bg-[#0A0A0A] border-[#2A2A2A] text-white h-10 pl-9 pr-3 text-sm rounded-md w-full"
                    onClick={(e) => {
                      e.stopPropagation()
                      searchInputRef.current?.focus()
                    }}
                    onFocus={(e) => e.target.select()}
                    onBlur={(e) => {
                      if (
                        e.relatedTarget &&
                        (e.relatedTarget as HTMLElement).closest(
                          "[data-radix-popper-content-wrapper]",
                        )
                      ) {
                        e.preventDefault()
                        setTimeout(() => searchInputRef.current?.focus(), 0)
                      }
                    }}
                  />
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
                  {isSearching && (
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      <Loader2 className="h-4 w-4 text-gray-400 animate-spin" />
                    </div>
                  )}
                </div>
              </PopoverTrigger>

              {/* Search results popover */}
              {debouncedSearchQuery.length >= 2 && (
                <PopoverContent
                  className="w-[280px] p-0 bg-[#141414] border-[#2A2A2A] shadow-lg"
                  align="end"
                  sideOffset={5}
                  onInteractOutside={(e) => {
                    if (
                      e.target &&
                      (e.target as HTMLElement).closest("input")
                    ) {
                      e.preventDefault()
                    }
                  }}
                  onOpenAutoFocus={(e) => {
                    e.preventDefault()
                    setTimeout(() => searchInputRef.current?.focus(), 0)
                  }}
                  onCloseAutoFocus={(e) => {
                    e.preventDefault()
                    setTimeout(() => searchInputRef.current?.focus(), 0)
                  }}
                >
                  <SimpleBar style={{ maxHeight: 250 }} className="p-1">
                    {/* Search results content */}
                    {isSearching ? (
                      <div className="flex justify-center py-4">
                        <Loader2 className="h-5 w-5 text-purple-500 animate-spin" />
                      </div>
                    ) : error ? (
                      <div className="text-center py-3 text-red-400 text-sm">
                        Error fetching results
                      </div>
                    ) : searchResults?.success &&
                      searchResults.data &&
                      Object.values(searchResults.data).some(
                        (arr) => arr.length > 0,
                      ) ? (
                      <div className="space-y-3">
                        {Object.entries(searchResults.data).map(
                          ([category, results]) => {
                            if (!results || results.length === 0) return null

                            return (
                              <div key={category} className="space-y-1">
                                <h3 className="text-xs font-semibold text-gray-400 uppercase px-2 py-1 sticky top-0 bg-[#141414] z-10">
                                  {category}
                                </h3>
                                <div className="space-y-0.5">
                                  {results.map((result: SearchSymbolResult) => (
                                    <button
                                      key={result.symbol}
                                      onClick={() => {
                                        handleAssetSelect(result.symbol)
                                        setSearchQuery("") // Clear search after selection
                                      }}
                                      disabled={
                                        selectedAssets.length >= 6 &&
                                        !selectedAssets.includes(result.symbol)
                                      }
                                      className={cn(
                                        "w-full flex items-center justify-between py-1.5 px-2 rounded-md text-sm",
                                        "hover:bg-[#1A1A1A] transition-colors text-left",
                                        selectedAssets.includes(result.symbol)
                                          ? "bg-[#1A1A1A] border-l-2 border-purple-500"
                                          : selectedAssets.length >= 6
                                            ? "border-l-2 border-transparent opacity-50 cursor-not-allowed"
                                            : "border-l-2 border-transparent",
                                      )}
                                    >
                                      <div className="flex items-center">
                                        <span className="font-medium text-white">
                                          {result.symbol}
                                        </span>
                                        <span className="ml-2 text-xs text-gray-400 truncate max-w-[150px]">
                                          {result.name}
                                        </span>
                                      </div>
                                      {selectedAssets.includes(
                                        result.symbol,
                                      ) && (
                                        <Check className="h-3 w-3 text-purple-500 flex-shrink-0" />
                                      )}
                                    </button>
                                  ))}
                                </div>
                              </div>
                            )
                          },
                        )}
                      </div>
                    ) : (
                      <div className="py-3 text-center text-gray-400 text-sm">
                        No results found
                      </div>
                    )}
                  </SimpleBar>
                </PopoverContent>
              )}
            </Popover>
          </div>
          <div className="bg-[#111111] rounded-lg p-1 sm:p-1.5 overflow-x-auto mb-2 sm:mb-3 no-scrollbar">
            <div className="flex min-w-max">
              <Button
                variant={selectedMarketTab === "stocks" ? "default" : "ghost"}
                className={cn(
                  "flex items-center gap-1.5 rounded-md py-2 px-3 text-sm font-medium",
                  selectedMarketTab === "stocks"
                    ? "bg-white text-black hover:bg-white hover:text-black"
                    : "text-gray-400 hover:text-white hover:bg-[#1A1A1A]/50",
                )}
                onClick={() => setSelectedMarketTab("stocks")}
              >
                <span className="text-lg">📈</span>
                <span>Stocks</span>
              </Button>

              <Button
                variant={selectedMarketTab === "forex" ? "default" : "ghost"}
                className={cn(
                  "flex items-center gap-1.5 rounded-md py-2 px-3 text-sm font-medium",
                  selectedMarketTab === "forex"
                    ? "bg-white text-black hover:bg-white hover:text-black"
                    : "text-gray-400 hover:text-white hover:bg-[#1A1A1A]/50",
                )}
                onClick={() => setSelectedMarketTab("forex")}
              >
                <span className="text-lg">💱</span>
                <span>Forex</span>
              </Button>

              <Button
                variant={selectedMarketTab === "crypto" ? "default" : "ghost"}
                className={cn(
                  "flex items-center gap-1.5 rounded-md py-2 px-3 text-sm font-medium",
                  selectedMarketTab === "crypto"
                    ? "bg-white text-black hover:bg-white hover:text-black"
                    : "text-gray-400 hover:text-white hover:bg-[#1A1A1A]/50",
                )}
                onClick={() => setSelectedMarketTab("crypto")}
              >
                <span className="text-lg">💰</span>
                <span>Crypto</span>
              </Button>

              <Button
                variant={selectedMarketTab === "futures" ? "default" : "ghost"}
                className={cn(
                  "flex items-center gap-1.5 rounded-md py-2 px-3 text-sm font-medium",
                  selectedMarketTab === "futures"
                    ? "bg-white text-black hover:bg-white hover:text-black"
                    : "text-gray-400 hover:text-white hover:bg-[#1A1A1A]/50",
                )}
                onClick={() => setSelectedMarketTab("futures")}
              >
                <span className="text-lg">🔮</span>
                <span>Futures</span>
              </Button>
            </div>
          </div>
        </div>

        {/* Selected assets display with counter */}
        {selectedAssets.length > 0 && (
          <div className="mb-4 sm:mb-6">
            <div className="flex justify-between items-center mb-2 sm:mb-3">
              <h3 className="text-white font-medium text-sm">
                Selected Assets
              </h3>
              <span
                className={cn(
                  "text-xs",
                  selectedAssets.length >= 6
                    ? "text-purple-500 font-medium"
                    : "text-gray-400",
                )}
              >
                {selectedAssets.length}/6 selected
                {selectedAssets.length >= 6 && " (max)"}
              </span>
            </div>
            <div className="flex flex-wrap gap-1.5 sm:gap-2">
              {selectedAssets.map((asset) => (
                <Badge
                  key={asset}
                  className={cn(
                    "bg-[#1A1A1A] text-white py-1 sm:py-1.5 px-2 sm:px-3 rounded-full",
                    "border border-purple-600/30 hover:border-purple-600/70",
                    "flex items-center gap-1 sm:gap-1.5 transition-colors",
                  )}
                  onClick={() => handleAssetSelect(asset)}
                >
                  <span className="font-medium text-[10px] sm:text-xs">
                    {asset}
                  </span>
                  <div className="rounded-full bg-purple-900/30 p-0.5 hover:bg-purple-900/50">
                    <X className="h-2.5 w-2.5 sm:h-3 sm:w-3 text-purple-400" />
                  </div>
                </Badge>
              ))}
            </div>
          </div>
        )}

        {/* Asset cards grid - improved for mobile */}
        <div className="grid grid-cols-3 sm:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-2 md:gap-3">
          {getAssetOptionsForTab(selectedMarketTab).map((asset) => (
            <AssetCard
              key={asset.value}
              symbol={asset.value}
              name={asset.label}
              isSelected={selectedAssets.includes(asset.value)}
              onClick={() => handleAssetSelect(asset.value)}
              disabled={
                selectedAssets.length >= 6 &&
                !selectedAssets.includes(asset.value)
              }
            />
          ))}
        </div>
      </div>

      <div className="mt-8 flex justify-center">
        <ContinueButton
          onClick={() => {
            fetchTechnicalSentiment(selectedAssets)
            setStep(3)
          }}
          disabled={selectedAssets.length === 0}
        />
      </div>
    </StepContainer>
  )

  // Step 3: Dashboard Selection
  const renderDashboardSelection = () => (
    console.log(selectedAssets),
    (
      <StepContainer
        title="Choose your primary dashboard"
        subtitle="Select which dashboard you'd like to see first when you log in"
        onBack={() => setStep(2)}
      >
        <div className="grid grid-cols-2 gap-3 mt-4">
          <motion.button
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.99 }}
            onClick={() => handleDashboardSelect("equities")}
            className={cn(
              "p-4 rounded-xl border transition-all duration-200",
              "bg-[#0A0A0A]/80 backdrop-blur-sm",
              selectedDashboard === "equities"
                ? "border-purple-600 bg-[#1A1A1A] shadow-[0_0_15px_rgba(147,51,234,0.1)]"
                : "border-[#2A2A2A] hover:border-purple-600/50 hover:bg-[#1A1A1A]",
            )}
          >
            <div className="flex flex-col items-center text-center space-y-2">
              <span className="text-2xl sm:text-3xl">📈</span>
              <h3 className="text-sm sm:text-xl font-medium text-white">
                Equities Dashboard
              </h3>
            </div>
          </motion.button>

          <motion.button
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.99 }}
            onClick={() => handleDashboardSelect("dashboard")}
            className={cn(
              "p-4 rounded-xl border transition-all duration-200",
              "bg-[#0A0A0A]/80 backdrop-blur-sm",
              selectedDashboard === "dashboard"
                ? "border-purple-600 bg-[#1A1A1A] shadow-[0_0_15px_rgba(147,51,234,0.1)]"
                : "border-[#2A2A2A] hover:border-purple-600/50 hover:bg-[#1A1A1A]",
            )}
          >
            <div className="flex flex-col items-center text-center space-y-2">
              <span className="text-2xl sm:text-3xl">🌐</span>
              <h3 className="text-sm sm:text-xl font-medium text-white">
                Currencies Dashboard
              </h3>
            </div>
          </motion.button>
        </div>

        <div className="mt-8 flex justify-center">
          <ContinueButton
            onClick={() => setStep(4)}
            disabled={!selectedDashboard}
          />
        </div>
      </StepContainer>
    )
  )

  // Step 4: Trading Experience
  const renderExperienceSelection = () => (
    <StepContainer
      title="What's your trading experience?"
      onBack={() => setStep(3)}
    >
      <div className="max-w-2xl mx-auto">
        <div className="grid grid-cols-1 gap-4">
          {experienceYears.map((option) => (
            <motion.button
              key={option.value}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => handleExperienceSelect(option.value)}
              className={cn(
                "p-6 rounded-lg flex items-center justify-between border transition-all duration-200",
                "bg-[#0A0A0A]/80 backdrop-blur-sm",
                userProfile.tradingYears === option.value
                  ? "border-purple-600 bg-[#1A1A1A] shadow-[0_0_15px_rgba(147,51,234,0.1)]"
                  : "border-[#2A2A2A] hover:border-purple-600/50 hover:bg-[#1A1A1A]",
              )}
            >
              <div className="flex items-center space-x-4">
                <span className="text-2xl">{option.icon}</span>
                <div className="text-left">
                  <div className="text-white font-medium">{option.label}</div>
                  <div className="text-gray-400 text-sm">
                    {option.description}
                  </div>
                </div>
              </div>
              <ChevronRight className="h-5 w-5 text-gray-500" />
            </motion.button>
          ))}
        </div>

        <div className="mt-8 flex justify-center">
          <ContinueButton
            onClick={() => setStep(5)}
            disabled={!userProfile.tradingYears}
          />
        </div>
      </div>
    </StepContainer>
  )

  // Step 5: Name Input
  const renderNameInput = () => (
    <StepContainer
      title="What's your name?"
      subtitle="Let us personalize your experience"
      onBack={() => setStep(4)}
    >
      <div className="max-w-md mx-auto">
        <Input
          type="text"
          placeholder="Enter your name"
          value={userProfile.name}
          onChange={(e) => {
            setUserProfile({ ...userProfile, name: e.target.value })
            // Track name input
            posthog.capture("onboarding_name_entered", {
              name_length: e.target.value.length,
              user: user?.email,
              timestamp: new Date().toISOString(),
            })
          }}
          className="bg-[#0A0A0A] border-[#2A2A2A] text-white h-12 px-4 text-lg rounded-lg w-full mb-4"
        />
        <div className="mt-8 flex justify-center">
          <ContinueButton
            onClick={() => setStep(6)}
            disabled={!userProfile.name.trim()}
          />
        </div>
      </div>
    </StepContainer>
  )

  // Step 6: Referral Source
  const renderReferralSource = () => (
    <StepContainer
      title="How did you hear about us?"
      subtitle="Help us understand how you found MRKT"
      onBack={() => setStep(5)}
    >
      <div className="grid grid-cols-2 gap-3">
        {referralOptions.map((option) => (
          <motion.button
            key={option.id}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => {
              setUserProfile({ ...userProfile, referralSource: option.id })
              // Track referral source selection
              posthog.capture("onboarding_referral_selected", {
                referral_source: option.id,
                user: user?.email,
                timestamp: new Date().toISOString(),
              })
            }}
            className={cn(
              "p-4 rounded-lg flex items-center space-x-3 border transition-all duration-200",
              "bg-[#0A0A0A]/80 backdrop-blur-sm",
              userProfile.referralSource === option.id
                ? "border-purple-600 bg-[#1A1A1A] shadow-[0_0_15px_rgba(147,51,234,0.1)]"
                : "border-[#2A2A2A] hover:border-purple-600/50 hover:bg-[#1A1A1A]",
            )}
          >
            <span className="text-lg">{option.icon}</span>
            <span className="text-white text-sm">{option.label}</span>
          </motion.button>
        ))}
      </div>

      <div className="mt-8 flex justify-center">
        <ContinueButton
          onClick={() => setStep(7)}
          disabled={!userProfile.referralSource}
        />
      </div>
    </StepContainer>
  )

  // Step 7: Goals Selection
  const renderGoalsSelection = () => (
    <StepContainer
      title="What are you looking to get out of MRKT?"
      subtitle="Select all that apply"
      onBack={() => setStep(6)}
    >
      <div className="grid grid-cols-2 gap-3">
        {mrktGoals.map((goal) => (
          <motion.button
            key={goal.id}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => {
              const isSelected = userProfile.goals.includes(goal.id)
              setUserProfile({
                ...userProfile,
                goals: isSelected
                  ? userProfile.goals.filter((g) => g !== goal.id)
                  : [...userProfile.goals, goal.id],
              })
              // Track goal selection/deselection
              posthog.capture("onboarding_goal_selected", {
                goal_id: goal.id,
                action: isSelected ? "deselected" : "selected",
                user: user?.email,
                timestamp: new Date().toISOString(),
              })
            }}
            className={cn(
              "p-3 sm:p-4 rounded-lg flex items-center justify-between border transition-all duration-200",
              "bg-[#0A0A0A]/80 backdrop-blur-sm",
              userProfile.goals.includes(goal.id)
                ? "border-purple-600 bg-[#1A1A1A] shadow-[0_0_15px_rgba(147,51,234,0.1)]"
                : "border-[#2A2A2A] hover:border-purple-600/50 hover:bg-[#1A1A1A]",
            )}
          >
            <div className="flex items-center space-x-2 p-2">
              <div
                className={cn(
                  "rounded-lg transition-colors",
                  userProfile.goals.includes(goal.id)
                    ? {
                        news: "bg-blue-600/20 text-blue-400",
                        trade_setups: "bg-green-600/20 text-green-400",
                        macro_understanding: "bg-purple-600/20 text-purple-400",
                        sentiment: "bg-yellow-600/20 text-yellow-400",
                        calendar: "bg-red-600/20 text-red-400",
                        research: "bg-cyan-600/20 text-cyan-400",
                        analysis: "bg-indigo-600/20 text-indigo-400",
                      }[goal.id]
                    : {
                        news: "bg-blue-900/20 text-blue-500",
                        trade_setups: "bg-green-900/20 text-green-500",
                        macro_understanding: "bg-purple-900/20 text-purple-500",
                        sentiment: "bg-yellow-900/20 text-yellow-500",
                        calendar: "bg-red-900/20 text-red-500",
                        research: "bg-cyan-900/20 text-cyan-500",
                        analysis: "bg-indigo-900/20 text-indigo-500",
                      }[goal.id],
                )}
              >
                <goal.icon className="w-5 h-5" />
              </div>
              <div className="text-white text-sm">{goal.label}</div>
            </div>
            {userProfile.goals.includes(goal.id) && (
              <Check className="h-5 w-5 text-purple-500 flex-shrink-0" />
            )}
          </motion.button>
        ))}
      </div>

      <div className="mt-8 flex justify-center">
        <ContinueButton
          onClick={() => setStep(8)}
          disabled={userProfile.goals.length === 0}
        />
      </div>
    </StepContainer>
  )

  // First, remove the pricing step and add completion step content
  const renderCompletion = () => (
    <StepContainer
      title="Welcome to MRKT!"
      subtitle="Let us show you what MRKT can do for your trading"
    >
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6 max-w-4xl mx-auto px-2 sm:px-4 md:px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="p-3 sm:p-4 md:p-6 rounded-lg md:rounded-xl border border-[#2A2A2A] bg-[#141414]"
        >
          <div className="flex flex-col items-center text-center space-y-2 sm:space-y-3 md:space-y-4">
            <span className="text-2xl sm:text-3xl md:text-4xl">🎯</span>
            <h3 className="text-sm sm:text-base md:text-lg font-medium text-white">
              Trade Smarter With AI
            </h3>
            <p className="text-[11px] sm:text-xs md:text-sm text-gray-400 max-w-[260px] md:max-w-none">
              Uncover trade opportunities with AI-driven insights tailored to
              your strategy.
            </p>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="p-3 sm:p-4 md:p-6 rounded-lg md:rounded-xl border border-[#2A2A2A] bg-[#141414]"
        >
          <div className="flex flex-col items-center text-center space-y-2 sm:space-y-3 md:space-y-4">
            <span className="text-2xl sm:text-3xl md:text-4xl">📊</span>
            <h3 className="text-sm sm:text-base md:text-lg font-medium text-white">
              Track Global Markets
            </h3>
            <p className="text-[11px] sm:text-xs md:text-sm text-gray-400 max-w-[260px] md:max-w-none">
              Track macro trends, analyze market moves, and monitor key economic
              events in one place.
            </p>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="p-3 sm:p-4 md:p-6 rounded-lg md:rounded-xl border border-[#2A2A2A] bg-[#141414]"
        >
          <div className="flex flex-col items-center text-center space-y-2 sm:space-y-3 md:space-y-4">
            <span className="text-2xl sm:text-3xl md:text-4xl">💡</span>
            <h3 className="text-sm sm:text-base md:text-lg font-medium text-white">
              Simplify the Chaos
            </h3>
            <p className="text-[11px] sm:text-xs md:text-sm text-gray-400 max-w-[260px] md:max-w-none">
              Stay ahead with instant sentiment tracking, news analysis, and
              high-impact alerts.
            </p>
          </div>
        </motion.div>
      </div>

      <div className="mt-4 sm:mt-6 md:mt-8 flex justify-center px-2 sm:px-4 md:px-0">
        <ContinueButton onClick={completeUserOnboarding}>
          Access your dashboard
        </ContinueButton>
      </div>
    </StepContainer>
  )

  // Update the renderCurrentStep function
  const renderCurrentStep = () => {
    switch (step) {
      case 1:
        return renderMarketSelection()
      case 2:
        return renderAssetSelection()
      case 3:
        return renderDashboardSelection()
      case 4:
        return renderExperienceSelection()
      case 5:
        return renderNameInput()
      case 6:
        return renderReferralSource()
      case 7:
        return renderGoalsSelection()
      case 8:
        return renderCompletion()
      default:
        return null
    }
  }

  // Add this inside the OnboardingScreen component
  const searchInputRef = useRef<HTMLInputElement>(null)

  return (
    <div className="min-h-screen bg-black relative">
      <ProgressBar currentStep={step} />
      <div className="min-h-screen flex items-center justify-center">
        <div className="w-full flex items-center justify-center py-8 sm:py-16">
          {renderCurrentStep()}
        </div>
      </div>
    </div>
  )
}

// Update the asset selection tab content container
const AssetCard = ({
  symbol,
  name,
  isSelected,
  onClick,
  disabled,
}: {
  symbol: string
  name: string
  isSelected: boolean
  onClick: () => void
  disabled: boolean
}) => {
  return (
    <motion.button
      whileHover={{ scale: disabled ? 1 : 1.01 }}
      whileTap={{ scale: disabled ? 1 : 0.99 }}
      onClick={onClick}
      disabled={disabled && !isSelected}
      className={cn(
        "p-1.5 sm:p-2 md:p-3 rounded-lg flex flex-col items-start justify-between border transition-all duration-200 h-[60px] sm:h-[75px] md:h-[90px]",
        "bg-[#0A0A0A]/80 backdrop-blur-sm text-left",
        isSelected
          ? "border-purple-600 bg-[#1A1A1A] shadow-[0_0_15px_rgba(147,51,234,0.1)]"
          : disabled
            ? "border-[#2A2A2A] opacity-50 cursor-not-allowed"
            : "border-[#2A2A2A] hover:border-purple-600/50 hover:bg-[#1A1A1A]",
      )}
    >
      <div className="flex flex-col h-full justify-between w-full">
        <div className="overflow-hidden">
          <h3 className="text-white font-bold text-sm sm:text-sm md:text-base truncate">
            {symbol}
          </h3>
          <p className="text-gray-400 text-[10px] sm:text-sm mt-0.5 truncate">
            {name}
          </p>
        </div>

        {isSelected && (
          <div className="self-end">
            <Check className="h-2.5 w-2.5 sm:h-3.5 sm:w-3.5 md:h-4 md:w-4 text-purple-500" />
          </div>
        )}
      </div>
    </motion.button>
  )
}
