import { useState, useRef, useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { useDebounce } from "@/hooks/useDebounce"
import { useUser } from "@/providers"
import { useUpdateUser } from "@/hooks/useUpdateUser"
import { toast } from "@/hooks/use-toast"
import { motion, AnimatePresence } from "framer-motion"
import { X, Search, Check, Loader2 } from "lucide-react"
import { Input } from "@/components/ui/input"
import { cn } from "@/lib/utils"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"

interface SearchSymbolResult {
  symbol: string
  name: string
  type: string
}

interface SearchSymbolsResponse {
  success: boolean
  data: {
    stock: SearchSymbolResult[]
    crypto: SearchSymbolResult[]
    forex: SearchSymbolResult[]
    commodity: SearchSymbolResult[]
    index: SearchSymbolResult[]
  }
}

interface AssetSelectorProps {
  isOpen: boolean
  onClose: () => void
  assetType: "forex" | "equities"
  onAssetSelected?: (symbol: string) => void
  currentIndex?: number
}

export const AssetSelector = ({
  isOpen,
  onClose,
  assetType,
  onAssetSelected,
  currentIndex,
}: AssetSelectorProps) => {
  const { userData } = useUser()
  const { updateUser } = useUpdateUser()
  const { getAccessTokenSilently, user } = useAuth0()
  const [searchQuery, setSearchQuery] = useState("")
  const debouncedSearchQuery = useDebounce(searchQuery, 500)
  const searchInputRef = useRef<HTMLInputElement>(null)
  const [selectedAssets, setSelectedAssets] = useState<string[]>([])

  // Initialize selected assets from user data
  useEffect(() => {
    if (userData?.tradingInfo?.specificAssets) {
      setSelectedAssets(userData.tradingInfo.specificAssets[assetType] || [])
    }
  }, [userData, assetType])

  // Focus the search input when the component opens
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current?.focus()
      }, 100)
    }
  }, [isOpen])

  // Search for symbols
  const {
    data: searchResults,
    isLoading: isSearching,
    error,
  } = useQuery({
    queryKey: ["symbolSearch", debouncedSearchQuery],
    queryFn: async () => {
      try {
        const token = await getAccessTokenSilently()
        if (!debouncedSearchQuery || debouncedSearchQuery.length < 2) {
          return null
        }

        const url = `${import.meta.env.VITE_MRKT_SERVER}/mrkt-ai/search-symbols?query=${encodeURIComponent(debouncedSearchQuery)}`

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })

        if (!response.ok) {
          const errorText = await response.text()
          throw new Error(
            `Failed to fetch search results: ${response.status} ${errorText}`,
          )
        }

        const data = await response.json()
        return data as SearchSymbolsResponse
      } catch (err) {
        console.error("Symbol search error:", err)
        throw err
      }
    },
    enabled: debouncedSearchQuery.length >= 2,
    staleTime: 1000 * 60 * 5, // Cache results for 5 minutes
    refetchOnWindowFocus: false,
    retry: 1,
  })

  // Handle asset selection
  const handleAssetSelect = async (symbol: string) => {
    if (!user?.email) return

    try {
      // Create a copy of the current assets
      const newAssets = [...selectedAssets]

      // If we have a current index, replace that specific asset
      if (currentIndex !== undefined) {
        newAssets[currentIndex] = symbol
      } else {
        // Otherwise just add it if not already present
        if (!newAssets.includes(symbol)) {
          newAssets.push(symbol)
        }
      }

      // Update the user's assets
      await updateUser({
        email: user.email,
        updates: {
          tradingInfo: {
            ...userData?.tradingInfo,
            specificAssets: {
              ...userData?.tradingInfo?.specificAssets,
              [assetType]: newAssets,
            },
          },
        },
      })

      // Notify the parent component
      if (onAssetSelected) {
        onAssetSelected(symbol)
      }

      // Show success toast
      toast({
        description: `${symbol} has been added to your dashboard`,
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })

      // Close the selector
      onClose()
    } catch (error) {
      console.error("Error updating assets:", error)
      toast({
        title: "Error",
        description: "Failed to update your assets",
        variant: "destructive",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    }
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/40 backdrop-blur-sm"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="relative w-full max-w-md rounded-xl bg-[#111] border border-[#333] backdrop-blur-2xl bg-black/55 shadow-2xl"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center p-4 border-b border-[#333]">
              <h2 className="text-lg font-medium text-white">
                {currentIndex !== undefined
                  ? "Replace Asset"
                  : "Add Asset to Dashboard"}
              </h2>
              <button
                onClick={onClose}
                className="text-white/70 hover:text-white"
              >
                <X className="h-4 w-4" />
              </button>
            </div>

            <div className="p-4">
              <div className="relative w-full mb-4">
                <Input
                  ref={searchInputRef}
                  type="text"
                  placeholder="Search for assets..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="bg-[#0A0A0A] border-[#2A2A2A] text-white h-10 pl-9 pr-3 text-sm rounded-md w-full"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
                {isSearching && (
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                    <Loader2 className="h-4 w-4 text-gray-400 animate-spin" />
                  </div>
                )}
              </div>

              <div className="mt-2 h-[300px] overflow-hidden">
                <SimpleBar style={{ maxHeight: "100%" }}>
                  {debouncedSearchQuery.length < 2 ? (
                    <div className="text-center py-8 text-gray-400">
                      Type at least 2 characters to search
                    </div>
                  ) : isSearching ? (
                    <div className="flex justify-center py-8">
                      <Loader2 className="h-6 w-6 text-purple-500 animate-spin" />
                    </div>
                  ) : error ? (
                    <div className="text-center py-8 text-red-400">
                      Error fetching results. Please try again.
                    </div>
                  ) : searchResults?.success &&
                    searchResults.data &&
                    Object.values(searchResults.data).some(
                      (arr) => arr.length > 0,
                    ) ? (
                    <div className="space-y-4">
                      {Object.entries(searchResults.data).map(
                        ([category, results]) => {
                          if (!results || results.length === 0) return null

                          return (
                            <div key={category} className="space-y-1">
                              <h3 className="text-xs font-semibold text-gray-400 uppercase px-2 py-1">
                                {category}
                              </h3>
                              <div className="space-y-0.5">
                                {results.map((result: SearchSymbolResult) => (
                                  <button
                                    key={result.symbol}
                                    onClick={() =>
                                      handleAssetSelect(result.symbol)
                                    }
                                    className={cn(
                                      "w-full flex items-center justify-between py-2 px-3 rounded-md text-sm",
                                      "hover:bg-[#1A1A1A] transition-colors text-left",
                                      selectedAssets.includes(result.symbol)
                                        ? "bg-[#1A1A1A] border-l-2 border-purple-500"
                                        : "border-l-2 border-transparent",
                                    )}
                                  >
                                    <div className="flex flex-col">
                                      <span className="font-medium text-white">
                                        {result.symbol}
                                      </span>
                                      <span className="text-xs text-gray-400 truncate max-w-[280px]">
                                        {result.name}
                                      </span>
                                    </div>
                                    {selectedAssets.includes(result.symbol) && (
                                      <Check className="h-4 w-4 text-purple-500 flex-shrink-0" />
                                    )}
                                  </button>
                                ))}
                              </div>
                            </div>
                          )
                        },
                      )}
                    </div>
                  ) : (
                    <div className="py-8 text-center text-gray-400">
                      No results found
                    </div>
                  )}
                </SimpleBar>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
