import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import {
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  YAxis,
  ReferenceLine,
  Area,
  XAxis,
} from "recharts"
import { format } from "date-fns"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

interface IntradayLevelsChartProps {
  symbol: string
  levels: number[]
}

interface ChartDataPoint {
  date: number
  close: number
}

export function IntradayLevelsChart({
  symbol,
  levels,
}: IntradayLevelsChartProps) {
  const { getAccessTokenSilently } = useAuth0()

  const { data: assetPriceData, isLoading } = useQuery({
    queryKey: ["intradayPrice", symbol],
    retry: false,
    queryFn: async () => {
      try {
        const token = await getAccessTokenSilently()
        const now = new Date()
        const weekAgo = new Date(now.getTime())
        weekAgo.setDate(weekAgo.getDate() - 3)

        const response = await fetch(
          `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/15min?from=${format(weekAgo, "yyyy-MM-dd")}&to=${format(now, "yyyy-MM-dd")}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        if (!response.ok) {
          throw new Error("Failed to fetch price data")
        }
        const data = await response.json()
        if (!data?.length) {
          throw new Error("No intraday price data available")
        }
        return data.sort(
          (a: any, b: any) =>
            new Date(a.date).getTime() - new Date(b.date).getTime(),
        )
      } catch (error) {
        console.error("Error fetching intraday price data:", error)
        throw error
      }
    },
  })

  if (isLoading || !assetPriceData?.length) {
    return (
      <div className="h-[400px] rounded-lg">
        <Skeleton
          height="100%"
          baseColor="rgba(255, 255, 255, 0.05)"
          highlightColor="rgba(255, 255, 255, 0.1)"
        />
      </div>
    )
  }

  try {
    const chartData = assetPriceData.map(
      (d: any): ChartDataPoint => ({
        date: new Date(d.date).getTime(),
        close: d.close,
      }),
    )

    const sortedLevels = [...levels].sort((a, b) => a - b)

    return (
      <div className="h-[400px] w-full relative rounded-lg">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={chartData}
            margin={{ top: -40, right: 20, left: 0, bottom: 10 }}
          >
            <defs>
              <linearGradient id="chartGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#ffffff" stopOpacity={0.1} />
                <stop offset="100%" stopColor="#ffffff" stopOpacity={0.02} />
              </linearGradient>
            </defs>

            <XAxis
              dataKey="date"
              type="category"
              scale="band"
              tickFormatter={(timestamp) =>
                format(new Date(timestamp), "MMM d HH:mm")
              }
              minTickGap={50}
              interval="preserveStartEnd"
              stroke="transparent"
              tick={{ fill: "rgba(255, 255, 255, 0.4)", fontSize: 10 }}
              axisLine={false}
              tickLine={false}
              padding={{ left: 0, right: 0 }}
            />

            <YAxis
              domain={[
                (dataMin: number) => Math.min(dataMin * 0.995, sortedLevels[0]),
                (dataMax: number) =>
                  Math.max(
                    dataMax * 1.005,
                    sortedLevels[sortedLevels.length - 1],
                  ),
              ]}
              tick={false}
              width={35}
              stroke="transparent"
              axisLine={false}
              tickLine={false}
              orientation="right"
              padding={{ top: 0, bottom: 0 }}
            />

            {/* Price Line */}
            <Area
              type="monotone"
              dataKey="close"
              stroke="rgba(255, 255, 255, 0.7)"
              strokeWidth={0.75}
              fill="rgba(112, 20, 218, 0)"
              fillOpacity={0.4}
              isAnimationActive={false}
              dot={false}
              connectNulls={false}
              activeDot={{
                r: 3,
                fill: "#fff",
                stroke: "rgba(255, 255, 255, 0.2)",
                strokeWidth: 4,
              }}
            />

            {/* Key Levels */}
            {sortedLevels.map((level, index) => (
              <ReferenceLine
                key={`level-${index}`}
                y={level}
                stroke="rgba(163, 159, 155, 0.58)"
                strokeWidth={1}
                label={{
                  value: `${level}`,
                  position: "right",
                  fill: "rgba(255, 255, 255, 0.8)",
                  fontSize: 11,
                  fontWeight: 500,
                  dx: 2,
                }}
              />
            ))}

            <Tooltip
              content={({ active, payload }) => {
                if (!active || !payload?.length) return null

                const data = payload[0].payload
                return (
                  <div className="bg-black/90 backdrop-blur-md px-3 py-2 rounded-md border border-white/10">
                    <div className="text-white font-medium text-sm">
                      ${data.close?.toFixed(2)}
                    </div>
                    <div className="text-white/50 text-xs">
                      {format(new Date(data.date), "MMM d, HH:mm")}
                    </div>
                  </div>
                )
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  } catch (error) {
    console.error("Error rendering chart:", error)
    return (
      <div className="h-[200px] bg-white/5 rounded-lg flex items-center justify-center text-white/50 text-sm">
        Unable to display chart
      </div>
    )
  }
}
