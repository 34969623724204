import { useQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { useNavigate } from "@tanstack/react-router"

interface ActiveStock {
  symbol: string
  name: string
  change: number
  price: number
  changesPercentage: number
}

export function ActiveStocks() {
  const { getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()

  const { data, isLoading } = useQuery({
    queryKey: ["activeStocks"],
    queryFn: async () => {
      const url = `${import.meta.env.VITE_MRKT_SERVER}/equities/actives`
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      })

      if (!response.ok) {
        throw new Error("Failed to fetch active stocks data")
      }
      return response.json()
    },
    refetchOnWindowFocus: false,
    staleTime: 30000,
    retry: 1,
  })

  if (isLoading) {
    return (
      <Card className="h-full w-full bg-inherit border-none p-0">
        <CardHeader className="p-2">
          <CardTitle className="text-lg font-medium text-white">
            <Skeleton
              width={150}
              height={24}
              baseColor="#222"
              highlightColor="#333"
            />
          </CardTitle>
        </CardHeader>
        <CardContent className="p-4">
          {[...Array(10)].map((_, i) => (
            <Skeleton
              key={i}
              height={48}
              className="mb-2"
              baseColor="#222"
              highlightColor="#333"
            />
          ))}
        </CardContent>
      </Card>
    )
  }

  const topStocks = data?.slice(0, 10) || []

  return (
    <Card className="h-full w-full bg-inherit border-none p-0">
      <CardHeader className="p-2">
        <h2 className="text-white text-sm font-medium mb-2">Most Active</h2>
      </CardHeader>
      <CardContent className="p-0 h-[calc(100%-3rem)]">
        <SimpleBar className="h-full">
          <div className="space-y-2">
            {topStocks.map((stock: ActiveStock) => (
              <div
                key={stock.symbol}
                onClick={() =>
                  navigate({
                    to: "/equities/symbol/$symbol",
                    params: { symbol: stock.symbol },
                  })
                }
                className="group block cursor-pointer"
              >
                <div
                  className="relative flex gap-4 p-3 rounded-lg
                    border border-white/5 
                    bg-black/20
                    hover:bg-black/30
                    hover:border-white/10
                    transition-all duration-200"
                >
                  <div className="w-10 h-10 flex-shrink-0">
                    <div
                      className="w-full h-full rounded-lg bg-black/30
                        flex items-center justify-center overflow-hidden
                        group-hover:border-white/10
                        transition-all duration-200"
                    >
                      <img
                        src={`https://financialmodelingprep.com/image-stock/${stock.symbol}.png`}
                        alt={`${stock.symbol} logo`}
                        className="w-full h-full object-contain p-2"
                        onError={(e) => {
                          const target = e.target as HTMLImageElement
                          const container = target.parentElement
                          if (container) {
                            container.innerHTML = ""
                            container.appendChild(
                              document.createTextNode(stock.symbol),
                            )
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex-1 min-w-0 flex justify-between items-center">
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-white/70">
                        {stock.symbol}
                      </span>
                      <span className="text-sm text-white/60 truncate max-w-[200px] group-hover:text-white/80 transition-colors duration-200">
                        {stock.name}
                      </span>
                    </div>
                    <div className="flex flex-col items-end">
                      <span className="text-sm font-medium text-white/70">
                        ${stock.price.toFixed(2)}
                      </span>
                      <span
                        className={`text-sm font-medium ${
                          stock.changesPercentage >= 0
                            ? "text-emerald-500/90"
                            : "text-red-500/90"
                        }`}
                      >
                        {stock.changesPercentage >= 0 ? "+" : ""}
                        {stock.changesPercentage.toFixed(2)}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </SimpleBar>
      </CardContent>
    </Card>
  )
}
