import { createFileRoute, Outlet, useNavigate } from "@tanstack/react-router"
import { Navbar } from "@/components"
import { useUser } from "@/providers/UserProvider"
import { LoadingScreen } from "@/providers"
import { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"

export const Route = createFileRoute("/dashboard")({
  validateSearch: (search: Record<string, unknown>) => {
    const plan = search.plan as string | undefined
    if (plan && !["monthly", "annual"].includes(plan)) {
      return { plan: undefined }
    }
    return { plan: plan as "monthly" | "annual" | undefined }
  },
  component: () => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
    const { plan } = Route.useSearch()
    const { isSubscribed, isLoading: isUserLoading, userData } = useUser()
    const navigate = useNavigate()

    useEffect(() => {
      console.log("[Dashboard Route] State:", {
        isLoading,
        isAuthenticated,
        isUserLoading,
        isSubscribed,
        plan,
        hasOnboarded: userData?.hasOnboarded,
      })

      if (!isLoading && !isUserLoading) {
        if (!isAuthenticated) {
          console.log(
            "[Dashboard Route] User not authenticated, redirecting to login",
          )
          loginWithRedirect({
            appState: {
              returnTo: "/auth",
              plan,
            },
          })
          return
        }

        if (!isSubscribed) {
          console.log(
            "[Dashboard Route] User not subscribed, redirecting to checkout",
          )
          navigate({ to: "/checkout" })
          return
        }

        if (!userData?.hasOnboarded) {
          console.log(
            "[Dashboard Route] User not onboarded, redirecting to onboarding",
          )
          navigate({ to: "/onboarding" })
          return
        }
      }
    }, [
      isLoading,
      isAuthenticated,
      isUserLoading,
      isSubscribed,
      userData?.hasOnboarded,
      plan,
      navigate,
      loginWithRedirect,
    ])

    // Show LoadingScreen while checking auth status
    if (isLoading || isUserLoading) {
      return <LoadingScreen />
    }

    return <DashboardLayout />
  },
})

function DashboardLayout() {
  const { userData, isLoading } = useUser()

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <div className="bg-black min-h-screen">
      <Navbar />
      <main className="py-6">
        <div className="px-12 sm:px-6 lg:px-12 mx-auto">
          <Outlet />
        </div>
      </main>
    </div>
  )
}
