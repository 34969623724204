import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { TooltipIcon } from "@/components/ui/tooltip_icon"
import { useAuth0 } from "@auth0/auth0-react"
import { Dialog, Tab } from "@headlessui/react"
import { useQuery } from "@tanstack/react-query"
import clsx from "clsx"
import { format, parseISO } from "date-fns"
import { Brain, Clock } from "lucide-react"
import { useEffect, useMemo, useState } from "react"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { EventDialogContent } from "./EventDialogContent"
import { FedSpeakerInfo, getEventAnalysis, getEventSummary } from "./utils"
import Skeleton from "react-loading-skeleton"
import { getCountryFlag } from "../EconomicCalendar/EconomicCalendar"
import { useUser } from "@/providers"
import { usePostHog } from "posthog-js/react"

export interface CentralBankEvent {
  id: string
  dateUtc: string
  name: string
  currencyCode: string
  volatility: "HIGH" | "MEDIUM" | "LOW" | "NONE"
  isSpeech: boolean
  isReport: boolean
}

const impactColors = {
  LOW: "bg-yellow-500",
  MEDIUM: "bg-orange-500",
  HIGH: "bg-red-500",
  NONE: "bg-gray-500",
}

//TODO - add ECB
const centralBanks = [
  { name: "FED", value: "US" },
  { name: "BOE", value: "UK" },
  { name: "BOJ", value: "JP" },
  // { name: "ECB", value: "EU" },
  // { name: "BOC", value: "CA" },
  // { name: "RBA", value: "AU" },
]

const CENTRAL_BANK_EVENTS_TOOLTIP_TEXT =
  "Get analysis of upcoming central bank events before they happen in order to prepare for the market impact. Find out what the market expects and potential price movements on major asset classes."

const fetchCentralBankEvents = async (
  getAccessTokenSilently: () => Promise<string>,
  country: string = "US",
) => {
  const startDate = "2024-06-01"
  const endDate = "2025-03-30"

  const response = await fetch(
    `${import.meta.env.VITE_MRKT_SERVER}/calendar/central-bank-events?startDate=${startDate}&endDate=${endDate}&countries=${country}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    },
  )

  if (!response.ok) {
    throw new Error("Network response was not ok")
  }

  return response.json() as Promise<CentralBankEvent[]>
}

export const CentralBankEvents = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [country, setCountry] = useState("US")
  const {
    data: events,
    isLoading: isCentralBankEventsLoading,
    error,
    refetch,
  } = useQuery<CentralBankEvent[], Error>({
    queryKey: ["centralBankEvents", country],
    queryFn: () => fetchCentralBankEvents(getAccessTokenSilently, country),
    staleTime: Infinity, // The data never goes stale
    gcTime: Infinity, // Keep the data cached indefinitely
  })

  const [selectedEvent, setSelectedEvent] = useState<CentralBankEvent | null>(
    null,
  )
  const [eventInfo, setEventInfo] = useState<FedSpeakerInfo | null>(null)
  const [openAnalysisDialog, setOpenAnalysisDialog] = useState(false)
  const [isAnalysisLoading, setIsAnalysisLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState("upcoming")
  const { userData } = useUser()
  const posthog = usePostHog()

  const CentralBanksHeader = () => {
    return (
      <>
        <CardHeader className="p-2">
          <h2 className="text-white text-sm font-medium mb-2">
            Central Bank Events
          </h2>
        </CardHeader>
        <div className="px-2 pb-2 flex space-x-2 overflow-x-hidden">
          {centralBanks.map((bank) => (
            <button
              key={bank.name}
              onClick={() => setCountry(bank.value)}
              className={clsx(
                "flex items-center justify-center py-1 px-2 rounded-md text-sm font-medium transition-colors duration-200",
                country === bank.value
                  ? "bg-gray-600/50 text-primary-foreground"
                  : "bg-[#201f21] text-white hover:bg-gray-700",
              )}
            >
              <span className="mr-2">{getCountryFlag(bank.value)}</span>
              <span>{bank.name}</span>
            </button>
          ))}
        </div>
      </>
    )
  }

  useEffect(() => {
    refetch()
  }, [country])

  const { pastEvents, upcomingEvents } = useMemo(() => {
    const now = new Date()
    return {
      pastEvents:
        events?.filter((event) => new Date(event.dateUtc) < now) || [],
      upcomingEvents:
        events?.filter((event) => new Date(event.dateUtc) >= now) || [],
    }
  }, [events])

  const handleEventAnalysis = async (
    event: CentralBankEvent,
    centralBank: string,
    getAccessTokenSilently: () => Promise<string>,
  ) => {
    setIsAnalysisLoading(true)
    setSelectedEvent(event)
    setOpenAnalysisDialog(true)

    posthog.capture("central_bank_event_ai_analysis", {
      event_id: event.id,
      event_title: event.name,
      user: userData?.name,
    })
    const eventAnalysisResponse = await getEventAnalysis(
      event.name,
      centralBank,
      getAccessTokenSilently,
    )

    setIsAnalysisLoading(false)
    setEventInfo(eventAnalysisResponse as unknown as FedSpeakerInfo)
  }

  //TODO : Event Summary needs to be refactored to use different component
  const handleEventSummary = async (
    event: CentralBankEvent,
    getAccessTokenSilently: () => Promise<string>,
  ) => {
    setIsAnalysisLoading(true)
    setSelectedEvent(event)
    setOpenAnalysisDialog(true)

    const eventSummaryResponse = await getEventSummary(
      event.name,
      format(parseISO(event.dateUtc), "yyyy-MM-dd"),
      getAccessTokenSilently,
    )

    setIsAnalysisLoading(false)
    setEventInfo(eventSummaryResponse as unknown as FedSpeakerInfo)
  }

  if (isCentralBankEventsLoading) {
    return (
      <Card className="h-full w-full bg-inherit border-none p-0">
        <CentralBanksHeader />
        <CardContent className="p-0 h-full">
          <Skeleton
            count={4}
            className="h-[50px] rounded-md opacity-10 bg-gray-700 my-2"
          />
        </CardContent>
      </Card>
    )
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <Card className="h-full w-full bg-inherit border-none p-0">
      <CentralBanksHeader />
      <CardContent className="h-full">
        <Tab.Group
          selectedIndex={selectedTab === "upcoming" ? 0 : 1}
          onChange={(index) =>
            setSelectedTab(index === 0 ? "upcoming" : "past")
          }
        >
          <Tab.List className="flex space-x-4 mb-4">
            <Tab
              className={({ selected }) =>
                `px-3 py-1.5 text-xs rounded-lg transition-all duration-200 ${
                  selected
                    ? "bg-white/5 text-white shadow-lg shadow-white/5"
                    : "text-gray-500 hover:bg-white/5 hover:text-white"
                }`
              }
            >
              Upcoming
            </Tab>
            <Tab
              className={({ selected }) =>
                `px-3 py-1.5 text-xs rounded-lg transition-all duration-200 ${
                  selected
                    ? "bg-white/5 text-white shadow-lg shadow-white/5"
                    : "text-gray-500 hover:bg-white/5 hover:text-white"
                }`
              }
            >
              Past
            </Tab>
          </Tab.List>
          <SimpleBar style={{ maxHeight: "210px" }}>
            <Tab.Panels>
              <Tab.Panel>
                <CentralBankEventList
                  events={upcomingEvents}
                  handleEvent={(event) =>
                    handleEventAnalysis(event, country, getAccessTokenSilently)
                  }
                />
              </Tab.Panel>
              <Tab.Panel>
                {/* TODO - add back onclick handler */}
                <CentralBankEventList
                  events={pastEvents}
                  isPastEvents={true}
                  handleEvent={() => console.log("past event")}
                />
              </Tab.Panel>
            </Tab.Panels>
          </SimpleBar>
        </Tab.Group>
      </CardContent>
      {openAnalysisDialog && (
        <div className="fixed inset-y-0 right-0 w-[600px] border-l border-zinc-800/50 shadow-xl transform transition-transform duration-300 ease-in-out z-50">
          <EventDialogContent
            event={selectedEvent}
            eventInfo={eventInfo}
            isLoading={isAnalysisLoading}
            onClose={() => setOpenAnalysisDialog(false)}
            isPastEvent={selectedTab === "past"}
          />
        </div>
      )}
    </Card>
  )
}

type CentralBankEventListProps = {
  events: CentralBankEvent[]
  handleEvent: (event: CentralBankEvent) => void
  isPastEvents?: boolean
}

const CentralBankEventList = ({
  events,
  handleEvent,
  isPastEvents = false,
}: CentralBankEventListProps) => {
  return (
    <div className="space-y-1">
      {events.map((event) => (
        <div
          key={event.id}
          className="group relative flex items-center gap-3 rounded-lg p-2 transition-colors hover:bg-white/[0.03]"
        >
          <div className="flex min-w-0 flex-1 items-center gap-3">
            <div
              className={`h-10 w-[3px] flex-shrink-0 rounded-full ${
                impactColors[event.volatility]
              } opacity-80`}
            />

            <div className="flex-shrink-0 text-center">
              <div className="text-base font-semibold text-white">
                {format(new Date(event.dateUtc), "dd")}
              </div>
              <div className="text-xs text-zinc-500">
                {format(new Date(event.dateUtc), "MMM")}
              </div>
            </div>

            <div className="min-w-0 flex-1">
              <div className="truncate text-sm font-medium text-white">
                {event.name}
              </div>
              <div className="mt-1 flex items-center text-xs text-zinc-500">
                <Clock className="mr-1 h-3 w-3" />
                {format(new Date(event.dateUtc), "h:mm a")}
              </div>
            </div>

            {!isPastEvents && (
              <button
                onClick={() => handleEvent(event)}
                className="flex-shrink-0 rounded-md p-1.5 text-zinc-400 opacity-0 transition-opacity hover:bg-white/[0.03] hover:text-purple-400 group-hover:opacity-100"
              >
                <Brain className="h-5 w-5" />
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
