import { useEffect, useState } from "react"
import {
  Area,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { useQuery } from "@tanstack/react-query"
import { format } from "date-fns"
import { useAuth0 } from "@auth0/auth0-react"
import { cn } from "@/lib/utils"
import { Brain } from "lucide-react"
import {
  getSentimentColor,
  getSentimentText,
} from "@/presentation/screens/homescreen/components/MarketHeadlines/AIAnalysisPanel"
import posthog from "posthog-js"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

type TimeRange = "1D" | "1W" | "1M" | "3M" | "1Y" | "ALL"
const timeRanges: TimeRange[] = ["1D", "1W", "1M", "3M", "1Y", "ALL"]

interface DataPoint {
  date: number
  close: number
  symbol: string
  percentChange: number
}

interface SentimentAnalysis {
  trend: string
  reasoning: string
}

interface TimeframeAnalysis {
  hourly: SentimentAnalysis
  daily: SentimentAnalysis
  weekly: SentimentAnalysis
  intraday_levels: number[]
}

interface SentimentResponse {
  symbol: string
  data: {
    analysis: TimeframeAnalysis
    generatedAt: string
    symbol: string
    lastPrice: number
    sentimentAnalysis?: {
      explanation: string
      citations: string[]
    }
  }
}

interface MultiSymbolChartProps {
  symbols: string[]
  symbolLabels: Record<string, string>
  symbolColors?: Record<string, string>
  height?: number
  defaultVisibleSymbols?: string[]
  title?: string
  colors?: string[]
  selectedTimeRange?: TimeRange
  selectedDate?: Date
  defaultTimeRange?: TimeRange
  tickCount?: number
  showSentiment?: boolean
  sentimentSymbols?: Array<Record<string, string>>
  showMrktAI?: boolean
  onShowAnalysis?: (analysisData: {
    symbol: string
    analysis: string
    oneHourSentiment: string
    dailySentiment: string
    weeklySentiment: string
    oneHourReasoning: string
    dailyReasoning: string
    weeklyReasoning: string
    generatedAt: string
    intraday_levels: number[]
    querySymbol: string
  }) => void
}

type LongerTimeframes = "hourly" | "daily" | "weekly"

const ChartSkeleton = ({
  height = 400,
  title,
  symbols,
}: {
  height?: number
  title?: string
  symbols: string[]
}) => {
  return (
    <div className="flex flex-col h-full animate-pulse p-4">
      {title && <div className="mb-2 h-5 w-32 bg-white/5 rounded" />}

      {/* Time range buttons skeleton */}
      <div className="flex gap-2 mb-4">
        {timeRanges.map((range) => (
          <div key={range} className="h-7 w-12 bg-white/5 rounded" />
        ))}
      </div>

      {/* Chart area skeleton with gradient effect */}
      <div className="flex-grow relative overflow-hidden">
        <div
          className="w-full bg-gradient-to-b from-white/5 to-white/[0.02] rounded-lg"
          style={{ height }}
        >
          {/* Fake chart lines */}
          <div className="absolute inset-0 flex flex-col justify-between py-8">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="w-full h-px bg-white/5" />
            ))}
          </div>
        </div>
      </div>

      {/* Symbol buttons skeleton */}
      <div className="flex flex-wrap gap-3 mt-4">
        {symbols.map((symbol) => (
          <div key={symbol} className="h-7 w-24 bg-white/5 rounded" />
        ))}
      </div>
    </div>
  )
}

const getSentimentGradientClass = (sentiment: string) => {
  const lowerSentiment = sentiment.toLowerCase()
  if (lowerSentiment === "bullish" || lowerSentiment === "neutral_to_bullish") {
    return "bg-gradient-to-bl from-green-500/10 via-transparent to-transparent"
  }
  if (lowerSentiment === "bearish" || lowerSentiment === "neutral_to_bearish") {
    return "bg-gradient-to-bl from-red-500/10 via-transparent to-transparent"
  }
  return ""
}

export const MultiSymbolChart = ({
  symbols,
  symbolLabels,
  symbolColors = {},
  height = 400,
  defaultVisibleSymbols,
  title,
  colors,
  selectedTimeRange,
  selectedDate,
  defaultTimeRange,
  tickCount = 4,
  showSentiment = false,
  sentimentSymbols = [],
  showMrktAI = false,
  onShowAnalysis,
}: MultiSymbolChartProps) => {
  const [selectedRange, setSelectedRange] = useState<TimeRange>(
    selectedTimeRange ?? defaultTimeRange ?? "1W",
  )
  const { getAccessTokenSilently, user } = useAuth0()
  const [visibleSymbols, setVisibleSymbols] = useState<Set<string>>(
    new Set(defaultVisibleSymbols || symbols),
  )
  const [sentimentTimeframe, setSentimentTimeframe] =
    useState<LongerTimeframes>("daily")
  const [selectedSymbol, setSelectedSymbol] = useState<string>(symbols[0])
  const [selectedAISymbol, setSelectedAISymbol] = useState<string | null>(null)

  useEffect(() => {
    setVisibleSymbols(new Set(defaultVisibleSymbols || symbols))
  }, [symbols, defaultVisibleSymbols])

  const defaultColors: { [key: string]: string } = {
    SPY: "rgba(255, 255, 255, 0.7)",
    QQQ: "rgba(147, 197, 253, 0.7)",
    DIA: "rgba(167, 139, 250, 0.7)",
    IWM: "rgba(248, 113, 113, 0.7)",
    VIX: "rgba(251, 191, 36, 0.7)",
    ...symbolColors,
  }

  const toggleSymbol = (symbol: string) => {
    setVisibleSymbols((prev) => {
      const next = new Set(prev)
      if (next.has(symbol) && next.size > 1) {
        next.delete(symbol)
        setSelectedSymbol(Array.from(next)[0])
      } else if (!next.has(symbol)) {
        next.add(symbol)
        setSelectedSymbol(symbol)
      }
      return next
    })
  }

  const fetchPriceData = async (symbol: string) => {
    try {
      let endpoint = ""
      const now = selectedDate ? new Date(selectedDate) : new Date()

      switch (selectedRange) {
        case "1D":
          const threeDaysAgo = new Date(now.getTime() - 3 * 24 * 60 * 60 * 1000)
          endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/5min?from=${format(threeDaysAgo, "yyyy-MM-dd")}&to=${format(now, "yyyy-MM-dd")}`
          break
        case "1W":
          const weekAgo = new Date(now.getTime())
          weekAgo.setDate(weekAgo.getDate() - 7)
          endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/15min?from=${format(weekAgo, "yyyy-MM-dd")}&to=${format(now, "yyyy-MM-dd")}`
          break
        case "1M":
          const monthAgo = new Date(now.getTime())
          monthAgo.setMonth(monthAgo.getMonth() - 1)
          endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/1hour?from=${format(monthAgo, "yyyy-MM-dd")}&to=${format(now, "yyyy-MM-dd")}`
          break
        case "3M":
          const threeMonthsAgo = new Date(now.getTime())
          threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3)
          endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/1hour?from=${format(threeMonthsAgo, "yyyy-MM-dd")}&to=${format(now, "yyyy-MM-dd")}`
          break
        case "1Y":
          const yearAgo = new Date(now.getTime())
          yearAgo.setFullYear(yearAgo.getFullYear() - 1)
          endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/historical-price/${symbol}?from=${format(yearAgo, "yyyy-MM-dd")}&to=${format(now, "yyyy-MM-dd")}`
          break
        case "ALL":
          endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/historical-price/${symbol}`
          break
      }

      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      })

      if (!response.ok) {
        throw new Error("Failed to fetch price data")
      }

      const data = await response.json()
      const isIntraday = ["1D", "1W", "1M", "3M"].includes(selectedRange)

      let prices = isIntraday
        ? data
        : data.historical.map((item: any) => ({
            date: item.date,
            close: item.adjClose || item.close,
          }))

      prices = prices.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime(),
      )

      const firstPrice = prices[0].close
      return prices.map((price: any) => ({
        date: price.date,
        symbol,
        close: price.close,
        percentChange: ((price.close - firstPrice) / firstPrice) * 100,
      }))
    } catch (error) {
      console.error(`Error fetching price data for ${symbol}:`, error)
      throw error
    }
  }

  const { data: combinedData, isLoading } = useQuery({
    queryKey: [
      "multiSymbolChart",
      selectedRange,
      symbols.join(),
      selectedDate?.toISOString(),
    ],
    queryFn: async () => {
      try {
        const allData = await Promise.all(
          symbols.map((symbol) => fetchPriceData(symbol)),
        )

        const allTimestamps = [
          ...new Set(
            allData.flat().map((d: DataPoint) => new Date(d.date).getTime()),
          ),
        ].sort((a, b) => a - b)

        const processedData = symbols.map((symbol, index) => {
          const symbolData = allData[index]
          const firstPrice = symbolData[0].close

          return symbolData.map((d: DataPoint) => ({
            date: new Date(d.date).getTime(),
            symbol,
            close: d.close,
            percentChange:
              symbol === "^VIX"
                ? d.close
                : ((d.close - firstPrice) / firstPrice) * 100,
          }))
        })

        const combined = allTimestamps.map((timestamp) => {
          const dataPoint: any = { date: new Date(timestamp).toISOString() }

          symbols.forEach((symbol, index) => {
            const symbolData = processedData[index]
            const matchingPoint = symbolData.find(
              (d: DataPoint) => d.date === timestamp,
            )
            if (matchingPoint) {
              dataPoint[`${symbol}_percentChange`] = matchingPoint.percentChange
            }
          })

          return dataPoint
        })

        // Filter to only show last 24 hours if selectedRange is "1D"
        const filteredData =
          selectedRange === "1D"
            ? combined.filter((point) => {
                const pointTime = new Date(point.date).getTime()
                // Get the latest timestamp from the data
                const latestTime = Math.max(...allTimestamps)
                const twentyFourHoursBeforeLatest =
                  latestTime - 24 * 60 * 60 * 1000
                return pointTime > twentyFourHoursBeforeLatest
              })
            : combined

        return filteredData.filter((point) =>
          symbols.every(
            (symbol) => point[`${symbol}_percentChange`] !== undefined,
          ),
        )
      } catch (error) {
        console.error("Error processing market indices data:", error)
        throw error
      }
    },
    refetchInterval: selectedRange === "1D" && !selectedDate ? 60000 : false,
    refetchOnWindowFocus: selectedRange === "1D" && !selectedDate,
  })

  const formatXAxis = (value: string) => {
    const date = new Date(value)
    if (selectedRange === "1D") {
      return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    }
    return date.toLocaleDateString([], { month: "short", day: "numeric" })
  }

  const getSymbolColor = (symbol: string) => {
    if (colors && colors.length > 0) {
      return colors[0]
    }
    const defaultColorKeys = Object.keys(defaultColors)
    const index = symbols.indexOf(symbol)
    return (
      symbolColors[symbol] ||
      defaultColors[defaultColorKeys[index % defaultColorKeys.length]]
    )
  }

  const { data: sentimentData, isLoading: isSentimentLoading } = useQuery({
    queryKey: ["sentiment", Object.values(sentimentSymbols || {}).join()],
    queryFn: async () => {
      if (!showSentiment || !sentimentSymbols) return null

      const symbolsToFetch = sentimentSymbols.map(
        (obj) => Object.values(obj)[0],
      )

      console.log("symbolsToFetch", symbolsToFetch)

      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/sentiment/technical/batch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
          body: JSON.stringify({ symbols: symbolsToFetch }),
        },
      )

      if (!response.ok) {
        const text = await response.text()
        console.error("Sentiment fetch failed:", text)
        throw new Error("Failed to fetch sentiment data")
      }

      const data = await response.json()
      return data as SentimentResponse[]
    },
    enabled: showSentiment && Object.keys(sentimentSymbols || {}).length > 0,
  })

  const handleSymbolClick = (symbol: string) => {
    toggleSymbol(symbol)
    if (showMrktAI && onShowAnalysis) {
      const analysisData = sentimentData?.find((item: SentimentResponse) => {
        const symbolMapping = sentimentSymbols?.find(
          (mapping) => Object.keys(mapping)[0] === symbol,
        )
        return (
          item.symbol ===
          (symbolMapping ? Object.values(symbolMapping)[0] : null)
        )
      })?.data

      onShowAnalysis({
        symbol: symbolLabels[symbol] || "",
        analysis: analysisData?.sentimentAnalysis?.explanation || "",
        oneHourSentiment: analysisData?.analysis.hourly.trend || "Neutral",
        dailySentiment: analysisData?.analysis.daily.trend || "Neutral",
        weeklySentiment: analysisData?.analysis.weekly.trend || "Neutral",
        oneHourReasoning: analysisData?.analysis.hourly.reasoning || "",
        dailyReasoning: analysisData?.analysis.daily.reasoning || "",
        weeklyReasoning: analysisData?.analysis.weekly.reasoning || "",
        generatedAt: analysisData?.generatedAt || "",
        intraday_levels: analysisData?.analysis.intraday_levels || [],
        querySymbol: symbol,
      })
    }
  }

  const SentimentAnalysis = () => {
    if (isSentimentLoading) {
      return (
        <div className="text-[15px] font-medium text-white/90 break-words">
          <Skeleton
            count={1}
            width={200}
            height={20}
            baseColor="rgba(255,255,255,0.1)"
            highlightColor="rgba(255,255,255,0.2)"
          />
        </div>
      )
    }

    const sentimentBias =
      sentimentData
        ?.find((item: SentimentResponse) => {
          const symbolMapping = sentimentSymbols.find(
            (mapping) => Object.keys(mapping)[0] === selectedSymbol,
          )
          const targetSymbol = symbolMapping
            ? Object.values(symbolMapping)[0]
            : null
          return item.symbol === targetSymbol
        })
        ?.data?.analysis.hourly?.trend?.toLowerCase() || "neutral"

    return (
      <div className="text-[15px] font-medium text-white/90 break-words">
        <span className="text-white/90 font-medium">
          {symbolLabels[selectedSymbol]}
        </span>{" "}
        is{" "}
        <span className={cn("font-bold", getSentimentColor(sentimentBias))}>
          {getSentimentText(sentimentBias)}
        </span>{" "}
        on intraday
      </div>
    )
  }

  if (!symbols.length) {
    return (
      <div className="flex flex-col h-full p-4">
        <div className="text-white text-sm font-medium mb-2">Chart</div>
        <div className="flex-grow flex items-center justify-center">
          <div className="text-gray-400 text-sm">No data available</div>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return <ChartSkeleton height={height} title={title} symbols={symbols} />
  }

  if (!combinedData || combinedData.length === 0) {
    return (
      <div className="flex flex-col h-full">
        {title && (
          <h2 className="text-white text-sm font-medium mb-2">{title}</h2>
        )}
        <div className="flex gap-2 mb-2 text-xs">
          {timeRanges.map((range) => (
            <button
              key={range}
              onClick={() => setSelectedRange(range)}
              className={cn(
                "px-2 py-1 rounded transition-colors",
                selectedRange === range
                  ? "bg-white/10 text-white"
                  : "text-gray-400 hover:text-white",
              )}
            >
              {range}
            </button>
          ))}
        </div>
        <div className="flex-grow flex items-center justify-center text-gray-400 text-sm">
          No data available for selected time range
        </div>
      </div>
    )
  }

  return (
    <div
      className={cn(
        "flex flex-col h-full relative p-4 rounded-xl",
        showSentiment &&
          sentimentData &&
          getSentimentGradientClass(
            sentimentData?.find((item: SentimentResponse) => {
              const symbolMapping = sentimentSymbols.find(
                (mapping) => Object.keys(mapping)[0] === selectedSymbol,
              )
              return (
                item.symbol ===
                (symbolMapping ? Object.values(symbolMapping)[0] : null)
              )
            })?.data?.analysis.hourly.trend || "Neutral",
          ),
      )}
    >
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center gap-2">
          {title && <h2 className="text-white text-sm font-medium">{title}</h2>}
        </div>
        {showMrktAI && (
          <div className="flex items-center">
            <Brain
              className="w-5 h-5 text-purple-400/70 cursor-pointer hover:text-purple-400 transition-colors"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedAISymbol(selectedSymbol)
                handleSymbolClick(selectedSymbol)
                posthog.capture("sentiment_analysis_clicked", {
                  symbol: selectedSymbol,
                  user: user?.email,
                })
              }}
            />
          </div>
        )}
      </div>
      <div className="flex gap-2 mb-2 text-xs">
        {timeRanges.map((range) => (
          <button
            key={range}
            onClick={() => setSelectedRange(range)}
            className={cn(
              "px-2 py-1 rounded transition-colors",
              selectedRange === range
                ? "bg-white/10 text-white"
                : "text-gray-400 hover:text-white",
            )}
          >
            {range}
          </button>
        ))}
      </div>

      <div className="flex-grow">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={combinedData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <XAxis
              dataKey="date"
              tickFormatter={formatXAxis}
              minTickGap={50}
              stroke="transparent"
              tick={false}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              yAxisId="indices"
              domain={["auto", "auto"]}
              tickFormatter={(value) => {
                const isVixOnly =
                  visibleSymbols.size === 1 && visibleSymbols.has("^VIX")
                return isVixOnly ? value.toFixed(0) : `${value.toFixed(0)}%`
              }}
              width={25}
              stroke="transparent"
              tick={{
                fill: "rgba(255, 255, 255, 0.4)",
                fontSize: 10,
                fontWeight: 500,
              }}
              axisLine={false}
              tickLine={false}
              tickCount={tickCount}
              style={{ fontFamily: "-apple-system, system-ui, sans-serif" }}
              orientation="left"
            />
            <Tooltip
              formatter={(value: number, name: string) => {
                const symbolName = name.replace("_percentChange", "")
                return [
                  symbolName === "^VIX"
                    ? value.toFixed(2)
                    : `${value.toFixed(2)}%`,
                  symbolName,
                ]
              }}
              labelFormatter={(label) => new Date(label).toLocaleString()}
              contentStyle={{
                backgroundColor: "rgba(0,0,0,0.95)",
                backdropFilter: "blur(16px)",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "8px",
                color: "#fff",
                padding: "10px",
                fontSize: "12px",
                boxShadow: `
                  0 0 0 1px rgba(255,255,255,0.05),
                  0 4px 6px rgba(0,0,0,0.2),
                  0 8px 16px rgba(0,0,0,0.2),
                  0 0 20px rgba(0,0,0,0.3)
                `,
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            />
            {symbols
              .filter((symbol) => visibleSymbols.has(symbol))
              .map((symbol) => (
                <Area
                  key={symbol}
                  type="monotone"
                  dataKey={`${symbol}_percentChange`}
                  name={symbol}
                  stroke={getSymbolColor(symbol)}
                  strokeWidth={1.5}
                  fillOpacity={0}
                  isAnimationActive={false}
                  dot={false}
                  yAxisId="indices"
                  activeDot={{
                    r: 4,
                    fill: "#fff",
                    stroke: "rgba(255, 255, 255, 0.2)",
                    strokeWidth: 8,
                  }}
                />
              ))}
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      <div className="flex flex-wrap gap-3 mt-2">
        {symbols.map((symbol) => {
          return (
            <button
              key={symbol}
              onClick={() => handleSymbolClick(symbol)}
              className={cn(
                "flex items-center gap-2 py-1.5 transition-all text-xs",
                "border border-transparent",
                visibleSymbols.has(symbol)
                  ? "text-white border-l-2"
                  : "text-gray-500 opacity-50 hover:opacity-75",
              )}
              style={{
                borderLeftColor:
                  visibleSymbols.has(symbol) && !showMrktAI
                    ? getSymbolColor(symbol)
                    : "transparent ml-0",
              }}
            >
              {showMrktAI ? (
                <div className="flex items-start w-full">
                  <div className="text-left flex-1">
                    <SentimentAnalysis />
                  </div>
                </div>
              ) : (
                <span className="ml-2">{symbolLabels[symbol]}</span>
              )}
            </button>
          )
        })}
      </div>
    </div>
  )
}
