import { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { format } from "date-fns"
import {
  Area,
  ComposedChart,
  ResponsiveContainer,
  YAxis,
  Tooltip,
} from "recharts"
import { useQuery } from "@tanstack/react-query"

interface UserReportAssetItemProps {
  symbol: string
  name: string
  price: number | string
  change: number | string
  changePercent: string
  summary?: string
}

export const UserReportAssetItem = ({
  symbol,
  name,
  price,
  change,
  changePercent,
  summary,
}: UserReportAssetItemProps) => {
  const { getAccessTokenSilently } = useAuth0()
  const { data: chartData = [], isLoading } = useQuery({
    queryKey: ["assetChart", symbol],
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      const now = new Date()
      const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)

      const endpoint = `${import.meta.env.VITE_MRKT_SERVER}/equities/intraday/${symbol}/5min?from=${format(oneWeekAgo, "yyyy-MM-dd")}&to=${format(now, "yyyy-MM-dd")}`

      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        throw new Error("Failed to fetch chart data")
      }

      const data = await response.json()

      if (data && data.length > 0) {
        // Sort data chronologically (oldest to newest)
        const sortedData = [...data].sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
        )

        // Calculate percent change from first data point
        const firstValue = sortedData[0].close

        // Take a subset of data points to avoid overcrowding
        const step = Math.max(1, Math.floor(sortedData.length / 50))
        return sortedData
          .filter((_: any, i: number) => i % step === 0)
          .map((item: any) => ({
            date: new Date(item.date).getTime(),
            value: item.close,
            percentChange: ((item.close - firstValue) / firstValue) * 100,
          }))
      }
      return []
    },
  })

  const isPositive =
    typeof change === "number" ? change >= 0 : changePercent.startsWith("+")

  // Calculate min and max values for proper Y-axis domain
  const minValue =
    chartData.length > 0
      ? Math.min(...chartData.map((item) => item.value)) * 0.998
      : 0
  const maxValue =
    chartData.length > 0
      ? Math.max(...chartData.map((item) => item.value)) * 1.002
      : 0

  // Custom tooltip formatter
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-black/90 border border-white/10 rounded-md p-2 text-xs shadow-lg">
          <p className="text-white/90 font-medium">
            ${payload[0].value.toFixed(2)}
          </p>
          <p className="text-white/60 text-[10px]">
            {new Date(payload[0].payload.date).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              month: "short",
              day: "numeric",
            })}
          </p>
        </div>
      )
    }
    return null
  }

  return (
    <div className="p-2 flex flex-col sm:flex-row items-start justify-between gap-4">
      <div className="flex flex-col flex-1 w-full">
        <div className="flex items-center gap-3">
          <div className="w-5 h-5 flex-shrink-0">
            <img
              src={`https://financialmodelingprep.com/image-stock/${symbol}.png`}
              alt={`${symbol} logo`}
              className="w-full h-full object-contain opacity-80 group-hover:opacity-100 transition-opacity duration-200"
              onError={(e) => {
                const target = e.target as HTMLImageElement
                target.style.display = "none"
                target.parentElement!.innerHTML = `<div class="w-full h-full flex items-center justify-center text-xs font-medium text-white/50 bg-white/5 rounded-md">${symbol}</div>`
              }}
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-baseline gap-2">
              <span className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-blue-400">
                {symbol}
              </span>
              <span className="text-white/50">is trading at</span>
              <span className="text-l font-semibold text-white/90">
                ${price}
              </span>
              <span
                className={`text-sm px-2 py-0.5 rounded-full ${isPositive ? "bg-green-500/10 text-green-400" : "bg-red-500/10 text-red-400"}`}
              >
                {changePercent}
              </span>
            </div>
          </div>
        </div>

        {name !== symbol && (
          <div className="text-white/60 text-sm truncate mt-1 ml-0">{name}</div>
        )}
        {summary && (
          <div className="text-white/70 text-md mt-2 ml-0">{summary}</div>
        )}
      </div>

      {/* Chart with tooltip */}
      <div className="w-full sm:w-[220px] h-[80px] mt-3">
        {!isLoading && chartData.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={chartData}
              margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
            >
              <YAxis domain={[minValue, maxValue]} hide={true} />
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ stroke: "rgba(255,255,255,0.2)", strokeWidth: 1 }}
                position={{ y: 0 }}
              />
              <Area
                type="monotone"
                dataKey="value"
                stroke={isPositive ? "#4ADE80" : "#F87171"}
                fill={
                  isPositive
                    ? "rgba(74, 222, 128, 0.1)"
                    : "rgba(248, 113, 113, 0.1)"
                }
                strokeWidth={1.5}
                dot={false}
                activeDot={{
                  r: 4,
                  fill: isPositive ? "#4ADE80" : "#F87171",
                  stroke: "#000",
                  strokeWidth: 1,
                }}
                isAnimationActive={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <div className="w-4 h-4 border-2 border-t-transparent border-white/30 rounded-full animate-spin" />
          </div>
        )}
      </div>
    </div>
  )
}
