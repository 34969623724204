import { ReactNode, useState, useEffect } from "react"
import { Auth0Provider } from "@auth0/auth0-react"

type AuthProviderProps = {
  children: ReactNode
}

export const LoadingScreen = () => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        const increment = Math.max(0.5, (100 - prev) * 0.05)
        return Math.min(prev + increment, 99.9)
      })
    }, 10)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black z-[9999]">
      <div className="w-64 h-0.5 bg-gray-900 rounded-full overflow-hidden">
        <div
          className="h-full bg-white transition-all duration-200 ease-in-out"
          style={{
            width: `${progress}%`,
            transition: "width 200ms cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        />
      </div>
    </div>
  )
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const domain = import.meta.env.VITE_AUTH0_DOMAIN
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID
  const mrktServer = import.meta.env.VITE_AUTH0_AUDIENCE
  const [isInitialized, setIsInitialized] = useState(false)

  if (!(domain && clientId)) {
    return null
  }

  const onRedirectCallback = async (appState: any) => {
    console.log("[AuthProvider] Redirect callback triggered", { appState })
    const params = new URLSearchParams(window.location.search)
    const hasAuthParams = params.has("code") || params.has("error")
    const isStripeReturn = window.location.href.includes("stripe")
    const isFromStripeBack = document.referrer.includes("stripe")
    const connection = params.get("connection")

    console.log("[AuthProvider] Redirect params:", {
      hasAuthParams,
      isStripeReturn,
      isFromStripeBack,
      connection,
      searchParams: Object.fromEntries(params.entries()),
      referrer: document.referrer,
    })

    // Handle back button from Stripe
    if (isFromStripeBack) {
      console.log("[AuthProvider] Detected back navigation from Stripe")
      window.location.replace("/checkout")
      return
    }

    if (hasAuthParams || isStripeReturn) {
      const returnPath = isStripeReturn
        ? "/auth"
        : appState?.returnTo || "/onboarding"

      try {
        const searchParams = new URLSearchParams()
        if (appState?.plan && !isStripeReturn) {
          searchParams.set("plan", appState.plan)
        }
        if (connection) {
          searchParams.set("connection", connection)
        }

        const fullPath = `${returnPath}${
          searchParams.toString() ? `?${searchParams.toString()}` : ""
        }`

        console.log("[AuthProvider] Redirecting to:", fullPath)
        window.location.replace(fullPath)
      } catch (error) {
        console.error("[AuthProvider] Navigation error:", error)
        window.location.replace("/onboarding")
      }
    }
  }

  // Handle browser back/forward cache (bfcache)
  useEffect(() => {
    const handlePageShow = (e: PageTransitionEvent) => {
      if (e.persisted) {
        // If coming back from Stripe, redirect to checkout
        if (document.referrer.includes("stripe")) {
          window.location.replace("/checkout")
          return
        }
        window.location.reload()
      }
    }
    window.addEventListener("pageshow", handlePageShow)
    return () => window.removeEventListener("pageshow", handlePageShow)
  }, [])

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: mrktServer,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
    >
      {children}
    </Auth0Provider>
  )
}
