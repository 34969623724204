import { XCircle, X, FileText } from "lucide-react"
import { DNA } from "react-loader-spinner"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { motion } from "framer-motion"
import { Resizable } from "re-resizable"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { useState, useEffect } from "react"
import { format } from "date-fns"
import { MultiSymbolChart } from "@/presentation/components/Charts/MultiSymbolChart"

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

const highlightText = (text: string, keyPoints: string[]) => {
  let highlightedText = text
  keyPoints.forEach((point) => {
    const escapedPoint = escapeRegExp(point)
    const regex = new RegExp(escapedPoint, "gi")
    highlightedText = highlightedText.replace(
      regex,
      `<span class="bg-yellow-500/30 text-yellow-200 font-medium px-1 rounded-sm border-b border-yellow-400/50">${point}</span>`,
    )
  })
  return (
    <p
      className="text-gray-300"
      dangerouslySetInnerHTML={{ __html: highlightedText }}
    />
  )
}

const GLOBAL_SYMBOLS = ["USDCNY", "USDJPY", "EURUSD", "AUDUSD", "GBPUSD"]
const GLOBAL_SYMBOL_LABELS = {
  USDCNY: "USD/CNY",
  USDJPY: "USD/JPY",
  EURUSD: "EUR/USD",
  AUDUSD: "AUD/USD",
  GBPUSD: "GBP/USD",
}

const SummaryLoadingSkeleton = ({ messages }: { messages: string[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length)
    }, 2000)

    return () => clearInterval(interval)
  }, [messages.length])

  return (
    <div className="space-y-6">
      <div className="flex flex-col items-center justify-center py-8">
        <div className="flex gap-1">
          <DNA
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{ color: "orange" }}
            wrapperClass="dna-wrapper"
          />
        </div>
        <h2 className="text-xl font-medium text-white mb-4">
          {messages[currentIndex]}
        </h2>
      </div>

      <div className="space-y-6">
        {[...Array(6)].map((_, index) => (
          <div key={index}>
            <Skeleton
              height={24}
              width={160}
              className="mb-3"
              baseColor="#27272a"
              highlightColor="#3f3f46"
            />
            <div className="space-y-2">
              <Skeleton
                height={16}
                baseColor="#27272a"
                highlightColor="#3f3f46"
              />
              <Skeleton
                height={16}
                width="75%"
                baseColor="#27272a"
                highlightColor="#3f3f46"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

interface DailySummaryPanelProps {
  analysis: any
  isLoading: boolean
  onClose: () => void
  selectedDate?: Date
}

export const DailySummaryPanel = ({
  analysis,
  isLoading,
  onClose,
  selectedDate,
}: DailySummaryPanelProps) => {
  const loadingMessages = [
    "Analyzing market headlines...",
    "Processing daily events...",
    "Synthesizing market insights...",
    "Generating comprehensive summary...",
    "Identifying key trends...",
  ]

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "spring", bounce: 0, duration: 0.4 }}
      className="fixed right-0 top-0 h-full bg-[#111] border-l border-[#333] backdrop-blur-xl bg-black/80 shadow-xl z-[50] flex"
    >
      <Resizable
        defaultSize={{ width: 500, height: "100%" }}
        minWidth={500}
        maxWidth={1000}
        enable={{
          top: false,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        handleClasses={{
          left: "w-1 h-full cursor-ew-resize hover:bg-purple-500/20 transition-colors absolute left-0 top-0 border-r border-purple-500/10",
        }}
      >
        <div className="h-full flex flex-col overflow-hidden">
          <div className="flex justify-between items-center p-6 border-b border-[#333] bg-black/50 backdrop-blur-sm">
            <div className="flex items-center gap-3">
              <div className="flex items-center justify-center w-10 h-10 rounded-lg bg-purple-500/10 border border-purple-500/20">
                <FileText className="w-5 h-5 text-purple-400" />
              </div>
              <div>
                <h2 className="text-xl font-semibold text-white">
                  Daily Market Summary
                </h2>
                <p className="text-sm text-gray-400">
                  {selectedDate
                    ? format(selectedDate, "MMMM d, yyyy")
                    : "Latest Summary"}
                </p>
              </div>
            </div>
            <button
              onClick={onClose}
              className="flex items-center justify-center w-8 h-8 rounded-lg text-gray-400 hover:text-white hover:bg-white/5 transition-colors"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <SimpleBar
            className="flex-1 overflow-y-auto"
            style={{ height: "100%" }}
            autoHide={false}
          >
            <div className="p-6 pb-12">
              {isLoading ? (
                <SummaryLoadingSkeleton messages={loadingMessages} />
              ) : analysis ? (
                <div className="space-y-8">
                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Global Market Trends
                    </h3>
                    {highlightText(
                      analysis.global_market_trends.text,
                      analysis.global_market_trends.key_points,
                    )}
                  </div>
                  <div className="mt-4">
                    <div className="h-[300px] w-full">
                      <MultiSymbolChart
                        symbols={GLOBAL_SYMBOLS}
                        symbolLabels={GLOBAL_SYMBOL_LABELS}
                        height={450}
                        title="Currency Performance"
                        selectedTimeRange="1D"
                        selectedDate={selectedDate}
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Key Economic Updates
                    </h3>
                    {highlightText(
                      analysis.key_economic_updates.text,
                      analysis.key_economic_updates.key_points,
                    )}
                  </div>
                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Geopolitical Developments
                    </h3>
                    {highlightText(
                      analysis.geopolitical_developments.text,
                      analysis.geopolitical_developments.key_points,
                    )}
                  </div>
                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Major Commodities & Currency Moves
                    </h3>
                    {highlightText(
                      analysis.major_commodities_currency_moves.text,
                      analysis.major_commodities_currency_moves.key_points,
                    )}
                  </div>
                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Corporate Financial News
                    </h3>
                    {highlightText(
                      analysis.corporate_financial_news.text,
                      analysis.corporate_financial_news.key_points,
                    )}
                  </div>
                  <div>
                    <h3 className="text-lg font-medium mb-4 text-white">
                      Other Highlights
                    </h3>
                    {highlightText(
                      analysis.miscellaneous_highlights.text,
                      analysis.miscellaneous_highlights.key_points,
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center py-12">
                  <XCircle className="w-12 h-12 text-red-500/70 mb-4" />
                  <h3 className="text-lg font-medium text-white mb-2">
                    Analysis Failed
                  </h3>
                  <p className="text-zinc-400 text-sm">
                    Unable to generate summary. Please try again later.
                  </p>
                </div>
              )}
            </div>
          </SimpleBar>
        </div>
      </Resizable>
    </motion.div>
  )
}
