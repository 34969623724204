import { createFileRoute, useNavigate } from "@tanstack/react-router"
import { useAuth0 } from "@auth0/auth0-react"
import { useEffect } from "react"
import { LoadingScreen, useUser } from "@/providers"

export const Route = createFileRoute("/auth")({
  validateSearch: (search: Record<string, unknown>) => {
    const plan = search.plan as string | undefined
    if (plan && !["monthly", "annual"].includes(plan)) {
      return { plan: undefined }
    }
    return { plan: plan as "monthly" | "annual" | undefined }
  },
  component: () => {
    const { isLoading, isAuthenticated } = useAuth0()
    const { plan } = Route.useSearch()
    const { isSubscribed, isLoading: isUserLoading, userData } = useUser()
    const navigate = useNavigate()

    useEffect(() => {
      console.log("[Auth Route] State:", {
        isLoading,
        isAuthenticated,
        isUserLoading,
        isSubscribed,
        plan,
        userData,
      })

      if (!isLoading && !isAuthenticated) {
        console.log("[Auth Route] User not authenticated")
        return
      }

      // If user hasn't completed onboarding, send them there first
      if (isAuthenticated && !isUserLoading && !userData?.hasOnboarded) {
        console.log("[Auth Route] User needs to complete onboarding")
        navigate({ to: "/onboarding" })
        return
      }

      // If user is authenticated but not subscribed, redirect to checkout
      if (
        isAuthenticated &&
        !isUserLoading &&
        !isSubscribed &&
        userData?.hasOnboarded
      ) {
        console.log(
          "[Auth Route] User authenticated but not subscribed, redirecting to checkout",
        )
        if (!plan) {
          navigate({ to: "/checkout" })
        } else {
          navigate({ to: "/checkout", search: { plan } })
        }
        return
      }

      // If user is authenticated and subscribed, redirect to their preferred dashboard
      if (isAuthenticated && !isUserLoading && isSubscribed) {
        console.log(
          "[Auth Route] User authenticated and subscribed, redirecting to dashboard",
        )

        // Check if user has a preferred dashboard
        if (userData?.tradingInfo?.selectedDashboard === "equities") {
          navigate({ to: "/equities" })
        } else {
          navigate({ to: "/dashboard" })
        }
        return
      }
    }, [
      isLoading,
      isAuthenticated,
      isSubscribed,
      isUserLoading,
      plan,
      userData,
    ])

    return <LoadingScreen />
  },
})
