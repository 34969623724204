import { useEffect, useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { X, Brain, TrendingUp, TrendingDown } from "lucide-react"
import { useUser } from "@/providers"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { cn } from "@/lib/utils"
import { MultiSymbolChart } from "../Charts/MultiSymbolChart"
import { UserReportAssetItem } from "./UserReportAssetItem"
import { ArrowUpRight, ArrowDownRight, Minus } from "lucide-react"

interface Asset {
  symbol: string
  summary: string
}

interface Quote {
  symbol: string
  name: string
  price: number
  change: number
  changePercentage: number
  volume: number
  dayLow: number
  dayHigh: number
  yearHigh: number
  yearLow: number
  marketCap: number | null
  priceAvg50: number
  priceAvg200: number
  exchange: string
  open: number
  previousClose: number
  timestamp: number
}

interface ReportData {
  assetSummaryData: {
    global_market_overview: {
      text: string
    }
    asset_breakdown_summary: {
      text: string
    }
    assets: Asset[]
    generatedAt: string
    dailyRecap: {
      analysis: {
        global_market_trends: {
          text: string
          key_points: string[]
        }
        key_economic_updates: {
          text: string
          key_points: string[]
        }
        corporate_financial_news: {
          text: string
          key_points: string[]
        }
        geopolitical_developments: {
          text: string
          key_points: string[]
        }
        major_commodities_currency_moves: {
          text: string
          key_points: string[]
        }
        miscellaneous_highlights: {
          text: string
          key_points: string[]
        }
      }
    }
  }
  quotes: Quote[]
}

interface UserReportProps {
  isOpen: boolean
  onClose: () => void
  symbols: string[]
}

const getSentimentColor = (sentiment: string) => {
  if (!sentiment) return "text-gray-400"

  if (sentiment.includes("bullish") || sentiment.includes("Bullish")) {
    return "text-green-400"
  } else if (sentiment.includes("bearish") || sentiment.includes("Bearish")) {
    return "text-red-400"
  } else {
    return "text-yellow-400"
  }
}

const getSentimentText = (sentiment: string) => {
  if (!sentiment) return "neutral"

  const lowerSentiment = sentiment.toLowerCase()

  if (lowerSentiment.includes("neutral_to_bullish")) {
    return "slightly bullish"
  } else if (lowerSentiment.includes("neutral_to_bearish")) {
    return "slightly bearish"
  } else if (lowerSentiment.includes("strongly_bullish")) {
    return "strongly bullish"
  } else if (lowerSentiment.includes("strongly_bearish")) {
    return "strongly bearish"
  } else if (lowerSentiment.includes("bullish")) {
    return "bullish"
  } else if (lowerSentiment.includes("bearish")) {
    return "bearish"
  } else {
    return "neutral"
  }
}

const getSentimentIcon = (sentiment: string) => {
  if (!sentiment) return <Minus className="h-4 w-4 text-yellow-400" />

  if (sentiment.includes("bullish") || sentiment.includes("Bullish")) {
    return <ArrowUpRight className="h-4 w-4 text-green-400" />
  } else if (sentiment.includes("bearish") || sentiment.includes("Bearish")) {
    return <ArrowDownRight className="h-4 w-4 text-red-400" />
  } else {
    return <Minus className="h-4 w-4 text-yellow-400" />
  }
}

const fetchMarketReport = async ({
  symbols,
  getToken,
  userId,
}: {
  symbols: string[]
  getToken: () => Promise<string>
  userId: string
}) => {
  const token = await getToken()

  const response = await fetch(
    `${import.meta.env.VITE_MRKT_SERVER}/mrkt-ai/user-report`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ symbols, userId }),
    },
  )

  const result = await response.json()
  if (!result.success) {
    throw new Error("Failed to fetch market report")
  }

  return result.data
}

export const UserReport = ({ isOpen, onClose, symbols }: UserReportProps) => {
  const { userData } = useUser()
  const [selectedAssets, setSelectedAssets] = useState<string[]>([])
  const { getAccessTokenSilently } = useAuth0()
  const [thinkingStep, setThinkingStep] = useState(0)

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget && !isPending) onClose()
  }

  useEffect(() => {
    setSelectedAssets(userData?.tradingInfo?.specificAssets?.forex)
  }, [userData])

  // Get sentiment data for the US stock market (S&P 500)
  const { data: sentimentData, isLoading: isSentimentLoading } = useQuery({
    queryKey: ["sentiment", "ESUSD", isOpen],
    queryFn: async () => {
      if (!isOpen) return null

      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/sentiment/technical/batch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
          body: JSON.stringify({ symbols: ["ESUSD", ...symbols] }),
        },
      )

      if (!response.ok) {
        const text = await response.text()
        console.error("Sentiment fetch failed:", text)
        throw new Error("Failed to fetch sentiment data")
      }

      const data = await response.json()
      return data
    },
    enabled: isOpen,
  })

  // Get user's MRKT report data
  const {
    data: reportData,
    isPending,
    mutate,
  } = useMutation({
    mutationFn: (symbols: string[]) =>
      fetchMarketReport({
        symbols,
        getToken: getAccessTokenSilently,
        userId: userData?.email,
      }),
    gcTime: 1000000,
  })

  useEffect(() => {
    if (isOpen && selectedAssets?.length > 0) {
      mutate(selectedAssets)
    }
  }, [isOpen, selectedAssets, mutate])

  useEffect(() => {
    if (!isPending) return

    const steps = [
      "Gathering market data for your watchlist assets...",
      "Analyzing price patterns and recent movements...",
      "Evaluating market sentiment and news impact...",
      "Identifying correlations between assets...",
      "Generating comprehensive insights and recommendations...",
    ]

    let currentStep = 0
    const interval = setInterval(() => {
      if (currentStep < steps.length - 1) {
        currentStep++
        setThinkingStep(currentStep)
      } else {
        clearInterval(interval)
      }
    }, 3000) // Progress to next step every 3 seconds

    return () => clearInterval(interval)
  }, [isPending])

  // Process quotes data to get price and change information
  const processedAssets =
    reportData?.assetSummaryData?.assets
      ?.filter((asset: Asset) => selectedAssets.includes(asset.symbol))
      ?.map((asset: Quote) => {
        const quote = reportData?.quotes?.find(
          (q: Quote) => q.symbol === asset.symbol,
        )

        // Find the sentiment data for this asset
        const assetSentiment = sentimentData?.find(
          (s: any) => s.symbol === asset.symbol,
        )

        // Get hourly analysis data
        const hourlyAnalysis = assetSentiment?.data?.analysis?.hourly
        const hourlyTrend = hourlyAnalysis?.trend || "neutral"
        const hourlyReasoning = hourlyAnalysis?.reasoning || ""

        return {
          ...asset,
          name: asset.symbol,
          changePercentage: quote?.changePercentage || 0,
          price: quote?.price || 0,
          change: quote?.change || 0,
          // Use the hourly analysis data
          summary: hourlyReasoning,
        }
      }) || []

  console.log("Processed assets:", processedAssets)
  // Add this console log to debug the data
  useEffect(() => {
    if (reportData) {
      console.log("Report data:", reportData)
      console.log("Selected assets:", selectedAssets)
      console.log("Sentiment data:", sentimentData)
    }
  }, [reportData, selectedAssets, sentimentData])

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/40 backdrop-blur-sm"
          onClick={handleBackdropClick}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="relative w-full max-w-4xl h-[90vh] rounded-xl bg-[#111] border border-[#333] backdrop-blur-2xl bg-black/75 shadow-2xl flex flex-col overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="h-full flex flex-col">
              {/* Header with close button */}
              <div className="flex justify-between items-center p-6 shrink-0 border-b border-[#333]">
                <div className="flex items-center gap-2">
                  <Brain className={cn("w-5 h-5 text-purple-400")} />
                  <span className="text-2xl font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-blue-400 to-indigo-300">
                    Your MRKT Report
                  </span>
                </div>
                <button
                  onClick={!isPending ? onClose : undefined}
                  className={cn(
                    "text-white/70",
                    isPending
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:text-white",
                  )}
                >
                  <X className="h-4 w-4" />
                </button>
              </div>

              {/* Content with SimpleBar for scrolling */}
              <div className="flex-1 min-h-0">
                <SimpleBar
                  className="h-full px-4 sm:px-6 pb-6 custom-scrollbar-light"
                  autoHide={false}
                >
                  <div className="h-full">
                    {isPending ? (
                      <div className="flex flex-col items-center justify-center min-h-[80vh]">
                        <div className="flex flex-col items-center gap-3 mb-4">
                          <Brain className="w-6 h-6 text-purple-400" />
                          <div className="text-lg font-medium text-white/90 text-center">
                            MRKT AI is generating your report
                          </div>
                        </div>

                        <div className="w-full max-w-lg bg-black/30 rounded-lg p-5 space-y-4">
                          {/* All steps appear sequentially */}
                          {thinkingStep >= 0 && (
                            <div className="flex items-start gap-3 animate-fadeIn">
                              <div className="w-2 h-2 rounded-full bg-purple-400 mt-2"></div>
                              <div className="flex-1">
                                <div className="text-white/80">
                                  Gathering market data for your assets...
                                </div>
                              </div>
                            </div>
                          )}

                          {/* Step 2 - Appears after delay */}
                          {thinkingStep >= 1 && (
                            <div className="flex items-start gap-3 animate-fadeIn">
                              <div className="w-2 h-2 rounded-full bg-purple-400 mt-2"></div>
                              <div className="flex-1">
                                <div className="text-white/80">
                                  Evaluating market sentiment and news impact...
                                </div>
                              </div>
                            </div>
                          )}

                          {/* Step 3 - Appears after more delay */}
                          {thinkingStep >= 2 && (
                            <div className="flex items-start gap-3 animate-fadeIn">
                              <div className="w-2 h-2 rounded-full bg-purple-400 mt-2"></div>
                              <div className="flex-1">
                                <div className="text-white/80">
                                  Analyzing recent price movements...
                                </div>
                              </div>
                            </div>
                          )}

                          {/* Step 4 - Appears after even more delay */}
                          {thinkingStep >= 3 && (
                            <div className="flex items-start gap-3 animate-fadeIn">
                              <div className="w-2 h-2 rounded-full bg-purple-400 mt-2"></div>
                              <div className="flex-1">
                                <div className="text-white/80">
                                  Identifying correlations between assets...
                                </div>
                              </div>
                            </div>
                          )}

                          {/* Step 5 - Final step */}
                          {thinkingStep >= 4 && (
                            <div className="flex items-start gap-3 animate-fadeIn">
                              <div className="w-2 h-2 rounded-full bg-purple-400 mt-2"></div>
                              <div className="flex-1">
                                <div className="text-white/80">
                                  Generating comprehensive insights and
                                  recommendations...
                                </div>
                              </div>
                            </div>
                          )}

                          {/* Current thinking indicator */}
                          <div className="flex items-center gap-2 mt-4">
                            <div className="animate-spin w-3 h-3 border-2 border-purple-400 border-t-transparent rounded-full"></div>
                            <div className="text-white/60 text-sm italic">
                              Thinking...
                            </div>
                          </div>
                        </div>

                        <div className="mt-6 text-white/50 text-sm max-w-md text-center">
                          MRKT AI is generating a comprehensive analysis of your
                          watchlist assets, examining recent price movements,
                          market conditions, and potential opportunities.
                        </div>
                      </div>
                    ) : (
                      <>
                        <SimpleBar className="flex-1 min-h-0 custom-scrollbar">
                          <div className="pr-2 sm:pr-4 space-y-4 mt-4">
                            {/* Add S&P 500 Sentiment Display */}
                            {sentimentData &&
                              sentimentData.length > 0 &&
                              sentimentData[0]?.data?.analysis?.daily
                                ?.trend && (
                                <div>
                                  <div className="shrink-0">
                                    <h3 className="text-lg font-medium text-white/90 flex flex-col sm:flex-row sm:items-center sm:justify-between">
                                      <div>
                                        {sentimentData[0].data.analysis.daily.trend
                                          .toLowerCase()
                                          .includes("bullish") ||
                                        sentimentData[0].data.analysis.daily.trend
                                          .toLowerCase()
                                          .includes("neutral_to_bullish") ? (
                                          <TrendingUp
                                            className={cn(
                                              "w-4 h-4 inline mr-2",
                                              getSentimentColor(
                                                sentimentData[0].data.analysis
                                                  .daily.trend,
                                              ),
                                            )}
                                          />
                                        ) : sentimentData[0].data.analysis.daily.trend
                                            .toLowerCase()
                                            .includes("bearish") ||
                                          sentimentData[0].data.analysis.daily.trend
                                            .toLowerCase()
                                            .includes("neutral_to_bearish") ? (
                                          <TrendingDown
                                            className={cn(
                                              "w-4 h-4 inline mr-2",
                                              getSentimentColor(
                                                sentimentData[0].data.analysis
                                                  .daily.trend,
                                              ),
                                            )}
                                          />
                                        ) : null}
                                        <span className="bg-white/5 backdrop-blur-[2px] text-white/90 font-medium">
                                          Market
                                        </span>{" "}
                                        is{" "}
                                        <span
                                          className={cn(
                                            "font-medium",
                                            getSentimentColor(
                                              sentimentData[0].data.analysis
                                                .daily.trend,
                                            ),
                                          )}
                                        >
                                          {getSentimentText(
                                            sentimentData[0].data.analysis.daily
                                              .trend,
                                          )}
                                        </span>{" "}
                                      </div>
                                      {reportData?.assetSummaryData
                                        ?.generatedAt && (
                                        <span className="text-sm text-white/50 mt-1 sm:mt-0">
                                          Generated at:{" "}
                                          {new Date(
                                            reportData.assetSummaryData.generatedAt,
                                          ).toLocaleTimeString()}
                                        </span>
                                      )}
                                    </h3>
                                  </div>
                                </div>
                              )}

                            {/* Market Overview */}
                            <div className="text-md text-white/90 leading-relaxed">
                              {reportData?.assetSummaryData?.dailyRecap
                                ?.analysis.global_market_trends?.text ||
                                "Loading market overview..."}
                            </div>
                            <div className="text-md text-white/90 leading-relaxed">
                              {reportData?.assetSummaryData
                                ?.asset_breakdown_summary?.text ||
                                "Loading summary analysis..."}
                            </div>

                            {/* Asset Cards Row */}
                            <SimpleBar
                              className="w-full pb-2"
                              autoHide={false}
                              style={{ maxHeight: "unset" }}
                            >
                              <div className="flex w-full gap-3 px-1 py-2">
                                {processedAssets.map(
                                  (asset: any, index: number) => (
                                    <div
                                      key={asset.symbol || index}
                                      className="flex-1 min-w-[120px] p-4 rounded-xl bg-gradient-to-br from-[#111]/90 to-black/80 backdrop-blur-md border border-[#333] shadow-[0_8px_20px_rgba(0,0,0,0.3),inset_0_1px_1px_rgba(255,255,255,0.05)] hover:shadow-[0_8px_25px_rgba(80,60,200,0.2),inset_0_1px_1px_rgba(255,255,255,0.1)] hover:border-purple-900/40 transition-all duration-300"
                                    >
                                      <div className="flex flex-col gap-2">
                                        <div className="flex items-center justify-between">
                                          <span className="font-medium text-sm text-white/90">
                                            {asset.symbol}
                                          </span>
                                          {asset.change >= 0 ? (
                                            <TrendingUp className="w-4 h-4 text-green-400" />
                                          ) : (
                                            <TrendingDown className="w-4 h-4 text-red-400" />
                                          )}
                                        </div>
                                        <div
                                          className={`text-xl font-bold mt-1 ${asset.change >= 0 ? "text-green-400" : "text-red-400"}`}
                                        >
                                          {asset.changePercentage >= 0
                                            ? "+"
                                            : ""}
                                          {asset.changePercentage.toFixed(2)}%
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                )}
                              </div>
                            </SimpleBar>

                            <div className="h-[400px]">
                              <MultiSymbolChart
                                symbols={selectedAssets}
                                symbolLabels={selectedAssets.reduce(
                                  (acc, symbol) => {
                                    acc[symbol] = symbol
                                    return acc
                                  },
                                  {} as Record<string, string>,
                                )}
                                defaultVisibleSymbols={selectedAssets}
                                symbolColors={selectedAssets.reduce(
                                  (acc, symbol, index) => {
                                    const colors = [
                                      "rgba(127, 179, 213, 0.7)", // #7FB3D5 Desaturated Blue
                                      "rgba(229, 152, 102, 0.7)", // #E59866 Muted Orange
                                      "rgba(195, 155, 211, 0.7)", // #C39BD3 Soft Violet
                                      "rgba(118, 215, 196, 0.7)", // #76D7C4 Subtle Teal
                                      "rgba(171, 178, 185, 0.7)", // #ABB2B9 Muted Gray
                                    ]
                                    acc[symbol] = colors[index % colors.length]
                                    return acc
                                  },
                                  {} as Record<string, string>,
                                )}
                                height={400}
                                title=""
                                tickCount={3}
                                defaultTimeRange="1W"
                              />
                            </div>
                            {/* Top Movers Section */}
                            <div className="space-y-4">
                              <div className="flex items-center gap-2">
                                <h3 className="text-md font-medium text-white/90">
                                  Asset Breakdown
                                </h3>
                              </div>
                              <div className="space-y-2">
                                {processedAssets.map(
                                  (asset: any, index: number) => (
                                    <UserReportAssetItem
                                      key={asset.symbol || index}
                                      symbol={asset.symbol}
                                      name={asset.name}
                                      price={asset.price}
                                      change={asset.change}
                                      changePercent={`${asset.changePercentage >= 0 ? "+" : ""}${asset.changePercentage.toFixed(2)}%`}
                                      summary={asset.summary}
                                    />
                                  ),
                                )}
                              </div>
                            </div>

                            {/* Summary Section */}
                          </div>
                        </SimpleBar>
                      </>
                    )}
                  </div>
                </SimpleBar>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
