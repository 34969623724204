import { Brain, Loader2 } from "lucide-react"
import { ButtonHTMLAttributes, forwardRef } from "react"
import { cn } from "@/lib/utils"

interface AIGenerateButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  isGenerating?: boolean
  text?: string
}

export const AIGenerateButton = forwardRef<
  HTMLButtonElement,
  AIGenerateButtonProps
>(({ isGenerating = false, text, className = "", ...props }, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      className={cn(
        "px-4 py-3 rounded-lg",
        "bg-gradient-to-r from-purple-500/10 to-blue-500/10",
        "hover:from-purple-500/20 hover:to-blue-500/20",
        "border border-purple-500/30",
        "transition-colors duration-200",
        "flex items-center gap-2",
        className,
      )}
    >
      {isGenerating ? (
        <Loader2 className="w-4 h-4 animate-spin text-purple-400" />
      ) : (
        <Brain className="w-4 h-4 text-purple-400" />
      )}
      <span className="text-purple-300 text-sm">{text}</span>
    </button>
  )
})

AIGenerateButton.displayName = "AIGenerateButton"
