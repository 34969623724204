import { useAuth0 } from "@auth0/auth0-react"
import { useNavigate } from "@tanstack/react-router"
import { useState, useEffect, useRef } from "react"
import { Search, X, Loader2 } from "lucide-react"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import posthog from "posthog-js"

interface TickerSearchResult {
  symbol: string
  name: string
  currency: string
  stockExchange: string
  exchangeShortName: string
}

interface SearchTickerProps {
  onSelect?: (symbol: string) => void
}

export function SearchTicker({ onSelect }: SearchTickerProps = {}) {
  const [query, setQuery] = useState("")
  const [results, setResults] = useState<TickerSearchResult[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [searchMode, setSearchMode] = useState<"ticker" | "company">("ticker")
  const { getAccessTokenSilently, user } = useAuth0()
  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (open) {
      inputRef.current?.focus()

      const handleEscape = (e: KeyboardEvent) => {
        if (e.key === "Escape") setOpen(false)
      }

      document.addEventListener("keydown", handleEscape)
      return () => document.removeEventListener("keydown", handleEscape)
    }
  }, [open])

  // Updated debounced search
  useEffect(() => {
    const timer = setTimeout(async () => {
      if (query.length < 1) {
        setResults([])
        return
      }

      setIsLoading(true)
      try {
        const token = await getAccessTokenSilently()
        const endpoint =
          searchMode === "ticker" ? "search-ticker" : "search-name"
        const queryParams =
          searchMode === "ticker"
            ? `query=${query}&limit=50`
            : `query=${query}&limit=50&exchange=NASDAQ`
        const response = await fetch(
          `${import.meta.env.VITE_MRKT_SERVER}/equities/${endpoint}?${queryParams}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        if (response.ok) {
          const data = await response.json()
          setResults(data)
        }
      } catch (error) {
        console.error("Search error:", error)
      } finally {
        setIsLoading(false)
      }
    }, 300)

    return () => clearTimeout(timer)
  }, [query, getAccessTokenSilently, searchMode])

  const handleSelect = (symbol: string) => {
    setOpen(false)
    setQuery("")

    if (onSelect) {
      onSelect(symbol)
    } else {
      navigate({ to: "/equities/symbol/$symbol", params: { symbol } })
    }
  }

  const handleClearSearch = () => {
    setQuery("")
    inputRef.current?.focus()
  }

  return (
    <div className="relative">
      <button
        onClick={() => {
          setOpen(true)
          posthog.capture("equities_search_ticker_clicked", {
            user: user?.email,
          })
        }}
        className="flex items-center gap-2 px-2.5 py-2.5 bg-white/5 border border-white/10 
                   rounded-lg text-white hover:bg-white/10 transition-all
                   shadow-[0_0_1px_rgba(255,255,255,0.1)] hover:shadow-[0_0_5px_rgba(255,255,255,0.1)]"
      >
        <Search className="h-4 w-4" />
        {/* <span className="font-medium text-sm">Search Ticker</span> */}
      </button>

      {open && (
        <>
          <div
            onClick={() => setOpen(false)}
            className="fixed inset-0 bg-black/90 z-50"
          />

          <div
            className="fixed inset-0 flex items-center justify-center z-50 p-4"
            onClick={() => setOpen(false)}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="w-full max-w-2xl rounded-lg shadow-2xl flex flex-col"
            >
              <div className="p-4 border rounded-md border-white/10 bg-black/95">
                <div className="flex flex-col gap-3">
                  <div className="flex items-center gap-3">
                    <Search className="w-5 h-5 text-gray-400" />
                    <input
                      ref={inputRef}
                      type="text"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      placeholder={`Search by ${searchMode === "ticker" ? "ticker" : "company name"}...`}
                      className="flex-1 bg-transparent text-white text-lg outline-none placeholder:text-gray-500"
                    />
                    {query && (
                      <button
                        onClick={handleClearSearch}
                        className="px-3 py-1.5 bg-white/5 hover:bg-white/10 
                                 rounded-md transition-colors text-sm text-gray-400"
                      >
                        Clear
                      </button>
                    )}
                    <div className="w-px h-6 bg-white/10" />
                    <button
                      onClick={() => setOpen(false)}
                      className="p-2 hover:bg-white/5 rounded-md transition-colors"
                      aria-label="Close search"
                    >
                      <X className="w-5 h-5 text-gray-400" strokeWidth={1.5} />
                    </button>
                  </div>

                  <div className="flex p-0.5 bg-white/5 rounded-lg w-fit">
                    <button
                      onClick={() => setSearchMode("ticker")}
                      className={`px-4 py-1.5 rounded-md text-sm font-medium transition-all ${
                        searchMode === "ticker"
                          ? "bg-white/15 text-white shadow-sm"
                          : "text-gray-400 hover:text-gray-300"
                      }`}
                    >
                      Ticker
                    </button>
                    <button
                      onClick={() => setSearchMode("company")}
                      className={`px-4 py-1.5 rounded-md text-sm font-medium transition-all ${
                        searchMode === "company"
                          ? "bg-white/15 text-white shadow-sm"
                          : "text-gray-400 hover:text-gray-300"
                      }`}
                    >
                      Company
                    </button>
                  </div>
                </div>
              </div>

              <div className="h-[1px] bg-transparent" />

              {results.length > 0 ? (
                <div className="border rounded-md border-white/10 bg-black/95">
                  <SimpleBar style={{ maxHeight: "400px" }}>
                    <div className="p-2">
                      <div className="space-y-0.5">
                        {results.map((result) => (
                          <button
                            key={result.symbol}
                            onClick={() => handleSelect(result.symbol)}
                            className="w-full p-3 rounded text-left hover:bg-white/5 
                                     transition-colors duration-200"
                          >
                            <div className="flex items-center justify-between">
                              <span className="text-white font-medium">
                                {result.symbol}
                              </span>
                              <span className="text-gray-500 text-sm">
                                {result.exchangeShortName}
                              </span>
                            </div>
                            <span className="text-gray-400 text-sm mt-0.5 block">
                              {result.name}
                            </span>
                          </button>
                        ))}
                      </div>
                    </div>
                  </SimpleBar>
                </div>
              ) : (
                <div className="h-[400px] flex items-center justify-center border rounded-md border-white/10 bg-black/95">
                  <div className="text-center">
                    {isLoading ? (
                      <div>
                        <Loader2 className="w-6 h-6 mx-auto mb-2 animate-spin text-gray-400" />
                      </div>
                    ) : query ? (
                      <div>
                        <Search className="w-6 h-6 mx-auto mb-2 text-gray-600" />
                        <div className="text-gray-400">No tickers found</div>
                      </div>
                    ) : (
                      <div>
                        <Search className="w-6 h-6 mx-auto mb-2 text-gray-600" />
                        <div className="text-gray-400">
                          Start typing to search tickers
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
