import { useState } from "react"
import {
  Area,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { useQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { SentimentBadge } from "../Badge/SentimentBadge"

const ChartSkeleton = ({
  height = 400,
  title,
}: {
  height?: number
  title?: string
}) => {
  return (
    <div className="flex flex-col h-full animate-pulse">
      {title && <div className="mb-2 h-5 w-32 bg-white/5 rounded" />}
      <div className="flex-grow relative overflow-hidden">
        <div
          className="w-full bg-gradient-to-b from-white/5 to-white/[0.02] rounded-lg"
          style={{ height }}
        >
          <div className="absolute inset-0 flex flex-col justify-between py-8">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="w-full h-px bg-white/5" />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

interface SafeHavenData {
  rating: string
  score: number
  timestamp: string
}

interface SafeHavenDemandChartProps {
  height?: number
  title?: string
  tickCount?: number
}

// Add interface for chart data
interface ChartDataPoint {
  date: string
  value: number
}

const SAFE_HAVEN_DESCRIPTION =
  "Safe Haven Demand measures the performance gap between Treasury bonds and stocks over 20 trading days. While stocks typically outperform long-term, bonds can do better in shorter periods when investors seek safety. Higher bond performance relative to stocks signals increased fear in the market."

export const SafeHavenDemandChart = ({
  height = 400,
  title = "Safe Haven Demand",
  tickCount = 6,
}: SafeHavenDemandChartProps) => {
  const { getAccessTokenSilently } = useAuth0()
  const [showInfo, setShowInfo] = useState(false)

  const { data: rawData, isLoading } = useQuery({
    queryKey: ["safeHavenDemand"],
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/fng/historical/safe_haven_demand`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error("Failed to fetch safe haven demand data")
      }

      const data = await response.json()
      // If the data is wrapped in a data property, extract it
      return data.data || data
    },
  })

  // Add new query for current rating
  const { data: currentData } = useQuery({
    queryKey: ["safeHavenDemandCurrent"],
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/fng/safe_haven_demand`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error("Failed to fetch current safe haven demand data")
      }

      const data = await response.json()
      return data.data
    },
  })

  const formatXAxis = (value: string) => {
    const date = new Date(value)
    return date.toLocaleDateString([], {
      month: "short",
      day: "numeric",
    })
  }

  // Transform and sort the data chronologically
  const chartData = rawData
    ? rawData
        .map(
          (item: SafeHavenData): ChartDataPoint => ({
            date: item.timestamp,
            value: Number(item.score.toFixed(2)),
          }),
        )
        .sort(
          (a: ChartDataPoint, b: ChartDataPoint) =>
            new Date(a.date).getTime() - new Date(b.date).getTime(),
        )
    : []

  if (isLoading) {
    return <ChartSkeleton height={height} title={title} />
  }

  if (chartData.length === 0) {
    return (
      <div className="flex flex-col h-full">
        {title && (
          <h2 className="text-white text-sm font-medium mb-2">{title}</h2>
        )}
        <div className="flex-grow flex items-center justify-center text-gray-400 text-sm">
          No data available
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-white text-sm font-medium mb-2">{title}</h2>
        {/* {currentData && (
          <div className="flex items-center gap-3">
            <SentimentBadge
              rating={currentData.rating}
              score={currentData.score}
              description={SAFE_HAVEN_DESCRIPTION}
              onInfoClick={() => setShowInfo(!showInfo)}
            />
          </div>
        )} */}
      </div>

      <div className="flex-grow relative">
        {showInfo ? (
          <div className="absolute inset-0 flex items-center justify-center bg-black/90 backdrop-blur-sm rounded-lg z-10 p-6">
            <div className="max-w-lg relative">
              <button
                onClick={() => setShowInfo(false)}
                className="absolute -top-2 -right-2 w-6 h-6 flex items-center justify-center rounded-full bg-white/10 hover:bg-white/20 transition-colors"
              >
                <span className="text-white/80">×</span>
              </button>
              <p className="text-sm leading-relaxed text-white/80">
                {SAFE_HAVEN_DESCRIPTION}
              </p>
            </div>
          </div>
        ) : null}
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={chartData}
            margin={{ top: 10, right: 0, bottom: -10, left: -10 }}
          >
            <XAxis
              dataKey="date"
              tickFormatter={formatXAxis}
              minTickGap={50}
              stroke="#666"
              tick={{
                fill: "rgba(255, 255, 255, 0.6)",
                fontSize: 9,
              }}
              axisLine={false}
              tickLine={{ stroke: "rgba(255, 255, 255, 0.1)" }}
            />
            <YAxis
              domain={["dataMin - 1", "dataMax + 1"]}
              tickFormatter={(value) => `${value.toFixed(1)}`}
              width={35}
              stroke="#666"
              tick={{
                fill: "rgba(255, 255, 255, 0.6)",
                fontSize: 9,
              }}
              axisLine={false}
              tickLine={{ stroke: "rgba(255, 255, 255, 0.1)" }}
              tickCount={tickCount}
              style={{ fontFamily: "-apple-system, system-ui, sans-serif" }}
            />
            <Tooltip
              formatter={(value: number) => [
                `${value.toFixed(2)}`,
                "Safe Haven Demand",
              ]}
              labelFormatter={(label) =>
                new Date(label).toLocaleDateString([], {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }
              contentStyle={{
                backgroundColor: "rgba(0,0,0,0.95)",
                backdropFilter: "blur(16px)",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "8px",
                color: "#fff",
                padding: "12px",
                fontSize: "12px",
                boxShadow: `
                  0 0 0 1px rgba(255,255,255,0.05),
                  0 4px 6px rgba(0,0,0,0.2)
                `,
                fontWeight: "500",
              }}
            />
            <Area
              type="monotone"
              dataKey="value"
              stroke="rgba(245, 158, 11, 0.6)"
              strokeWidth={1.5}
              fillOpacity={0.15}
              fill="rgba(245, 158, 11, 0.1)"
              isAnimationActive={false}
              dot={false}
              activeDot={{
                r: 4,
                fill: "#f59e0b",
                stroke: "rgba(245, 158, 11, 0.2)",
                strokeWidth: 8,
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
