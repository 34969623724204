import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery, useMutation } from "@tanstack/react-query"

type TrendType =
  | "bullish"
  | "bearish"
  | "neutral"
  | "neutral_to_bullish"
  | "neutral_to_bearish"

type TrendAnalysis = {
  trend: TrendType
  reasoning: string
}

type SymbolAnalysis = {
  analysis: {
    hourly: TrendAnalysis
    daily: TrendAnalysis
    weekly: TrendAnalysis
    intraday_levels: number[]
  }
  generatedAt: string
  symbol: string
  lastPrice: number
  sentimentAnalysis: {
    explanation: string
    citations: string[]
  }
}

type TechnicalSentimentResponse = Array<{
  symbol: string
  data: SymbolAnalysis
}>

type TechnicalSentimentData = {
  [symbol: string]: SymbolAnalysis
}

type UserContextType = {
  userData: any
  isLoading: boolean
  updateUser: (data: any) => void
  isSubscribed: boolean
  refetchUser: () => void
  fetchTechnicalSentiment: (
    symbols: string[],
  ) => Promise<TechnicalSentimentData>
  technicalSentimentData: TechnicalSentimentData | null
  isTechnicalSentimentLoading: boolean
}

const UserContext = createContext<UserContextType | null>(null)

export function UserProvider({ children }: { children: ReactNode }) {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0()
  const [technicalSentimentData, setTechnicalSentimentData] =
    useState<TechnicalSentimentData | null>(null)
  const [isTechnicalSentimentLoading, setIsTechnicalSentimentLoading] =
    useState<boolean>(false)

  const {
    data: userData,
    isLoading,
    refetch: userDataRefetch,
  } = useQuery({
    queryKey: ["user", user?.email],
    queryFn: async () => {
      if (!user?.email) return null
      const token = await getAccessTokenSilently()
      const encodedEmail = encodeURIComponent(user.email)
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/user?id=${encodedEmail}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      const data = await response.json()

      // If we get a "User not found" error, return { data: null }
      if (!response.ok && data.error === "User not found") {
        return { data: null }
      }

      // For other errors, throw them
      if (!response.ok) {
        throw new Error(data.error || "Failed to fetch user")
      }

      return data
    },
    enabled: !!user?.email,
  })

  const updateMutation = useMutation({
    mutationFn: async (data: any) => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/user/save`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      )
      if (!response.ok) {
        const error = await response.json()
        throw new Error(error.error || "Failed to update user")
      }
      return response.json()
    },
  })

  const fetchTechnicalSentiment = async (
    symbols: string[],
  ): Promise<TechnicalSentimentData> => {
    try {
      setIsTechnicalSentimentLoading(true)
      const token = await getAccessTokenSilently()

      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/sentiment/technical/batch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ symbols }),
        },
      )

      if (!response.ok) {
        const error = await response.json()
        throw new Error(
          error.error || "Failed to fetch technical sentiment data",
        )
      }

      const responseData: TechnicalSentimentResponse = await response.json()

      // Transform the array response into an object keyed by symbol for easier access
      const formattedData: TechnicalSentimentData = responseData.reduce(
        (acc, item) => {
          acc[item.symbol] = item.data
          return acc
        },
        {} as TechnicalSentimentData,
      )

      setTechnicalSentimentData(formattedData)
      return formattedData
    } catch (error) {
      console.error("Error fetching technical sentiment:", error)
      throw error
    } finally {
      setIsTechnicalSentimentLoading(false)
    }
  }

  // Handle initial user creation
  useEffect(() => {
    if (isAuthenticated && user && userData?.data === null) {
      updateMutation.mutate({
        auth0Id: user.sub!,
        email: user.email ?? "",
        name: user.name ?? "",
      })
    }
  }, [isAuthenticated, user, userData])

  return (
    <UserContext.Provider
      value={{
        userData: userData?.data,
        isLoading,
        updateUser: updateMutation.mutate,
        isSubscribed: userData?.data?.isSubscribed ?? false,
        refetchUser: userDataRefetch,
        fetchTechnicalSentiment,
        technicalSentimentData,
        isTechnicalSentimentLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) throw new Error("useUser must be used within UserProvider")
  return context
}
