import { useUser } from "@/providers/UserProvider"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "@/hooks"
import { Plus, X, Search, Loader2 } from "lucide-react"
import { useAuth0 } from "@auth0/auth0-react"
import { useState, useEffect, useRef, useCallback } from "react"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { useNavigate } from "@tanstack/react-router"
import posthog from "posthog-js"

interface QuoteData {
  symbol: string
  name: string
  price: number
  changesPercentage: number
  change: number
  dayLow: number
  dayHigh: number
  yearHigh: number
  yearLow: number
  marketCap: number
  priceAvg50: number
  priceAvg200: number
  exchange: string
  volume: number
  avgVolume: number
  open: number
  previousClose: number
  eps: number
  pe: number
  earningsAnnouncement: string
  sharesOutstanding: number
  timestamp: number
}

interface WatchlistResponse {
  watchlist: string[]
  quotes: QuoteData[]
}

interface WatchlistStock {
  symbol: string
  name: string
  price: number
  changePercentage: number
}

interface TickerSearchResult {
  symbol: string
  name: string
  currency: string
  stockExchange: string
  exchangeShortName: string
}

export function Watchlist() {
  const { user, getAccessTokenSilently } = useAuth0()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { data: watchlist, isLoading } = useQuery<WatchlistStock[]>({
    queryKey: ["watchlist", user?.email],
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/equities/watchlist/${user?.email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (!response.ok) throw new Error("Failed to fetch watchlist")
      const data: WatchlistResponse = await response.json()

      return data.quotes.map((quote) => ({
        symbol: quote.symbol,
        name: quote.name,
        price: typeof quote.price === "number" ? quote.price : 0,
        changePercentage:
          typeof quote.changesPercentage === "number"
            ? quote.changesPercentage
            : 0,
      }))
    },
    enabled: !!user?.email,
  })

  const addToWatchlist = useMutation({
    mutationFn: async (symbol: string) => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/equities/watchlist/${user?.email}/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ symbol }),
        },
      )
      if (!response.ok) throw new Error("Failed to add to watchlist")
      return response.json()
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["watchlist", user?.email] })
      toast({
        title: "Successfully added to watchlist",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to add to watchlist",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    },
  })

  const removeFromWatchlist = useMutation({
    mutationFn: async (symbol: string) => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/equities/watchlist/${user?.email}/remove`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ symbol }),
        },
      )
      if (!response.ok) throw new Error("Failed to remove from watchlist")
      return response.json()
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["watchlist", user?.email] })
      toast({
        title: "Successfully removed to watchlist",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to remove from watchlist",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    },
  })

  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [searchResults, setSearchResults] = useState<TickerSearchResult[]>([])
  const [isSearching, setIsSearching] = useState(false)
  const searchInputRef = useRef<HTMLInputElement>(null)
  const [showAbove, setShowAbove] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [searchMode, setSearchMode] = useState<"ticker" | "company">("ticker")

  const updatePopoverPosition = useCallback(() => {
    if (!buttonRef.current) return

    const buttonRect = buttonRef.current.getBoundingClientRect()
    const spaceBelow = window.innerHeight - buttonRect.bottom
    const spaceAbove = buttonRect.top

    setShowAbove(spaceBelow < 400 && spaceAbove > spaceBelow)
  }, [])

  useEffect(() => {
    if (isSearchOpen) {
      updatePopoverPosition()
      window.addEventListener("resize", updatePopoverPosition)
      return () => window.removeEventListener("resize", updatePopoverPosition)
    }
  }, [isSearchOpen, updatePopoverPosition])

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (searchQuery.length < 1) {
        setSearchResults([])
        return
      }

      setIsSearching(true)
      try {
        const token = await getAccessTokenSilently()
        const endpoint =
          searchMode === "ticker" ? "search-ticker" : "search-name"
        const queryParams =
          searchMode === "ticker"
            ? `query=${searchQuery}&limit=50`
            : `query=${searchQuery}&limit=50&exchange=NASDAQ`

        const response = await fetch(
          `${import.meta.env.VITE_MRKT_SERVER}/equities/${endpoint}?${queryParams}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        if (response.ok) {
          const data = await response.json()
          setSearchResults(data)
        }
      } catch (error) {
        console.error("Search error:", error)
      } finally {
        setIsSearching(false)
      }
    }, 300)

    return () => clearTimeout(timer)
  }, [searchQuery, getAccessTokenSilently, searchMode])

  const handleAddStock = (symbol: string) => {
    addToWatchlist.mutate(symbol)
    setIsSearchOpen(false)
    setSearchQuery("")
    setSearchResults([])
  }

  const SearchPopover = () => (
    <>
      <div
        className="fixed inset-0 z-50"
        onClick={() => setIsSearchOpen(false)}
      />
      <div
        className={`absolute ${
          showAbove ? "bottom-full mb-2" : "top-full mt-2"
        } right-0 w-96 p-3 rounded-lg border border-white/10 bg-zinc-950 shadow-xl z-50`}
      >
        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-2 bg-white/5 rounded-lg px-3 py-2">
            <Search className="w-4 h-4 text-gray-400" />
            <input
              ref={searchInputRef}
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={`Search by ${searchMode === "ticker" ? "ticker" : "company name"}...`}
              className="bg-transparent text-white outline-none w-full placeholder:text-gray-500"
              autoFocus
            />
            {searchQuery && (
              <button
                onClick={() => {
                  setSearchQuery("")
                  searchInputRef.current?.focus()
                }}
                className="px-2 py-1 bg-white/5 hover:bg-white/10 
                         rounded-md transition-colors text-sm text-gray-400"
              >
                Clear
              </button>
            )}
          </div>

          <div className="flex p-0.5 bg-white/5 rounded-lg w-fit">
            <button
              onClick={() => setSearchMode("ticker")}
              className={`px-4 py-1.5 rounded-md text-sm font-medium transition-all ${
                searchMode === "ticker"
                  ? "bg-white/15 text-white shadow-sm"
                  : "text-gray-400 hover:text-gray-300"
              }`}
            >
              Ticker
            </button>
            <button
              onClick={() => setSearchMode("company")}
              className={`px-4 py-1.5 rounded-md text-sm font-medium transition-all ${
                searchMode === "company"
                  ? "bg-white/15 text-white shadow-sm"
                  : "text-gray-400 hover:text-gray-300"
              }`}
            >
              Company
            </button>
          </div>
        </div>

        <div className="relative mt-2">
          {searchResults.length > 0 ? (
            <SimpleBar style={{ maxHeight: "300px" }}>
              <div className="space-y-1">
                {searchResults.map((result) => (
                  <button
                    key={result.symbol}
                    onClick={() => handleAddStock(result.symbol)}
                    className="w-full p-2 rounded text-left hover:bg-white/5 
                             transition-colors duration-200"
                  >
                    <div className="flex items-center justify-between">
                      <span className="text-white font-medium">
                        {result.symbol}
                      </span>
                      <span className="text-gray-500 text-sm">
                        {result.exchangeShortName}
                      </span>
                    </div>
                    <span className="text-gray-400 text-sm block truncate">
                      {result.name}
                    </span>
                  </button>
                ))}
              </div>
            </SimpleBar>
          ) : (
            <div className="h-[100px] flex items-center justify-center">
              {isSearching ? (
                <Loader2 className="w-5 h-5 animate-spin text-gray-400" />
              ) : searchQuery ? (
                <span className="text-gray-400">No results found</span>
              ) : (
                <span className="text-gray-400">Type to search</span>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )

  if (isLoading) {
    return <div className="text-white/60">Loading watchlist...</div>
  }

  if (!watchlist?.length) {
    return (
      <div className="h-full flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-white text-sm font-medium mb-2">Watchlist</h2>
        </div>
        <div className="flex-1 flex flex-col items-center justify-center text-white/60">
          <p>No stocks in watchlist</p>
          <div className="relative">
            <button
              ref={buttonRef}
              onClick={() => setIsSearchOpen(true)}
              className="inline-flex text-sm items-center mt-2 gap-2 py-2 px-4 text-white bg-[#111] border border-[#333] rounded-md hover:bg-[#333] transition-colors"
            >
              <Plus size={16} />
              Add Stock
            </button>
            {isSearchOpen && <SearchPopover />}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-white text-sm font-medium mb-2">Watchlist</h2>
        <div className="relative">
          <button
            ref={buttonRef}
            onClick={() => {
              setIsSearchOpen(true)
              posthog.capture("watchlist_add_stock_clicked", {
                user: user?.email,
              })
            }}
            className="inline-flex text-sm items-center gap-2 py-1 px-2 text-white bg-[#111] border border-[#333] rounded-md hover:bg-[#333] transition-colors"
          >
            <Plus size={16} />
            Add
          </button>
          {isSearchOpen && <SearchPopover />}
        </div>
      </div>
      <div className="flex-1 overflow-y-auto space-y-2">
        {watchlist.map((stock) => (
          <div
            key={stock.symbol}
            onClick={() =>
              navigate({
                to: "/equities/symbol/$symbol",
                params: { symbol: stock.symbol },
              })
            }
            className="group block cursor-pointer"
          >
            <div
              className="relative flex gap-4 p-3 rounded-lg
                border border-white/5 
                bg-black/20
                hover:bg-black/30
                hover:border-white/10
                transition-all duration-200"
            >
              <div className="w-10 h-10 flex-shrink-0">
                <div
                  className="w-full h-full rounded-lg bg-black/30
                    flex items-center justify-center overflow-hidden
                    group-hover:border-white/10
                    transition-all duration-200"
                >
                  <img
                    src={`https://financialmodelingprep.com/image-stock/${stock.symbol}.png`}
                    alt={`${stock.symbol} logo`}
                    className="w-full h-full object-contain p-2"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement
                      const container = target.parentElement
                      if (container) {
                        container.innerHTML = ""
                        container.appendChild(
                          document.createTextNode(stock.symbol),
                        )
                      }
                    }}
                  />
                </div>
              </div>
              <div className="flex-1 min-w-0 flex justify-between items-center">
                <div className="flex flex-col">
                  <span className="text-sm font-medium text-white/70">
                    {stock.symbol}
                  </span>
                  <span className="text-sm text-white/60 truncate max-w-[200px] group-hover:text-white/80 transition-colors duration-200">
                    {stock.name}
                  </span>
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex flex-col items-end">
                    <span className="text-sm font-medium text-white/70">
                      $
                      {typeof stock.price === "number"
                        ? stock.price.toFixed(2)
                        : "0.00"}
                    </span>
                    <span
                      className={`text-sm font-medium ${
                        (stock.changePercentage || 0) >= 0
                          ? "text-emerald-500/90"
                          : "text-red-500/90"
                      }`}
                    >
                      {(stock.changePercentage || 0) >= 0 ? "+" : ""}
                      {typeof stock.changePercentage === "number"
                        ? stock.changePercentage.toFixed(2)
                        : "0.00"}
                      %
                    </span>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      removeFromWatchlist.mutate(stock.symbol)
                    }}
                    className="p-1 hover:bg-white/10 rounded-lg transition-colors"
                  >
                    <X size={16} className="text-white/60" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
