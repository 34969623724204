import { createFileRoute, useNavigate } from "@tanstack/react-router"
import { useAuth0 } from "@auth0/auth0-react"
import { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { LoadingScreen, useUser } from "@/providers"
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card"
import { Switch } from "@/components/ui/switch"
import { ArrowRight, Check } from "lucide-react"
import { useState } from "react"
import { cn } from "@/lib/utils"

export const Route = createFileRoute("/checkout")({
  validateSearch: (search: Record<string, unknown>) => {
    const plan = search.plan as string | undefined
    if (plan && !["monthly", "annual"].includes(plan)) {
      return { plan: undefined }
    }
    return { plan: plan as "monthly" | "annual" | undefined }
  },
  component: () => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
    const { plan } = Route.useSearch()
    const { isSubscribed, isLoading: isUserLoading, userData } = useUser()
    const navigate = useNavigate()

    useEffect(() => {
      console.log("[Checkout Route] State:", {
        isLoading,
        isAuthenticated,
        isUserLoading,
        isSubscribed,
        plan,
      })

      if (!isLoading && !isAuthenticated) {
        console.log(
          "[Checkout Route] User not authenticated, redirecting to login",
        )
        loginWithRedirect({
          appState: { returnTo: "/onboarding", plan },
        })
      }

      if (
        !isLoading &&
        !isUserLoading &&
        isAuthenticated &&
        !userData?.hasOnboarded
      ) {
        console.log("[Checkout Route] User needs to complete onboarding")
        navigate({ to: "/onboarding" })
        return
      }

      if (!isLoading && !isUserLoading && isAuthenticated && isSubscribed) {
        console.log(
          "[Checkout Route] User already subscribed, redirecting to dashboard",
        )
        if (userData?.tradingInfo?.selectedDashboard === "equities") {
          navigate({ to: "/equities" })
        } else {
          navigate({ to: "/dashboard" })
        }
      }
    }, [isLoading, isAuthenticated, isUserLoading, isSubscribed])

    if (isLoading || isUserLoading) {
      return <LoadingScreen />
    }

    if (!plan && !isSubscribed && isAuthenticated) {
      return <PricingSection />
    }

    return <CheckoutPage />
  },
})

const pricingOptions = [
  {
    name: (
      <div className="flex items-center gap-2 min-h-[44px]">
        <span className="text-white text-lg font-bold">PRO</span>
      </div>
    ),
    price: "$49.99",
    yearlyPrice: "$499.99",
    description:
      "Access real-time market data and AI-powered insights to make smarter financial decisions.",
    features: [
      "Personalized AI-Powered Marktet Analysis",
      "Real-Time Market Sentiment",
      "Live Market Headlines & News",
      "AI-Enhanced Economic Calendar",
      "Global Market Dashboards",
      "Advanced Stock Research Tools",
    ],
  },
  {
    name: (
      <div className="flex items-center gap-3 min-h-[44px]">
        <span className="text-white text-lg font-bold">PRO</span>
        <span className="text-white font-semibold bg-green-500/70 px-2 py-2 rounded-lg">
          Capital Hungry Members Discount
        </span>
      </div>
    ),
    price: "$24.99",
    yearlyPrice: "$249.99",
    description:
      "Get the same powerful financial intelligence tools at a special rate for Capital Hungry members.",
    features: [
      "Personalized AI-Powered Marktet Analysis",
      "Real-Time Market Sentiment",
      "Live Market Headlines & News",
      "AI-Enhanced Economic Calendar",
      "Global Market Dashboards",
      "Advanced Stock Research Tools",
    ],
  },
]

const GradientButton = ({
  children,
  onClick,
  className,
}: {
  children: React.ReactNode
  onClick: () => void
  className?: string
}) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        "group relative w-full rounded-lg",
        "overflow-hidden px-4 py-2.5",
        "bg-gradient-to-r from-cyan-500 via-violet-500 to-cyan-500",
        "bg-[length:200%_auto]",
        "text-sm font-semibold text-white",
        "transition-all duration-300",
        "hover:bg-right-bottom",
        "hover:shadow-[0_0_20px_2px_rgba(6,182,212,0.3)]",
        "active:scale-[0.98]",
        className,
      )}
    >
      <div className="relative flex items-center justify-center gap-2">
        <span className="transition-transform duration-300 group-hover:translate-x-[-2px]">
          {children}
        </span>
        <ArrowRight className="size-4 transition-transform duration-300 group-hover:translate-x-1" />
      </div>
    </button>
  )
}

function PricingSection() {
  const [isYearly, setIsYearly] = useState(true)
  const { plan } = Route.useSearch()
  const { logout } = useAuth0()
  const { user, getAccessTokenSilently } = useAuth0()

  const { data: capitalHungryStatus, isLoading: isCapitalHungryLoading } =
    useQuery({
      queryKey: ["capitalHungryValidation", user?.email],
      queryFn: async () => {
        if (!user?.email) return { verified: false }

        try {
          const token = await getAccessTokenSilently()
          const params = new URLSearchParams()
          params.append("email", user.email)

          const response = await fetch(
            `${import.meta.env.VITE_MRKT_SERVER}/capital-hungry?${params}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )

          if (!response.ok) {
            return { verified: false }
          }

          const data = await response.json()
          return data
        } catch (error) {
          console.error("Error checking Capital Hungry status:", error)
          return { verified: false }
        }
      },
      enabled: !!user?.email,
    })

  const filteredPricingOptions = pricingOptions.filter((option) => {
    const isCapitalHungryOption = option.name.props?.children[1] !== undefined

    // If user is verified with Capital Hungry, only show the Capital Hungry option
    if (capitalHungryStatus?.verified) {
      return isCapitalHungryOption
    }

    // If user is not verified with Capital Hungry, only show the regular option
    return !isCapitalHungryOption
  })

  const handleCheckout = async (option: any, isYearly: boolean) => {
    const isCapitalHungryOption = option.name.props?.children[1] !== undefined
    const planType = isYearly ? "annual" : "monthly"
    const email = user?.email

    if (isCapitalHungryOption && capitalHungryStatus?.verified) {
      try {
        const token = await getAccessTokenSilently()
        const response = await fetch(
          `${import.meta.env.VITE_MRKT_SERVER}/capital-hungry/session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              email,
              planType,
            }),
          },
        )

        if (!response.ok) {
          throw new Error("Failed to create checkout session")
        }

        const data = await response.json()
        window.location.href = data.sessionUrl
      } catch (error) {
        console.error("Error creating Capital Hungry session:", error)
        window.location.href = `/checkout?plan=${planType}`
      }
    } else {
      window.location.href = `/checkout?plan=${planType}`
    }
  }

  return (
    <section
      id="pricing"
      className="min-h-screen bg-black flex flex-col justify-center"
    >
      <div className="container mx-auto max-w-md py-6 px-4 animate-fade-in">
        <h2 className="text-2xl sm:text-3xl font-bold text-center text-white mb-6">
          Choose Your Plan
        </h2>

        <div className="flex items-center justify-center mb-6">
          <div className="inline-flex items-center rounded-full border border-zinc-800 bg-black px-2 py-1">
            <span
              className={`px-2 py-1 text-sm font-medium transition-colors ${
                !isYearly ? "text-white" : "text-zinc-500"
              }`}
            >
              Monthly
            </span>
            <Switch
              checked={isYearly}
              onCheckedChange={setIsYearly}
              className={cn(
                "mx-1",
                "data-[state=checked]:bg-green-500",
                "data-[state=unchecked]:bg-zinc-700",
              )}
            />
            <span
              className={`px-2 py-1 text-sm font-medium transition-colors ${
                isYearly ? "text-white" : "text-zinc-500"
              }`}
            >
              Annual
            </span>
          </div>
          {isYearly && (
            <span className="ml-2 inline-flex items-center rounded-full bg-green-500/20 px-2 py-1 text-xs font-medium text-green-500">
              Save 17%
            </span>
          )}
        </div>

        {filteredPricingOptions.map((option, index) => (
          <Card
            key={index}
            className="w-full flex h-full flex-col shadow-xl border border-zinc-800 hover:border-cyan-500/30 transition-all duration-300 bg-[#0a0a0a] backdrop-blur-sm hover:shadow-2xl hover:shadow-cyan-500/10 rounded-xl overflow-hidden mb-6"
          >
            <div className="bg-gradient-to-r from-cyan-500/10 to-violet-500/10 py-2 text-center">
              <span className="text-xs font-semibold text-cyan-400 uppercase tracking-wider">
                {capitalHungryStatus?.verified
                  ? "Capital Hungry Discount"
                  : "Premium Plan"}
              </span>
            </div>

            <CardHeader className="text-center pt-4 pb-2 px-4">
              <CardDescription className="text-zinc-200 text-sm font-medium">
                AI market insights to elevate your trading
              </CardDescription>
            </CardHeader>

            <CardContent className="space-y-4 px-4">
              <div className="text-center">
                <div className="flex items-center justify-center">
                  <span className="text-4xl font-bold bg-gradient-to-r from-cyan-400 to-violet-400 bg-clip-text text-transparent">
                    {isYearly
                      ? option.yearlyPrice === "$499.99"
                        ? "$41.67"
                        : "$20.83" // $249.99 / 12 = $20.83
                      : option.price}
                  </span>
                  <span className="text-zinc-300 ml-1 text-base">/month</span>
                </div>
                {isYearly && (
                  <p className="text-xs text-zinc-300 mt-1 font-medium">
                    Billed annually ({option.yearlyPrice}/year)
                  </p>
                )}
              </div>

              <div className="border-t border-zinc-800/30 pt-4">
                <ul className="space-y-4">
                  {option.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start group">
                      <div className="h-5 w-5 rounded-full bg-cyan-500/10 flex items-center justify-center mr-2 group-hover:bg-cyan-500/20 transition-colors duration-200 mt-0.5">
                        <Check className="h-3 w-3 text-cyan-400 shrink-0" />
                      </div>
                      <span className="text-zinc-200 text-sm group-hover:text-white transition-colors duration-200">
                        {feature}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </CardContent>

            <CardFooter className="px-4 pb-6 pt-6">
              <GradientButton
                onClick={() => handleCheckout(option, isYearly)}
                className="bg-gradient-to-r from-cyan-500 to-violet-500 hover:from-cyan-600 hover:to-violet-600 rounded-lg py-3 text-sm"
              >
                Access your dashboard
              </GradientButton>
            </CardFooter>
          </Card>
        ))}

        <div className="text-center mt-2 pb-6 flex justify-center space-x-6">
          <a
            href="https://mrktedge.ai"
            className="text-zinc-400 hover:text-zinc-200 text-sm underline"
          >
            Return to Website
          </a>
          <button
            onClick={() => logout()}
            className="text-zinc-400 hover:text-zinc-200 text-sm underline"
          >
            Logout
          </button>
        </div>
      </div>
    </section>
  )
}

function CheckoutPage() {
  const { plan } = Route.useSearch()
  const { user, getAccessTokenSilently } = useAuth0()
  const { isSubscribed, isLoading: isUserLoading } = useUser()

  const { data: checkoutSession, isLoading: isCheckoutSessionLoading } =
    useQuery({
      queryKey: ["checkout-session", plan],
      queryFn: async () => {
        if (!user?.email) return null

        const token = await getAccessTokenSilently()
        const response = await fetch(
          `${import.meta.env.VITE_MRKT_SERVER}/stripe/create-checkout-session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              email: user.email,
              plan,
              auth0Id: user.sub,
              referral: window.tolt_referral,
            }),
          },
        )

        if (!response.ok) {
          throw new Error("Failed to create checkout session")
        }

        return response.json()
      },
      enabled: !!user?.email && !isSubscribed,
    })

  useEffect(() => {
    if (checkoutSession?.url) {
      window.location.href = checkoutSession.url
    }
  }, [checkoutSession])

  if (isCheckoutSessionLoading) {
    return <LoadingScreen />
  }

  return <LoadingScreen />
}
