import { useState } from "react"
import { useUser } from "@/providers"
import { useUpdateUser } from "@/hooks/useUpdateUser"
import { useAuth0 } from "@auth0/auth0-react"
import { motion, AnimatePresence } from "framer-motion"
import { X, Plus, Edit2, Trash2, Search, BarChart3 } from "lucide-react"
import { cn } from "@/lib/utils"
import { toast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import { AssetSelector } from "../AssetSelector/AssetSelector"

interface AssetManagerProps {
  isOpen: boolean
  onClose: () => void
  assetType: "forex" | "equities"
}

export const AssetManager = ({
  isOpen,
  onClose,
  assetType,
}: AssetManagerProps) => {
  const { userData } = useUser()
  const { updateUser } = useUpdateUser()
  const { user } = useAuth0()

  // Initialize with a 6-item array, ensuring we pad with empty strings if needed
  const initialAssets = Array(6)
    .fill("")
    .map(
      (_, i) => userData?.tradingInfo?.specificAssets?.[assetType]?.[i] || "",
    )
  const [assets, setAssets] = useState<string[]>(initialAssets)
  const [isAssetSelectorOpen, setIsAssetSelectorOpen] = useState(false)
  const [editingAssetIndex, setEditingAssetIndex] = useState<
    number | undefined
  >(undefined)

  const filledAssetCount = assets.filter(Boolean).length

  // Handle adding a new asset
  const handleAddAsset = () => {
    // Find the first empty slot
    const emptyIndex = assets.findIndex((asset) => !asset)
    setEditingAssetIndex(emptyIndex >= 0 ? emptyIndex : undefined)
    setIsAssetSelectorOpen(true)
  }

  // Handle editing an asset
  const handleEditAsset = (index: number) => {
    setEditingAssetIndex(index)
    setIsAssetSelectorOpen(true)
  }

  // Handle asset selection from the selector
  const handleAssetSelected = async (symbol: string) => {
    if (!user?.email) return

    try {
      // Always maintain a 6-item array
      const newAssets = [...assets]

      if (editingAssetIndex !== undefined) {
        newAssets[editingAssetIndex] = symbol
      } else {
        // Find first empty slot
        const emptyIndex = newAssets.findIndex((asset) => !asset)
        if (emptyIndex >= 0) {
          newAssets[emptyIndex] = symbol
        }
      }

      // Ensure the other type maintains its 6-item array too
      const otherType = assetType === "forex" ? "equities" : "forex"
      const otherTypeAssets = Array(6)
        .fill("")
        .map(
          (_, i) =>
            userData?.tradingInfo?.specificAssets?.[otherType]?.[i] || "",
        )

      // Create the complete trading info structure with both 6-item arrays
      const updatedTradingInfo = {
        ...userData?.tradingInfo,
        specificAssets: {
          [assetType]: newAssets,
          [otherType]: otherTypeAssets,
        },
      }

      // Update the user data with complete structure
      await updateUser({
        email: user.email,
        updates: {
          tradingInfo: updatedTradingInfo,
        },
      })

      setAssets(newAssets)
      setIsAssetSelectorOpen(false)
      setEditingAssetIndex(undefined)

      toast({
        description: "Dashboard assets updated successfully",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    } catch (error) {
      console.error("Error updating assets:", error)
      toast({
        title: "Error",
        description: "Failed to update dashboard assets",
        variant: "destructive",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    }
  }

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/40 backdrop-blur-sm"
            onClick={onClose}
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              className="relative w-full max-w-md rounded-xl bg-[#111] border border-[#333] backdrop-blur-2xl bg-black/55 shadow-2xl"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center p-4 border-b border-[#333]">
                <div className="flex items-center gap-2">
                  <BarChart3 className="h-5 w-5 text-purple-400" />
                  <h2 className="text-lg font-medium text-white">
                    {assetType === "forex"
                      ? "Personal Currencies Dashboard Assets"
                      : "Personal Equities Dashboard Assets"}
                  </h2>
                </div>
                <button
                  onClick={onClose}
                  className="text-white/70 hover:text-white"
                >
                  <X className="h-4 w-4" />
                </button>
              </div>

              <div className="p-4">
                <div className="mb-4">
                  <p className="text-sm text-white/70 mb-2">
                    {filledAssetCount === 6
                      ? "These assets appear on your personal dashboard and in your MRKT report. Edit existing assets to change them."
                      : `Please select ${6 - filledAssetCount} more asset${6 - filledAssetCount === 1 ? "" : "s"} to complete your dashboard setup.`}
                  </p>
                  <div className="space-y-2 mt-4">
                    {assets.map((asset, index) => (
                      <div
                        key={`asset-slot-${index}`}
                        className={cn(
                          "flex items-center justify-between p-3 rounded-md border",
                          asset
                            ? "bg-[#1A1A1A] border-[#333]"
                            : "bg-[#1A1A1A]/50 border-[#333]/50",
                        )}
                      >
                        <span
                          className={cn(
                            "font-medium",
                            asset ? "text-white" : "text-white/30",
                          )}
                        >
                          {asset || `Empty Slot ${index + 1}`}
                        </span>
                        <div className="flex items-center gap-2">
                          <button
                            onClick={() => handleEditAsset(index)}
                            className="p-1.5 rounded-full hover:bg-white/10 transition-colors"
                          >
                            {asset ? (
                              <Edit2 className="h-3.5 w-3.5 text-white/70" />
                            ) : (
                              <Plus className="h-3.5 w-3.5 text-white/70" />
                            )}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="px-4 pb-4">
                  <Button
                    onClick={handleAddAsset}
                    disabled={filledAssetCount >= 6}
                    className={cn(
                      "w-full transition-all duration-200 shadow-lg",
                      "border border-emerald-500/20 bg-gradient-to-r from-emerald-600 to-teal-500",
                      "hover:from-emerald-500 hover:to-teal-400 hover:scale-[1.02]",
                      "disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100",
                      "font-medium tracking-wide",
                    )}
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    {filledAssetCount >= 6
                      ? "Maximum Assets Reached"
                      : "Add New Asset"}
                  </Button>
                  {filledAssetCount >= 6 && (
                    <p className="text-xs text-white/50 text-center mt-2">
                      You can edit existing assets to change them
                    </p>
                  )}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AssetSelector
        isOpen={isAssetSelectorOpen}
        onClose={() => setIsAssetSelectorOpen(false)}
        assetType={assetType}
        currentIndex={editingAssetIndex}
        onAssetSelected={handleAssetSelected}
      />
    </>
  )
}
