import { useQuery } from "@tanstack/react-query"
import { format, subYears } from "date-fns"
import { useAuth0 } from "@auth0/auth0-react"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Area,
  ComposedChart,
} from "recharts"
import { useState } from "react"

interface YieldCurveData {
  date: string
  value: number
}

interface YieldCurveResponse {
  startDate: string
  endDate: string
  data: YieldCurveData[]
}

type TimeRange = "1Y" | "2Y" | "5Y" | "10Y"

export function YieldCurveChart() {
  const { getAccessTokenSilently } = useAuth0()
  const [selectedRange, setSelectedRange] = useState<TimeRange>("1Y")

  const { data, isLoading } = useQuery({
    queryKey: ["yieldCurve", selectedRange],
    queryFn: async () => {
      const endDate = new Date()
      const startDate = subYears(endDate, parseInt(selectedRange))

      const endpoint = `${
        import.meta.env.VITE_MRKT_SERVER
      }/equities/yield-curve?startDate=${format(
        startDate,
        "yyyy-MM-dd",
      )}&endDate=${format(endDate, "yyyy-MM-dd")}`

      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      })

      if (!response.ok) {
        throw new Error("Failed to fetch yield curve data")
      }

      return (await response.json()) as YieldCurveResponse
    },
  })

  const timeRanges: TimeRange[] = ["1Y", "2Y", "5Y", "10Y"]

  // Split the data into positive and negative values
  const gradientOffset = () => {
    const dataMax = Math.max(...(data?.data || []).map((d) => d.value))
    const dataMin = Math.min(...(data?.data || []).map((d) => d.value))

    if (dataMax <= 0) return 0
    if (dataMin >= 0) return 1

    return dataMax / (dataMax - dataMin)
  }

  return (
    <div className="h-full w-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-white text-sm font-medium mb-2">
          10Y-2Y Treasury Yield Spread
        </h2>

        <div className="flex gap-1">
          {timeRanges.map((range) => (
            <button
              key={range}
              onClick={() => setSelectedRange(range)}
              className={`px-2 py-1 text-xs font-medium rounded-md transition-colors duration-200 
                ${
                  selectedRange === range
                    ? "bg-white/10 text-white"
                    : "text-zinc-400 hover:text-white"
                }`}
            >
              {range}
            </button>
          ))}
        </div>
      </div>
      <div className="h-[calc(100%-40px)]">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={data?.data}
            margin={{ left: 0, right: 8, top: 8, bottom: 0 }}
          >
            <defs>
              <linearGradient id="positiveGradient" x1="0" y1="1" x2="0" y2="0">
                <stop offset="0%" stopColor="rgba(34, 197, 94, 0.1)" />
                <stop offset="100%" stopColor="rgba(34, 197, 94, 0.5)" />
              </linearGradient>
              <linearGradient id="negativeGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="rgba(244, 63, 94, 0.1)" />
                <stop offset="100%" stopColor="rgba(244, 63, 94, 0.5)" />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              tickFormatter={(date) => format(new Date(date), "MMM yyyy")}
              stroke="transparent"
              tick={{
                fill: "rgba(255, 255, 255, 0.4)",
                fontSize: 10,
                fontWeight: 500,
              }}
              tickLine={false}
              axisLine={false}
              interval="preserveStartEnd"
              minTickGap={50}
              padding={{ left: 10, right: 10 }}
            />
            <YAxis
              domain={["auto", "auto"]}
              stroke="transparent"
              tick={{
                fill: "rgba(255, 255, 255, 0.4)",
                fontSize: 10,
                fontWeight: 500,
              }}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => `${value.toFixed(1)}%`}
              width={40}
              style={{ fontFamily: "-apple-system, system-ui, sans-serif" }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "rgba(0,0,0,0.95)",
                backdropFilter: "blur(16px)",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "8px",
                color: "#fff",
                padding: "10px",
                fontSize: "12px",
                boxShadow: `
                  0 0 0 1px rgba(255,255,255,0.05),
                  0 4px 6px rgba(0,0,0,0.2),
                  0 8px 16px rgba(0,0,0,0.2),
                  0 0 20px rgba(0,0,0,0.3)
                `,
                fontWeight: "500",
                lineHeight: "1.5",
              }}
              labelFormatter={(date) => format(new Date(date), "MMM d, yyyy")}
              formatter={(value: number) => `${value.toFixed(2)}%`}
            />
            <ReferenceLine y={0} stroke="rgba(255,255,255,0.1)" />
            {isLoading ? null : (
              <>
                <Area
                  type="monotone"
                  dataKey={(d) => (d.value > 0 ? d.value : undefined)}
                  stroke="none"
                  fill="url(#positiveGradient)"
                  strokeWidth={1}
                  dot={false}
                  isAnimationActive={false}
                />
                <Area
                  type="monotone"
                  dataKey={(d) => (d.value < 0 ? d.value : undefined)}
                  stroke="none"
                  fill="url(#negativeGradient)"
                  strokeWidth={1}
                  dot={false}
                  isAnimationActive={false}
                />
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="rgba(255, 255, 255, 0.5)"
                  strokeWidth={1}
                  dot={false}
                  isAnimationActive={false}
                />
              </>
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
