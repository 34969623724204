import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState, useRef } from "react"
import {
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  ReferenceLine,
  Scatter,
} from "recharts"
import { format, subDays } from "date-fns"
import { GlobalMarketIndexes } from "../GlobalMarketIndexes/GlobalMarketIndexes"
import { SentimentBadge } from "../../../../components/Badge/SentimentBadge"

interface FearAndGreedData {
  previous_1_month: number
  previous_1_week: number
  previous_1_year: number
  previous_close: number
  rating: string
  score: number
  timestamp: string
}

interface FearAndGreedResponse {
  data: FearAndGreedData
}

export const FearAndGreedIndex = () => {
  const { getAccessTokenSilently } = useAuth0()

  const { data, isLoading } = useQuery({
    queryKey: ["fearAndGreed"],
    queryFn: async (): Promise<FearAndGreedData> => {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/fng/fear_and_greed`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      const json: FearAndGreedResponse = await response.json()
      return json.data
    },
  })

  // Keep mouse position tracking for hover effects
  const [mouseX, setMouseX] = useState<number | null>(null)
  const barsContainerRef = useRef<HTMLDivElement>(null)
  const [hoveredValue, setHoveredValue] = useState<number | null>(null)

  // Update the getBarScale function
  const getBarScale = (barIndex: number): number => {
    if (mouseX === null || !barsContainerRef.current) return 1

    const containerRect = barsContainerRef.current.getBoundingClientRect()
    const barWidth = containerRect.width / 50
    const barX = barIndex * barWidth + barWidth / 2
    const distance = Math.abs(mouseX - barX)

    // Only scale if mouse is within the bar's width
    return distance < barWidth / 2 ? 1.4 : 1 // Scale up by 40% when hovered
  }

  // Handle mouse movement
  const handleMouseMove = (e: React.MouseEvent) => {
    if (!barsContainerRef.current) return
    const rect = barsContainerRef.current.getBoundingClientRect()
    setMouseX(e.clientX - rect.left)
  }

  // Handle mouse leave
  const handleMouseLeave = () => {
    setMouseX(null)
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white/20"></div>
      </div>
    )
  }

  if (!data) return null

  const getBarColor = (index: number) => {
    const position = (index / 50) * 100 // Convert index to percentage
    if (position <= 20) return "bg-red-800"
    if (position <= 40) return "bg-orange-700"
    if (position <= 60) return "bg-yellow-700"
    if (position <= 80) return "bg-green-700"
    return "bg-emerald-700"
  }

  const getRatingColor = (rating: string) => {
    const colors: { [key: string]: string } = {
      "extreme fear": "text-red-800",
      fear: "text-orange-700",
      neutral: "text-yellow-700",
      greed: "text-green-700",
      "extreme greed": "text-emerald-700",
    }
    return colors[rating.toLowerCase()] || "text-white"
  }

  // Update the bars generation to remove click handling
  const bars = Array.from({ length: 50 }, (_, i) => {
    const position = (i / 50) * 100
    const distanceFromScore = Math.abs(position - data.score)
    const isActive = distanceFromScore < 1
    const baseColor = getBarColor(i)
    const scale = getBarScale(i)

    return (
      <div
        key={i}
        style={{
          opacity: isActive ? 1 : 0.3,
          height: isActive ? "2rem" : "1.5rem",
          transform: `scaleY(${scale})`,
          transition: "all 0.2s ease",
          transformOrigin: "bottom",
        }}
        className={`w-1 rounded-full ${baseColor} cursor-pointer relative group`}
        onMouseEnter={() => setHoveredValue(position)}
        onMouseLeave={() => setHoveredValue(null)}
      >
        {/* Hover tooltip */}
        <div className="absolute bottom-full mb-2 left-1/2 -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none z-10">
          <div className="bg-black/90 backdrop-blur-sm px-2 py-1 rounded-md border border-white/10 shadow-xl whitespace-nowrap">
            <div className="text-xs font-medium text-white">
              {Math.round(position)}
            </div>
          </div>
          {/* Tooltip arrow */}
          <div className="absolute left-1/2 -translate-x-1/2 top-full w-2 h-2 overflow-hidden">
            <div className="w-2 h-2 bg-black/90 rotate-45 transform origin-top-left translate-x-1/2"></div>
          </div>
        </div>

        {/* Hover effect overlay */}
        <div className="absolute inset-0 rounded-full bg-white/0 hover:bg-white/10 transition-colors" />
      </div>
    )
  })

  return (
    <div className="flex flex-col h-full">
      {/* Fear & Greed Index section */}
      <div className="flex-none">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-white text-sm font-medium mb-2">
            Fear & Greed Index
          </h2>
          <div className="flex items-center gap-3">
            <div className="flex items-baseline gap-1">
              <span className="text-sm font-medium text-white">
                {Math.round(data.score)}
              </span>
              <span className="text-xs text-white/40">/ 100</span>
            </div>
            <SentimentBadge rating={data.rating} score={data.score} />
          </div>
        </div>

        <div className="relative">
          <div className="flex flex-col gap-2">
            <div
              ref={barsContainerRef}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              className="flex justify-between items-center gap-[2px] px-1 relative"
            >
              {bars}
            </div>

            {/* Labels */}
            <div className="flex justify-between text-[10px] text-white/60 px-1">
              <span>Extreme Fear</span>
              <span>Fear</span>
              <span>Neutral</span>
              <span>Greed</span>
              <span>Extreme Greed</span>
            </div>
          </div>
        </div>
      </div>

      {/* Global Markets section */}
      <div className="flex-1 min-h-0 mt-8">
        <GlobalMarketIndexes />
      </div>
    </div>
  )
}
