import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"
import { format, formatISO } from "date-fns"
import { TooltipIcon } from "@/components/ui/tooltip_icon"
import { useAuth0 } from "@auth0/auth0-react"
import { AnimatePresence, motion } from "framer-motion"
import { Brain, ArrowUp, Loader2, Search, FileText } from "lucide-react"
import { useState, useRef, useEffect } from "react"
import SimpleBar from "simplebar-react"
import { useMarketHeadlines, useUser } from "../../../../../providers"
import {
  parseHeadlineTime,
  parseHeadlineTitle,
} from "../../utilities/headlineParser"
import { AnalysisDialogContent } from "./AnalysisDialog/components/HeadlineAnalysisDialogContent"
import { HeadlineAnalysis } from "./AnalysisDialog/Data/HeadlineAnalysisData"
import { headlinesKeywords } from "./AnalysisDialog/enums/HeadlineAnalysisChipEnums"
import Skeleton from "react-loading-skeleton"
import { toast } from "@/hooks/use-toast"
import { toPng } from "html-to-image"
import { usePostHog } from "posthog-js/react"
import { useInView } from "react-intersection-observer"
import { SearchOverlay } from "./SearchOverlay"
import { HeadlineItem } from "./HeadlineItem"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { DailySummaryPanel } from "./DailySummaryPanel"
import { DailyHeadlinesPanel } from "./DailyHeadlinesPanel"
import { cn } from "@/lib/utils"

const MARKET_HEADLINES_TOOLTIP_TEXT =
  "Get instant access to breaking news and critical market events as they happen. Real-time headlines keep you informed and ready to respond to market shifts, helping you stay one step ahead in your trading decisions."
// Helper function to highlight matching words
const highlightKeywords = (
  text: string,
  keywords: string[],
  highlightEnabled: boolean,
) => {
  if (!highlightEnabled) return text

  const words = text.split(/\s+/)
  return words.map((word, index) => {
    const cleanWord = word
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
      .toUpperCase()
    const matchedKeyword = keywords.find(
      (keyword) =>
        keyword === cleanWord ||
        (keyword.length > 2 && cleanWord.includes(keyword)),
    )
    if (matchedKeyword) {
      return (
        <span key={index} className="font-extrabold text-purple-400">
          {word}{" "}
        </span>
      )
    }
    return word + " "
  })
}

// Update the formatLocalTime function
const formatLocalTime = (dateStr: string) => {
  try {
    // Parse the ISO string and get the time components
    const date = new Date(dateStr)

    // Validate the date
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      console.warn("Invalid date string:", dateStr)
      return ""
    }

    // Format using 24-hour time
    const hours = date.getHours().toString().padStart(2, "0")
    const minutes = date.getMinutes().toString().padStart(2, "0")

    return `${hours}:${minutes}`
  } catch (error) {
    console.error("Error formatting time:", error)
    return ""
  }
}

// Add this interface at the top of the file
interface HeadlineAnalysisState {
  isLoading: boolean
  data?: HeadlineAnalysis
}

interface DailySummaryResponse {
  headlines: any[]
  metadata: {
    startDate: string
    endDate: string
    count: number
    window: string
  }
}

export const MarketHeadlines = () => {
  const {
    marketHeadlines,
    isLoading: isMarketHeadlinesLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useMarketHeadlines()

  // Add this for infinite scroll detection
  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0,
  })

  // Add this effect to trigger loading more headlines
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage, isFetchingNextPage])

  const [highlightEnabled, setHighlightEnabled] = useState(false)
  const { getAccessTokenSilently } = useAuth0()

  const [expandedHeadlines, setExpandedHeadlines] = useState<
    Record<number, HeadlineAnalysisState>
  >({})
  const [copiedIndex, setCopiedIndex] = useState<number | string | null>(null)
  const { userData } = useUser()
  const posthog = usePostHog()

  const [showScrollTop, setShowScrollTop] = useState(false)
  const simpleBarRef = useRef<any>(null)

  const handleScroll = (e: any) => {
    const scrollElement = e.target
    const scrollTop = scrollElement.scrollTop
    setShowScrollTop(scrollTop > 100)
  }

  const scrollToTop = () => {
    simpleBarRef.current?.getScrollElement().scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const handleSearchOpen = () => {
    posthog.capture("headline_search_opened", {
      user: userData?.email,
      source: "search_button",
    })
    setIsSearchOpen(true)
  }

  // Add keyboard shortcut handler
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "k") {
        e.preventDefault()
        posthog.capture("headline_search_opened", {
          user: userData?.email,
          source: "keyboard_shortcut",
        })
        setIsSearchOpen(true)
      }
    }

    document.addEventListener("keydown", handleKeyDown)
    return () => document.removeEventListener("keydown", handleKeyDown)
  }, [])

  const [isDailySummaryLoading, setIsDailySummaryLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date>()
  const [showDailySummary, setShowDailySummary] = useState(false)
  const [dailySummaryAnalysis, setDailySummaryAnalysis] = useState<any>(null)
  const [open, setOpen] = useState(false)

  const handleCloseDailySummary = () => {
    setShowDailySummary(false)
    setDailySummaryAnalysis(null)
  }

  const [isDailyHeadlinesLoading, setIsDailyHeadlinesLoading] = useState(false)
  const [showDailyHeadlines, setShowDailyHeadlines] = useState(false)
  const [dailyHeadlines, setDailyHeadlines] = useState<any[]>([])

  const handleGetDailyHeadlines = async () => {
    setIsDailyHeadlinesLoading(true)
    setShowDailyHeadlines(true)

    try {
      if (!selectedDate) {
        throw new Error("No date selected")
      }

      // Get user's timezone
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

      const dateParam = format(selectedDate, "yyyy-MM-dd")
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/headlines/calendar-day?date=${dateParam}&timezone=${encodeURIComponent(timezone)}`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error("Failed to fetch daily headlines")
      }

      const data = await response.json()
      setDailyHeadlines(Array.isArray(data?.headlines) ? data.headlines : [])

      posthog.capture("daily_headlines_viewed", {
        date: dateParam,
        timezone,
        user: userData?.name,
      })
    } catch (error) {
      console.error("Error fetching daily headlines:", error)
      toast({
        title: "Error",
        description: "Failed to fetch daily headlines",
        variant: "destructive",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
      setDailyHeadlines([])
    } finally {
      setIsDailyHeadlinesLoading(false)
    }
  }

  const handleCloseDailyHeadlines = () => {
    setShowDailyHeadlines(false)
    setDailyHeadlines([])
  }

  if (isMarketHeadlinesLoading) {
    return (
      <Card className="h-full w-full border-none flex flex-col bg-transparent">
        <CardHeader className="flex flex-row items-center justify-between border-gray-700">
          <div className="flex items-center space-x-2">
            <Switch
              id="highlight-toggle"
              checked={highlightEnabled}
              onCheckedChange={setHighlightEnabled}
              className="data-[state=checked]:bg-purple-400 data-[state=unchecked]:bg-gray-600"
            />
            <Label htmlFor="highlight-toggle" className="text-white text-md">
              AI highlights
            </Label>
          </div>
        </CardHeader>
        <CardContent className="flex-grow overflow-hidden relative">
          <SimpleBar style={{ maxHeight: "100%" }}>
            <ul className="space-y-4 relative">
              <div className="absolute left-[15px] top-0 bottom-0 w-px bg-gray-200"></div>
              {[...Array(6)].map((_, index) => (
                <li key={index} className="relative pl-6">
                  <div className="absolute left-[10px] w-[11px] h-[11px] rounded-full bg-white"></div>
                  <div className="ml-6 p-3">
                    <Skeleton className="h-4 w-24 mb-2 rounded-md opacity-10 bg-gray-700" />
                    <Skeleton className="h-12 rounded-md opacity-10 bg-gray-700" />
                  </div>
                </li>
              ))}
            </ul>
          </SimpleBar>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card className="h-full w-full border-none flex flex-col overflow-hidden bg-transparent">
      <div className="flex flex-col h-full">
        <div className="p-2 space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center bg-zinc-900/50 border border-[#333] rounded-lg divide-x divide-zinc-800/90 h-[42px]">
              <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                  <Button
                    variant="ghost"
                    size="sm"
                    className={cn(
                      "flex items-center gap-2 px-4 h-full rounded-l-lg",
                      "hover:bg-zinc-800/50 transition-all duration-200",
                      "group",
                    )}
                  >
                    <Brain
                      className={cn(
                        "w-4 h-4",
                        "transition-colors duration-200",
                        isDailySummaryLoading
                          ? "text-purple-400"
                          : "text-purple-400/80 group-hover:text-purple-300",
                      )}
                    />
                    <span className="text-sm font-medium text-zinc-300 group-hover:text-zinc-200 transition-colors duration-200">
                      View Daily Headlines
                    </span>
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  className="w-[300px] p-0 bg-zinc-900/95 border border-zinc-800/50 shadow-xl backdrop-blur-sm"
                  align="start"
                >
                  <div className="flex flex-col">
                    <div className="p-4 border-b border-zinc-800/50">
                      <span className="text-zinc-400 text-sm">
                        {selectedDate
                          ? format(selectedDate, "MMMM d, yyyy")
                          : "Select a date"}
                      </span>
                    </div>

                    <Calendar
                      mode="single"
                      selected={selectedDate}
                      onSelect={setSelectedDate}
                      className="p-0 mx-auto"
                      disabled={{ after: new Date() }}
                      classNames={{
                        months: "flex flex-col space-y-4",
                        month: "space-y-4",
                        caption:
                          "flex justify-center pt-1 relative items-center text-zinc-400",
                        caption_label: "text-sm font-medium",
                        nav: "space-x-1 flex items-center",
                        nav_button:
                          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 hover:bg-zinc-800/50 rounded-md text-zinc-400",
                        nav_button_previous: "absolute left-1",
                        nav_button_next: "absolute right-1",
                        table: "w-full border-collapse space-y-1",
                        head_row: "flex",
                        head_cell:
                          "text-zinc-400 rounded-md w-9 font-normal text-[0.8rem]",
                        row: "flex w-full mt-2 justify-center",
                        cell: "text-center text-sm p-0 relative [&:has([aria-selected])]:bg-zinc-800/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                        day: "h-9 w-9 p-0 font-normal aria-selected:opacity-100 text-zinc-400 hover:bg-zinc-800/50 rounded-md transition-colors",
                        day_selected:
                          "bg-purple-400/90 text-white hover:bg-purple-400 hover:text-white focus:bg-purple-400 focus:text-white",
                        day_today: "bg-zinc-800/50 text-white",
                        day_outside: "opacity-50",
                        day_disabled: "opacity-30 cursor-not-allowed",
                        day_range_middle:
                          "aria-selected:bg-zinc-800/50 aria-selected:text-zinc-400",
                        day_hidden: "invisible",
                      }}
                    />
                    <div className="p-4 border-t border-zinc-800/50 space-y-2">
                      <Button
                        onClick={() => {
                          handleGetDailyHeadlines()
                          setOpen(false)
                        }}
                        disabled={!selectedDate || isDailyHeadlinesLoading}
                        className="w-full bg-purple-500/20 hover:bg-purple-500/30 text-purple-300 border border-purple-500/30 transition-colors duration-200"
                      >
                        {isDailyHeadlinesLoading ? (
                          <Loader2 className="w-4 h-4 animate-spin mr-2" />
                        ) : (
                          <FileText className="w-4 h-4 mr-2" />
                        )}
                        View Raw Headlines
                      </Button>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
              <button
                onClick={handleSearchOpen}
                className={cn(
                  "flex items-center gap-2 px-4 h-full",
                  "hover:bg-zinc-800/50 transition-all duration-200",
                  "group",
                )}
              >
                <Search
                  className={cn(
                    "w-4 h-4",
                    "transition-colors duration-200",
                    "text-purple-400/80 group-hover:text-purple-300",
                  )}
                />
                <span className="text-sm font-medium text-zinc-300 group-hover:text-zinc-200 transition-colors duration-200">
                  Search headlines
                </span>
                <kbd className="hidden md:inline-flex items-center gap-1 px-2 py-0.5 text-[10px] font-medium text-zinc-500 bg-zinc-900/50 border border-zinc-800/50 rounded">
                  <span className="text-md">⌘</span>K
                </kbd>
              </button>
              <div
                className={cn(
                  "flex items-center px-4 h-full rounded-r-lg",
                  "hover:bg-zinc-800/50 transition-all duration-200",
                  "group",
                )}
              >
                <Switch
                  id="highlight-toggle"
                  checked={highlightEnabled}
                  onCheckedChange={setHighlightEnabled}
                  className="data-[state=checked]:bg-purple-400/90 data-[state=unchecked]:bg-zinc-700/50"
                />
                <Label
                  htmlFor="highlight-toggle"
                  className="text-sm font-medium text-zinc-300 group-hover:text-zinc-200 transition-colors duration-200 ml-2 cursor-pointer select-none"
                >
                  AI highlights
                </Label>
              </div>
            </div>
          </div>
        </div>

        <SearchOverlay
          isOpen={isSearchOpen}
          onClose={() => setIsSearchOpen(false)}
          highlightEnabled={highlightEnabled}
        />

        <div className="flex-1 overflow-hidden relative px-2">
          <button
            onClick={scrollToTop}
            className="absolute top-2 right-2 p-2 rounded-full bg-gray-800/90 hover:bg-gray-700 
                     border border-gray-700 text-gray-400 hover:text-white shadow-md 
                     transition-all duration-200 z-[10]"
            aria-label="Scroll to top"
          >
            <ArrowUp className="w-3.5 h-3.5" />
          </button>

          <SimpleBar
            style={{ height: "100%" }}
            ref={simpleBarRef}
            onScroll={handleScroll}
          >
            <ul className="space-y-2 relative pb-4">
              <div className="absolute left-[15px] top-0 bottom-0 w-px bg-gray-600/40"></div>
              {marketHeadlines.map((headline, index) => {
                const { relativeTime, localTime } = parseHeadlineTime(headline)
                const headlineTitle = parseHeadlineTitle(headline)
                if (headlineTitle === undefined) return null

                const formattedLocalTime = formatLocalTime(localTime)

                return (
                  <li key={index} className="relative pl-2 group">
                    <div className="absolute left-[10px] top-[22px] w-[11px] h-[11px] rounded-full bg-purple-400/80 ring-4 ring-black group-hover:ring-gray-800 transition-all duration-200"></div>
                    <div className="ml-6 p-4 rounded-lg transition-all duration-200 border border-transparent hover:border-gray-700">
                      <HeadlineItem
                        headlineTitle={headlineTitle}
                        headlineTime={localTime}
                        relativeTime={relativeTime}
                        highlightEnabled={highlightEnabled}
                      />
                    </div>
                  </li>
                )
              })}

              {(hasNextPage || isFetchingNextPage) && (
                <li ref={loadMoreRef} className="flex justify-center py-4">
                  {isFetchingNextPage && (
                    <div className="flex items-center gap-2 text-gray-400">
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <span>Loading more headlines...</span>
                    </div>
                  )}
                </li>
              )}
            </ul>
          </SimpleBar>
        </div>
      </div>

      <AnimatePresence>
        {showDailySummary && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="fixed inset-0 bg-black/50 z-50"
          >
            <DailySummaryPanel
              analysis={dailySummaryAnalysis?.analysis}
              isLoading={isDailySummaryLoading}
              onClose={handleCloseDailySummary}
              selectedDate={selectedDate}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showDailyHeadlines && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="fixed inset-0 bg-black/50 z-50 flex"
          >
            <DailyHeadlinesPanel
              headlines={dailyHeadlines}
              isLoading={isDailyHeadlinesLoading}
              onClose={handleCloseDailyHeadlines}
              selectedDate={selectedDate}
              highlightEnabled={highlightEnabled}
              className="ml-auto h-full"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Card>
  )
}
