import { createFileRoute, useNavigate } from "@tanstack/react-router"
import { useAuth0 } from "@auth0/auth0-react"
import { useEffect } from "react"
import { LoadingScreen } from "@/providers"

export const Route = createFileRoute("/auth/login")({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      connection: search.connection as string | undefined,
      plan: search.plan as "monthly" | "annual" | undefined,
    }
  },
  component: () => {
    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()
    const { connection, plan } = Route.useSearch()
    const navigate = useNavigate()

    useEffect(() => {
      if (!isLoading) {
        if (isAuthenticated) {
          // If already authenticated, redirect to auth route
          if (plan) {
            navigate({ to: "/auth", search: { plan } })
          } else {
            navigate({ to: "/auth" })
          }
        } else {
          // If not authenticated, initiate login with specified connection
          loginWithRedirect({
            appState: {
              returnTo: "/auth",
              plan,
            },
            authorizationParams: {
              connection,
            },
          })
        }
      }
    }, [isLoading, isAuthenticated, connection, plan])

    return <LoadingScreen />
  },
})
