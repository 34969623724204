import { XCircle, X, Brain } from "lucide-react"
import { DNA } from "react-loader-spinner"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { motion } from "framer-motion"
import { Resizable } from "re-resizable"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { cn } from "@/lib/utils"
import { Toggle } from "@/components/ui/Toggle"
import { format } from "date-fns"
import { MultiSymbolChart } from "@/presentation/components/Charts/MultiSymbolChart"
import { useNavigate } from "@tanstack/react-router"
import { DailySectorPerformance } from "../DailySectorPerformance/DailySectorPerformance"

interface DailyRecapAnalysis {
  metadata: {
    startDate: string
    endDate: string
    window: string
    requestedDate: string
  }
  analysis: {
    equity_market_trends: {
      text: string
      key_points: string[]
      trending_stocks: string[]
    }
    earnings: {
      text: string
      key_points: string[]
      earnings: string[]
    }
    macroeconomic_policy_impacts: {
      text: string
      key_points: string[]
    }
  }
}

interface EarningsSurpriseData {
  date: string
  symbol: string
  actualEarningResult: number
  estimatedEarning: number
}

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

const highlightText = (
  text: string,
  keyPoints: string[],
  highlightEnabled: boolean,
) => {
  if (!highlightEnabled) {
    return <p className="text-gray-300">{text}</p>
  }

  let highlightedText = text
  keyPoints.forEach((point) => {
    const escapedPoint = escapeRegExp(point)
    const regex = new RegExp(escapedPoint, "gi")
    highlightedText = highlightedText.replace(
      regex,
      `<span class="bg-yellow-600/40 text-yellow-200 font-medium px-1 rounded-sm border-b border-yellow-400/50">${point}</span>`,
    )
  })
  return (
    <p
      className="text-gray-300"
      dangerouslySetInnerHTML={{ __html: highlightedText }}
    />
  )
}

const AnalysisLoadingSkeleton = ({ messages }: { messages: string[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length)
    }, 2000)

    return () => clearInterval(interval)
  }, [messages.length])

  return (
    <div className="space-y-6">
      <div className="flex flex-col items-center justify-center py-8">
        <DNA
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{ color: "orange" }}
          wrapperClass="dna-wrapper"
        />
        <h2 className="text-xl font-medium text-white mb-4">
          {messages[currentIndex]}
        </h2>
      </div>

      <div className="space-y-6">
        {[...Array(4)].map((_, index) => (
          <div key={index}>
            <Skeleton
              height={24}
              width={160}
              className="mb-3"
              baseColor="#27272a"
              highlightColor="#3f3f46"
            />
            <div className="space-y-2">
              <Skeleton
                height={16}
                baseColor="#27272a"
                highlightColor="#3f3f46"
              />
              <Skeleton
                height={16}
                width="75%"
                baseColor="#27272a"
                highlightColor="#3f3f46"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

interface EquitiesDailyRecapProps {
  selectedDate?: Date
  onClose: () => void
}

export const EquitiesDailyRecap = ({
  selectedDate,
  onClose,
}: EquitiesDailyRecapProps) => {
  const { getAccessTokenSilently } = useAuth0()
  const [streamingAnalysis, setStreamingAnalysis] =
    useState<DailyRecapAnalysis | null>(null)
  const [streamingError, setStreamingError] = useState<string | null>(null)
  const [analysisStatus, setAnalysisStatus] = useState<
    "idle" | "fetching_data" | "analyzing_data" | "completed"
  >("idle")
  const [highlightEnabled, setHighlightEnabled] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchAnalysis = async () => {
      try {
        const token = await getAccessTokenSilently()
        const dateParam = selectedDate
          ? format(selectedDate, "yyyy-MM-dd")
          : undefined
        const queryString = dateParam ? `?date=${dateParam}` : ""

        const response = await fetch(
          `${import.meta.env.VITE_MRKT_SERVER}/equities/daily-recap${queryString}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        if (!response.ok) {
          throw new Error("Failed to fetch analysis")
        }

        // Check if response is JSON (cached) or stream
        const contentType = response.headers.get("content-type")
        if (contentType?.includes("application/json")) {
          // Handle cached JSON response
          const data = await response.json()
          if (data?.analysis) {
            setStreamingAnalysis(data)
            setAnalysisStatus("completed")
          } else {
            throw new Error("Invalid analysis data received")
          }
          return
        }

        // Handle streaming response
        const reader = response.body?.getReader()
        if (!reader) throw new Error("No reader available")

        try {
          let completeJson = ""
          while (true) {
            const { done, value } = await reader.read()
            if (done) break

            const chunk = new TextDecoder().decode(value)
            const lines = chunk.split("\n")

            for (const line of lines) {
              if (line.startsWith("data: ")) {
                try {
                  const data = JSON.parse(line.slice(5))

                  if (data.status) {
                    setAnalysisStatus(data.status)
                    continue
                  }

                  if (data.error) {
                    setStreamingError(data.error)
                    continue
                  }

                  if (data.type === "complete" && data.content) {
                    setStreamingAnalysis(data.content)
                    continue
                  }

                  if (data.type === "chunk") {
                    completeJson += data.content
                  }
                } catch (e) {
                  console.error("Error parsing SSE data:", e)
                }
              }
            }
          }
        } finally {
          reader.releaseLock()
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          setStreamingError(error.message)
        } else {
          setStreamingError("An error occurred")
        }
        console.error("Error:", error)
      }
    }

    fetchAnalysis()
  }, [selectedDate, getAccessTokenSilently])

  const loadingMessages = [
    "Analyzing market trends...",
    "Processing earnings updates...",
    "Evaluating sector performance...",
    "Analyzing global impacts...",
    "Synthesizing market recap...",
  ]

  const isLoading = analysisStatus !== "completed"

  const renderAnalysis = () => {
    if (!streamingAnalysis?.analysis) return null

    const { equity_market_trends, earnings, macroeconomic_policy_impacts } =
      streamingAnalysis.analysis

    return (
      <div className="space-y-8">
        {equity_market_trends && (
          <div>
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-medium mb-4 text-white">
                Today In Equities
              </h3>
              <Toggle
                enabled={highlightEnabled}
                onChange={setHighlightEnabled}
                label="Highlights"
                size="sm"
              />
            </div>
            {highlightText(
              equity_market_trends.text,
              equity_market_trends.key_points,
              highlightEnabled,
            )}
            <div className="mt-4">
              <h4 className="text-sm font-medium text-gray-400 mb-2">
                Trending Stocks Performance
              </h4>
              <div className="h-[300px] w-full">
                <MultiSymbolChart
                  symbols={equity_market_trends.trending_stocks}
                  symbolLabels={equity_market_trends.trending_stocks.reduce(
                    (acc, symbol) => ({
                      ...acc,
                      [symbol]: symbol,
                    }),
                    {},
                  )}
                  height={300}
                />
              </div>
            </div>
          </div>
        )}

        {earnings && (
          <div>
            <h3 className="text-lg font-medium mb-4 text-white">
              Earnings & Corporate Updates
            </h3>
            {highlightText(
              earnings.text,
              earnings.key_points,
              highlightEnabled,
            )}
            <div className="mt-4 flex flex-wrap gap-2">
              {earnings.earnings.map((symbol) => (
                <button
                  key={symbol}
                  onClick={() => {
                    navigate({
                      to: "/equities/symbol/$symbol",
                      params: { symbol },
                    })
                  }}
                  className="px-2.5 py-1 rounded 
                           bg-white/10 hover:bg-white/15 backdrop-blur-sm
                           border border-white/20 text-white text-xs font-medium 
                           shadow-sm shadow-black/20 hover:shadow-md
                           transition-all hover:-translate-y-[1px]"
                >
                  {symbol}
                </button>
              ))}
            </div>
          </div>
        )}

        {macroeconomic_policy_impacts && (
          <div>
            <h3 className="text-lg font-medium mb-4 text-white">
              Macro & Global Risks
            </h3>
            {highlightText(
              macroeconomic_policy_impacts.text,
              macroeconomic_policy_impacts.key_points,
              highlightEnabled,
            )}
          </div>
        )}

        <div>
          <h3 className="text-lg font-medium mb-4 text-white">
            Sector Performance
          </h3>
          <DailySectorPerformance selectedDate={selectedDate} />
        </div>
      </div>
    )
  }

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "spring", bounce: 0, duration: 0.4 }}
      className="fixed right-0 top-0 h-full bg-[#111] border-l border-[#333] backdrop-blur-xl bg-black/80 shadow-xl z-[50] flex"
    >
      <Resizable
        defaultSize={{ width: 600, height: "100%" }}
        minWidth={500}
        maxWidth={1000}
        enable={{
          top: false,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        handleClasses={{
          left: "w-1 h-full cursor-ew-resize hover:bg-purple-500/20 transition-colors absolute left-0 top-0 border-r border-purple-500/10",
        }}
      >
        <div className="h-full flex flex-col overflow-hidden">
          <div className="flex justify-between items-center p-6 border-b border-[#333] bg-black/50 backdrop-blur-sm">
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-purple-500/10 border border-purple-500/20">
                <Brain className="w-6 h-6 text-purple-400" />
              </div>
              <div>
                <h2 className="text-xl font-semibold text-white">
                  Equities Daily Recap
                </h2>
                <p className="text-sm text-gray-400">
                  {selectedDate
                    ? format(selectedDate, "MMMM d, yyyy")
                    : format(new Date(), "MMMM d, yyyy")}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <button
                onClick={onClose}
                className="flex items-center justify-center w-9 h-9 rounded-lg text-gray-400 hover:text-white hover:bg-white/5 transition-colors border border-white/5"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>

          <SimpleBar
            className="flex-1 overflow-y-auto"
            style={{ height: "100%" }}
            autoHide={false}
          >
            <div className="p-6 pb-12">
              {isLoading ? (
                <AnalysisLoadingSkeleton messages={loadingMessages} />
              ) : streamingError ? (
                <div className="flex flex-col items-center justify-center py-12">
                  <XCircle className="w-12 h-12 text-red-500/70 mb-4" />
                  <h3 className="text-lg font-medium text-white mb-2">
                    Analysis Failed
                  </h3>
                  <p className="text-zinc-400 text-sm">{streamingError}</p>
                </div>
              ) : (
                renderAnalysis()
              )}
            </div>
          </SimpleBar>
        </div>
      </Resizable>
    </motion.div>
  )
}
