import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState } from "react"
import { format } from "date-fns"
import { Loader2 } from "lucide-react"
import { cn } from "@/lib/utils"

interface SectorPerformance {
  date: string
  basicMaterialsChangesPercentage: number
  communicationServicesChangesPercentage: number
  consumerCyclicalChangesPercentage: number
  consumerDefensiveChangesPercentage: number
  energyChangesPercentage: number
  financialServicesChangesPercentage: number
  healthcareChangesPercentage: number
  industrialsChangesPercentage: number
  realEstateChangesPercentage: number
  technologyChangesPercentage: number
  utilitiesChangesPercentage: number
}

const SectorList = ({ data }: { data: { name: string; value: number }[] }) => {
  const sortedData = [...data].sort((a, b) => b.value - a.value)

  return (
    <div className="w-full">
      <div className="grid gap-1.5">
        {sortedData.map((sector) => (
          <div
            key={sector.name}
            className={cn(
              "flex justify-between items-center py-2 px-3 rounded-md",
              "bg-white/[0.03]",
              "border border-white/[0.05]",
              "transition-colors duration-200",
              "hover:bg-white/[0.06]",
              // Subtle gradient background
              "bg-gradient-to-r from-white/[0.03] to-transparent",
            )}
          >
            <span className="text-gray-300 text-sm font-medium">
              {sector.name}
            </span>
            <span
              className={cn(
                "text-sm tabular-nums font-medium",
                sector.value >= 0 ? "text-green-400" : "text-red-400",
              )}
            >
              {sector.value >= 0 ? "+" : ""}
              {sector.value.toFixed(2)}%
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

interface DailySectorPerformanceProps {
  selectedDate?: Date
}

export const DailySectorPerformance = ({
  selectedDate,
}: DailySectorPerformanceProps) => {
  const { getAccessTokenSilently } = useAuth0()
  const [performance, setPerformance] = useState<SectorPerformance | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchPerformance = async () => {
      try {
        setLoading(true)
        const token = await getAccessTokenSilently()
        const date = selectedDate
          ? format(selectedDate, "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd")

        const response = await fetch(
          `${import.meta.env.VITE_MRKT_SERVER}/equities/sector-performance?from=${date}&to=${date}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        if (!response.ok) {
          throw new Error("Failed to fetch sector performance")
        }

        const data = await response.json()
        setPerformance(data[0])
      } catch (err) {
        setError(err instanceof Error ? err.message : "Failed to fetch data")
      } finally {
        setLoading(false)
      }
    }

    fetchPerformance()
  }, [selectedDate, getAccessTokenSilently])

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[200px]">
        <Loader2 className="w-6 h-6 animate-spin text-gray-400" />
      </div>
    )
  }

  if (error || !performance) {
    return (
      <div className="text-red-400 text-sm">
        {error || "No performance data available"}
      </div>
    )
  }

  const sectorData = [
    { name: "Technology", value: performance.technologyChangesPercentage },
    { name: "Healthcare", value: performance.healthcareChangesPercentage },
    {
      name: "Financials",
      value: performance.financialServicesChangesPercentage,
    },
    {
      name: "Consumer Discretionary",
      value: performance.consumerCyclicalChangesPercentage,
    },
    { name: "Industrials", value: performance.industrialsChangesPercentage },
    { name: "Energy", value: performance.energyChangesPercentage },
    { name: "Materials", value: performance.basicMaterialsChangesPercentage },
    {
      name: "Communication Services",
      value: performance.communicationServicesChangesPercentage,
    },
    {
      name: "Consumer Staples",
      value: performance.consumerDefensiveChangesPercentage,
    },
    { name: "Real Estate", value: performance.realEstateChangesPercentage },
    { name: "Utilities", value: performance.utilitiesChangesPercentage },
  ]

  return <SectorList data={sectorData} />
}
