import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useArticles } from "@/providers/ArticlesProvider"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { format } from "date-fns"
import { useNavigate } from "@tanstack/react-router"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { useState, useEffect, useRef, useCallback } from "react"
import { MarketHeadlines } from "../MarketHeadlines"
import { ExternalLink } from "lucide-react"

const NewsTab = ({ type }: { type: "fx" | "stocks" | "crypto" }) => {
  const {
    fxArticles,
    stockArticles,
    cryptoArticles,
    isLoadingFx,
    isLoadingStocks,
    isLoadingCrypto,
    fetchNextFxPage,
    fetchNextStocksPage,
    fetchNextCryptoPage,
    hasNextFxPage,
    hasNextStocksPage,
    hasNextCryptoPage,
    isFetchingNextFxPage,
    isFetchingNextStocksPage,
    isFetchingNextCryptoPage,
  } = useArticles()
  const navigate = useNavigate()
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const articles = {
    fx: fxArticles,
    stocks: stockArticles,
    crypto: cryptoArticles,
  }[type]

  const isLoading = {
    fx: isLoadingFx,
    stocks: isLoadingStocks,
    crypto: isLoadingCrypto,
  }[type]

  const hasNextPage = {
    fx: hasNextFxPage,
    stocks: hasNextStocksPage,
    crypto: hasNextCryptoPage,
  }[type]

  const isFetchingNextPage = {
    fx: isFetchingNextFxPage,
    stocks: isFetchingNextStocksPage,
    crypto: isFetchingNextCryptoPage,
  }[type]

  const fetchNextPage = {
    fx: fetchNextFxPage,
    stocks: fetchNextStocksPage,
    crypto: fetchNextCryptoPage,
  }[type]

  const onScroll = useCallback(() => {
    if (!scrollContainerRef.current) return

    const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current

    // Load more when user has scrolled to within 100px of the bottom
    if (
      scrollTop + clientHeight >= scrollHeight - 100 &&
      !isFetchingNextPage &&
      hasNextPage
    ) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", onScroll)
      return () => scrollContainer.removeEventListener("scroll", onScroll)
    }
  }, [onScroll])

  if (isLoading) {
    return (
      <div className="space-y-2 p-4">
        {[...Array(8)].map((_, i) => (
          <Skeleton
            key={i}
            height={104}
            className="mb-2"
            baseColor="#222"
            highlightColor="#333"
          />
        ))}
      </div>
    )
  }

  return (
    <SimpleBar
      scrollableNodeProps={{ ref: scrollContainerRef }}
      className="h-full"
    >
      <div className="space-y-2 p-4">
        {articles.map((article) => (
          <div
            key={article.url}
            onClick={(e) => {
              e.preventDefault()
              if (type === "stocks") {
                navigate({
                  to: "/equities/symbol/$symbol",
                  params: { symbol: article.symbol },
                })
              }
            }}
            className="group block cursor-pointer"
          >
            <div
              className="relative flex flex-col p-3 rounded-lg
              border border-white/5 
              bg-black/20
              hover:bg-black/30
              hover:border-white/10
              transition-all duration-200"
            >
              <div className="flex gap-3 mb-2">
                <div className="w-8 h-8 flex-shrink-0">
                  <img
                    src={`https://financialmodelingprep.com/image-stock/${article.symbol}.png`}
                    alt={`${article.symbol} logo`}
                    className="w-full h-full object-contain opacity-80 group-hover:opacity-100 transition-opacity duration-200"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement
                      target.style.display = "none"
                      target.parentElement!.innerHTML = `<div class="w-full h-full flex items-center justify-center text-xs font-medium text-white/50 bg-white/5 rounded-md">${article.symbol}</div>`
                    }}
                  />
                </div>
                <h3 className="flex-1 text-sm font-medium text-white/80 leading-snug pr-6 group-hover:text-white transition-colors duration-200">
                  {article.title}
                </h3>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2 flex-wrap text-white/40">
                  <span className="text-[10px] font-medium tracking-wider uppercase bg-white/5 px-2 py-0.5 rounded-sm">
                    {article.symbol}
                  </span>
                  <span className="text-[10px] tracking-wide">
                    {format(new Date(article.publishedDate), "MMM d, HH:mm")}
                  </span>
                  <div
                    className={`h-1.5 w-1.5 rounded-full ${
                      article.sentiment === "Positive"
                        ? "bg-emerald-500/50"
                        : article.sentiment === "Negative"
                          ? "bg-red-500/50"
                          : "bg-white/20"
                    }`}
                  />
                </div>
                <div
                  className="flex items-center"
                  onClick={(e) => {
                    e.stopPropagation()
                    window.open(article.url, "_blank")
                  }}
                >
                  <ExternalLink className="w-4 h-4 text-white/30 hover:text-white/60 transition-colors duration-200" />
                </div>
              </div>
            </div>
          </div>
        ))}
        {isFetchingNextPage && (
          <div className="py-4">
            <Skeleton
              height={104}
              className="mb-2"
              baseColor="#222"
              highlightColor="#333"
            />
          </div>
        )}
      </div>
    </SimpleBar>
  )
}

export function MarketNews() {
  const [selectedTab, setSelectedTab] = useState<"fx" | "stocks" | "crypto">(
    "stocks",
  )

  return (
    <Card className="h-full w-full bg-inherit border-none p-0">
      <CardHeader className="p-1">
        <div className="flex items-center justify-between">
          <div className="flex gap-2">
            <button
              onClick={() => setSelectedTab("stocks")}
              className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                ${selectedTab === "stocks" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
            >
              Stocks
            </button>
            <button
              onClick={() => setSelectedTab("fx")}
              className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                ${selectedTab === "fx" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
            >
              Forex
            </button>
            <button
              onClick={() => setSelectedTab("crypto")}
              className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                ${selectedTab === "crypto" ? "bg-white/10 text-white" : "text-gray-400 hover:text-white"}`}
            >
              Crypto
            </button>
          </div>
        </div>
      </CardHeader>
      <CardContent className="p-0 h-[calc(100%-3rem)]">
        <div className="h-full">
          <div className="space-y-4 overflow-y-auto h-[calc(100%-48px)]">
            {selectedTab === "stocks" && <NewsTab type="stocks" />}
            {selectedTab === "fx" && <NewsTab type="fx" />}
            {selectedTab === "crypto" && <NewsTab type="crypto" />}
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
