import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import { addDays, startOfWeek } from "date-fns"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  PropsWithChildren,
} from "react"

export const getLocalMidnight = (date: Date) => {
  const local = new Date(date)
  local.setHours(0, 0, 0, 0)
  return local
}

export const getLocalEndOfDay = (date: Date) => {
  const local = new Date(date)
  local.setHours(23, 59, 59, 999)
  return local
}

export const adjustDateForTimezone = (dateString: string) => {
  const date = new Date(dateString)
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
}

type FetchEconomicCalendarProps = {
  countryFilter: string[]
  startDateFilter: Date
  endDateFilter: Date
  impactFilter: string[]
  categoryFilter: string[]
  getAccessTokenSilently: () => Promise<string>
}

type EconomicCalendarContextProps = {
  economicCalendarData: any
  countryFilter: string[]
  impactFilter: string[]
  categoryFilter: string[]
  setEconomicCalendarData: Dispatch<SetStateAction<string[]>>
  setCountryFilter: Dispatch<SetStateAction<string[] | null>>
  setImpactFilter: Dispatch<SetStateAction<string[] | null>>
  setCategoryFilter: Dispatch<SetStateAction<string[]>>
  setStartDateFilter: Dispatch<SetStateAction<Date>>
  setEndDateFilter: Dispatch<SetStateAction<Date>>
  error: Error | null
  isLoading: boolean
  updateCalendar: (incomingEvent: any) => void
}

const EconomicCalendarContext = createContext<
  EconomicCalendarContextProps | undefined
>(undefined)

type EconomicCalendarProviderProps = {
  children: ReactNode
}

export const INCOMING_CALENDAR_EVENT = {
  changes: [
    { key: "dateUtc", value: "2014-04-10T08:00:00Z" },
    { key: "actual", value: 0.4 },
  ],
  eventDateId: "306d3a2e-7c1b-4937-b2e3-a63171e174fe",
  eventId: "6ada7843-f47f-4e5f-a54b-4c64bb7d8511",
  lastUpdated: 1722600980,
  type: "eventDate_updated",
}

export const defaultCountries = [
  "US",
  "UK",
  "FR",
  "JP",
  "IN",
  "CA",
  "DE",
  "IT",
  "ES",
  "TH",
  "CH",
  "CN",
  "AU",
  "BR",
  "AR",
  "MX",
  "RU",
  "SA",
  "ZA",
  "KR",
  "TR",
  "ID",
  "ECM",
  "NZ",
]

const defaultImpacts = ["LOW", "MEDIUM", "HIGH", "NONE"]
const categories = [
  "c94405b5-5f85-4397-ab11-002a481c4b92", //central bank
  "24127f3b-edce-4dc4-afdf-0b3bd8a964be", //Economic Activity
  "33303f5e-1e3c-4016-ab2d-ac87e98f57ca", //Inflation
  "91da97bd-d94a-4ce8-a02b-b96ee2944e4c", //Labor Market
  "9c4a731a-d993-4d55-89f3-dc707cc1d596", //Interest Rates
  "fa6570f6-e494-4563-a363-00d0f2abec37", //capital flows
  "7dfaef86-c3fe-4e76-9421-8958cc2f9a0d", //Holidays
  "e229c890-80fc-40f3-b6f4-b658f3a02635", //Consumption
  "1e06a304-fac6-440c-9ced-9225a6277a55", //Housing Market
  "8896aa26-a50c-4f8b-aa11-8b3fccda1dfd", //Bond Auctions
  "dd332fd3-6996-41be-8c41-33f277074fa7", //Energy
  "e9e957ec-2927-4a77-ae0c-f5e4b5807c16", //Politics
]

const fetchEconomicCalendar = async ({
  countryFilter,
  startDateFilter,
  endDateFilter,
  impactFilter,
  categoryFilter,
  getAccessTokenSilently,
}: FetchEconomicCalendarProps) => {
  const url = new URL(`${import.meta.env.VITE_MRKT_SERVER}/calendar/recent`)

  url.searchParams.append("startDate", startDateFilter.toISOString())
  url.searchParams.append("endDate", endDateFilter.toISOString())

  if (countryFilter.length === 0) {
    defaultCountries.forEach((country) =>
      url.searchParams.append("countries", country),
    )
  } else {
    countryFilter.forEach((country) =>
      url.searchParams.append("countries", country),
    )
  }

  if (impactFilter.length === 0) {
    defaultImpacts.forEach((impact) =>
      url.searchParams.append("volatilities", impact),
    )
  } else {
    impactFilter.forEach((impact) =>
      url.searchParams.append("volatilities", impact),
    )
  }

  categoryFilter.forEach((category) =>
    url.searchParams.append("categories", category),
  )

  try {
    const response = await fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    })
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`)
    }
    const result = await response.json()
    return result
  } catch (err) {
    if (err instanceof Error) {
      return err.message
    } else {
      return "An unknown error occurred"
    }
  }
}

interface User {
  economicCalendarFilters: {
    countries: string[]
    impact: string[]
    categories: string[]
  }
}

export const EconomicCalendarProvider = ({
  children,
}: EconomicCalendarProviderProps) => {
  const { getAccessTokenSilently, user } = useAuth0()
  const [economicCalendarData, setEconomicCalendarData] = useState<any[]>([])
  const [countryFilter, setCountryFilter] = useState<string[] | null>(null)
  const [impactFilter, setImpactFilter] = useState<string[] | null>(null)
  const [categoryFilter, setCategoryFilter] = useState<string[]>([])
  const [startDateFilter, setStartDateFilter] = useState<Date>(() =>
    getLocalMidnight(startOfWeek(new Date())),
  )
  const [endDateFilter, setEndDateFilter] = useState<Date>(() =>
    getLocalEndOfDay(addDays(startOfWeek(new Date()), 14)),
  )

  // Fetch user data
  const { data: userData, isLoading: isUserLoading } = useQuery({
    queryKey: ["user", user?.sub],
    queryFn: async () => {
      if (!user?.email) return null

      const encodedEmail = encodeURIComponent(user.email)
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/user?id=${encodedEmail}`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error("Failed to fetch user")
      }

      const { data } = await response.json()
      return data as User
    },
    enabled: !!user?.sub,
  })

  // Initialize filters once when user data is available or loading is complete
  useEffect(() => {
    if (!isUserLoading && userData?.economicCalendarFilters) {
      const { countries, impact, categories } = userData.economicCalendarFilters
      setCountryFilter(countries?.length > 0 ? countries : defaultCountries)
      setImpactFilter(impact?.length > 0 ? impact : defaultImpacts)
      setCategoryFilter(categories?.length > 0 ? categories : [])
    }
  }, [userData, isUserLoading])

  // Economic calendar query - now waits for filters to be initialized
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: [
      "economicCalendar",
      {
        countryFilter,
        startDateFilter,
        endDateFilter,
        impactFilter,
        categoryFilter,
      },
    ],
    queryFn: () => {
      return fetchEconomicCalendar({
        countryFilter: countryFilter || defaultCountries,
        startDateFilter,
        endDateFilter,
        impactFilter: impactFilter || defaultImpacts,
        categoryFilter: categoryFilter.length > 0 ? categoryFilter : categories,
        getAccessTokenSilently,
      })
    },
    enabled: !isUserLoading && countryFilter !== null && impactFilter !== null,
  })

  const safeSetEconomicCalendarData = (data: any) => {
    if (Array.isArray(data)) {
      setEconomicCalendarData(data)
    } else {
      console.warn("Invalid data format received:", data)
      setEconomicCalendarData([])
    }
  }

  useEffect(() => {
    if (data) {
      safeSetEconomicCalendarData(data)
    }
  }, [data])

  const updateCalendar = (incomingEvent: any) => {
    // First check if we have data to update
    if (!economicCalendarData) {
      console.info("Cannot update - calendar data not initialized")
      return
    }

    // Early return for non-update events
    if (
      incomingEvent.type === "eventDate_created" ||
      incomingEvent.type === "eventDate_deleted" ||
      incomingEvent.type === "event_created"
    ) {
      console.info(`Received ${incomingEvent.type}:`, incomingEvent)
      return
    }

    // Continue with update logic for eventDate_updated
    if (
      incomingEvent.type !== "eventDate_updated" ||
      !Array.isArray(incomingEvent.changes) ||
      incomingEvent.changes.length === 0
    ) {
      console.info(
        "Received event update without valid changes:",
        incomingEvent,
      )
      return
    }

    setEconomicCalendarData((prevData: any) => {
      const updatedData = prevData.map((event: any) => {
        if (event.eventId === incomingEvent.eventId) {
          const updatedEvent = { ...event }
          incomingEvent.changes.forEach((change: any) => {
            updatedEvent[change.key] = change.value
          })
          return updatedEvent
        }
        return event
      })
      return updatedData
    })
  }

  return (
    <EconomicCalendarContext.Provider
      value={{
        economicCalendarData,
        setEconomicCalendarData,
        setImpactFilter,
        setCategoryFilter,
        categoryFilter,
        countryFilter: countryFilter ?? [],
        impactFilter: impactFilter ?? [],
        error,
        isLoading: isLoading || isUserLoading || countryFilter === null,
        updateCalendar,
        setCountryFilter,
        setStartDateFilter,
        setEndDateFilter,
      }}
    >
      {children}
    </EconomicCalendarContext.Provider>
  )
}

export const useEconomicCalendar = (): EconomicCalendarContextProps => {
  const context = useContext(EconomicCalendarContext)
  if (!context) {
    throw new Error(
      "useEconomicCalendar must be used within a EconomicCalendarProvider",
    )
  }
  return context
}
